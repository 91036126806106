﻿import { Component, Output, EventEmitter, HostListener, ViewChild, OnInit, Input } from '@angular/core';
import { Contact } from '../shared/contact';
import { ContactService } from '../shared/contactService';
import { error } from 'util';

@Component({
    selector: "add-contact",
    templateUrl: "contactAdd.component.html",
    styleUrls: ["contactAdd.component.css"]
})
export class ContactAddComponent {

    errorMessage: string;

    public contact = {
        id: 0,
        contactName: null,
        email: null,
        cell: null
    }

    @Output() toggle = new EventEmitter<boolean>();

    constructor(private contactService: ContactService) { }

    onSubmit() {
        //this.contactService.addContact(this.company, this.contact)
        //    .subscribe(_ => {
        //        this.toggle.emit(true);
        //    }, err => {
        //        this.errorMessage = 'Failed to add contact.';
        //        if (typeof err.error !== 'string') {
        //            Object.values(err.error).forEach(val => {
        //                this.errorMessage = this.errorMessage + ' ' + val[0];
        //            });
        //        }
        //        else {
        //            this.errorMessage = err.error;
        //        }
        //    });

    }
}