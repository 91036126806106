﻿import { Component, Input } from '@angular/core';
import { Load } from '../../shared/load';
import { LoadDocument } from '../../shared/loadDocument';

@Component({
    selector: "load-documents",
    templateUrl: "loadDocuments.component.html",
    styleUrls: ["loadDocuments.component.css"]
})
export class LoadDocumentsComponent {

    @Input() load: Load;

    public showLoadDocumentList: boolean;
    public showDeleteLoadDocument: boolean;

    public loadDocument: LoadDocument;

    ngOnInit(): void {
        this.clearLoadDocumentView();
        this.showLoadDocumentList = true;
    }

    clearLoadDocumentView() {
        this.showLoadDocumentList = false;
        this.showDeleteLoadDocument = false;
    }

    onShowLoadDocumentList() {
        this.clearLoadDocumentView();
        this.showLoadDocumentList = true;
    }

    onLoadDocumentDelete(loadDocument: LoadDocument) {
        this.loadDocument = loadDocument;
        this.clearLoadDocumentView();
        this.showDeleteLoadDocument = true;
    }

    onRefreshLoadDocumentList(hideLoadDocument: boolean) {
        this.clearLoadDocumentView();
        this.showLoadDocumentList = hideLoadDocument;
    }

}
