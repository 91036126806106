﻿import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Load } from '../../shared/load';
import { isUndefined } from 'util';
import { LoadService } from '../../shared/loadService';
import { LoadCustomer } from '../../shared/loadCustomer';

@Component({
    selector: "load-customer-list",
    templateUrl: "loadCustomerList.component.html",
    styleUrls: ["loadCustomerList.component.css"]
})
export class LoadCustomerListComponent implements OnInit {

    @Input() load: Load;

    @Input() loadCustomers: LoadCustomer[];
    @Output() openCargoEmitter = new EventEmitter<LoadCustomer>();

    @Output() showEditLoadCustomerEmitter = new EventEmitter<LoadCustomer>();
    @Output() showDeleteLoadCustomerEmitter = new EventEmitter<LoadCustomer>();

    constructor(private loadService: LoadService) { }

    ngOnInit(): void {
        this.getLoadCustomers();
    }

    getLoadCustomers() {
        if (!isUndefined(this.load) && isUndefined(this.loadCustomers)) {
            this.loadService.getLoadCustomers(this.load.id)
                .subscribe(s => {
                    if (s) {
                        this.loadCustomers = this.loadService.loadCustomers;
                    }
                });
        }
    }

    onEditLoadCustomer(loadCustomer:LoadCustomer) {
        this.showEditLoadCustomerEmitter.emit(loadCustomer);
    }

    onDeleteLoadCustomer(loadCustomer: LoadCustomer) {
        this.showDeleteLoadCustomerEmitter.emit(loadCustomer);
    }

    onCargo(loadCustomer: LoadCustomer) {
        this.openCargoEmitter.emit(loadCustomer);
    }

}