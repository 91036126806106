﻿import { Component, Input } from '@angular/core';
import { Supplier } from '../../shared/supplier';
import { SupplierContact } from '../../shared/supplierContact';

@Component({
    selector: "supplier-contacts",
    templateUrl: "supplierContacts.component.html",
    styleUrls: ["supplierContacts.component.css"]
})
export class SupplierContactsComponent {
    @Input() supplier: Supplier;

    public showSupplierContactList: boolean;
    public showSupplierContact: boolean;
    public showDeleteSupplierContact: boolean;
    public showEditSupplierContact: boolean;
    public showEditSupplierContactQuestionnaire: boolean;

    public supplierContact: SupplierContact;

    ngOnInit(): void {
        this.clearSupplierContactView();
        this.showSupplierContactList = true;
    }

    clearSupplierContactView() {
        this.showSupplierContactList = false;
        this.showSupplierContact = false;
        this.showDeleteSupplierContact = false;
        this.showEditSupplierContact = false;
        this.showEditSupplierContactQuestionnaire = false;
    }

    showNewSupplierContact() {
        this.clearSupplierContactView();
        this.showSupplierContact = true;
    }

    onShowSupplierContactList() {
        this.clearSupplierContactView();
        this.showSupplierContactList = true;
    }


    onSupplierContactAdded(supplierContact: SupplierContact) {
        this.clearSupplierContactView();
        this.showSupplierContactList = true;
    }

    onSupplierContactEdit(supplierContact: SupplierContact) {
        this.supplierContact = supplierContact;
        this.clearSupplierContactView();
        this.showEditSupplierContact = true;
    }

    onSupplierContactQuestionnaireEdit(supplierContact: SupplierContact) {
        this.supplierContact = supplierContact;
        this.clearSupplierContactView();
        this.showEditSupplierContactQuestionnaire = true;
    }

    onSupplierContactDelete(supplierContact: SupplierContact) {
        this.supplierContact = supplierContact;
        this.clearSupplierContactView();
        this.showDeleteSupplierContact = true;
    }

    onRefreshSupplierContactList(hideSupplierContact: boolean) {
        this.clearSupplierContactView();
        this.showSupplierContactList = hideSupplierContact;
    }
}