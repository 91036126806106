﻿import { Injectable } from '@angular/core';
import { GeneralDocument } from './generalDocument';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class DocumentService {

    public generalDocument: GeneralDocument;
    public generalDocuments: GeneralDocument[];

    constructor(private http: HttpClient) { }

    public loadGeneralDocuments(): Observable<boolean> {

        return this.http
            .get("/api/documents/")
            .pipe(map((data: any) => {
                this.generalDocuments = data;
                return true;
            }));

    }

    public deleteGeneralDocument(generalDocumentId: number): Observable<boolean> {

        return this.http
            .delete("/api/documents/" + generalDocumentId)
            .pipe(map((data: any) => {
                this.generalDocument = data;
                return true;
            }));
    }

    public loadDocument(id: number) {
        return this.http.get('/api/documents/' + id, { responseType: "blob" })
            .pipe(map(blob => {
                return new Blob([blob], { type: "application/pdf" });
            }));
    }

}