﻿import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Supplier } from '../shared/supplier';
import { SupplierService } from '../shared/supplierService';

@Component({
    selector: "supplier-list",
    templateUrl: "supplierList.component.html",
    styleUrls: ["supplierList.component.css"]
})
export class SupplierListComponent implements OnInit, OnDestroy {

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

    public suppliers: Supplier[] = [];

    @Output() showEditSupplierEmitter = new EventEmitter<Supplier>();
    @Output() showDeleteSupplierEmitter = new EventEmitter<Supplier>();

    constructor(private supplierService: SupplierService) { }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngOnInit(): void {
        this.loadSuppliers();
    }

    loadSuppliers() {

        this.supplierService.loadSuppliers()
            .subscribe(success => {
                if (success) {

                    this.dtOptions = {
                        pagingType: 'full_numbers',
                        pageLength: 10
                    };

                    this.suppliers = this.supplierService.suppliers;
                    this.dtTrigger.next();
                }
            });

    }

    onEditSupplier(supplier: Supplier) {
        this.showEditSupplierEmitter.emit(supplier);
    }

}