﻿import { Component, OnInit, Input } from '@angular/core';
import { Load } from '../shared/load';
import { LoadService } from '../shared/loadService';
import { Cargo } from '../shared/cargo';
import { Location } from '../shared/location';
import { EquipmentService } from '../shared/equipmentService';
import { Equipment } from '../shared/equipment';
import { LoadCustomer } from '../shared/loadCustomer';
import { CargoLocation } from '../shared/cargoLocation';
import { forEach } from '@angular/router/src/utils/collection';
import { LoadSupplier } from '../shared/loadSupplier';

@Component({
    selector: "loadDetails",
    templateUrl: "loadDetails.component.html",
    styleUrls: ["loadDetails.component.css"]
})
export class LoadDetailsComponent implements OnInit {

    @Input() load: Load;
    @Input() loadCustomers: LoadCustomer[];
    @Input() loadSuppliers: LoadSupplier[];

    cargos: Cargo[] = [];
    selectedEquipment: Equipment[] = [];

    loadCommission: number = 0;
    loadSupplierRate: number = 0;
    loadCustomerRate: number = 0;

    constructor(private loadService: LoadService, private equipmentService: EquipmentService) {
    }

    ngOnInit(): void {
        console.log(this.load, this.loadCustomers, this.loadSuppliers);
        this.getLoadCargos();
        this.getEquipment();
        this.getFinancials();
    }

    getFinancials() {
        this.loadCustomers.forEach(l => {
            this.loadCommission += l.commission;
            this.loadCustomerRate += l.customerRate;
        });

        this.loadSuppliers.forEach(l => {
            this.loadSupplierRate += l.supplierRate;
        })
    }

    getEquipment() {
        this.cargos.forEach(cargo => {

            this.equipmentService.loadSelectedEquipment(cargo.id)
                .subscribe(s => {
                    if (s) {
                        this.selectedEquipment = this.equipmentService.equipments;
                    }
                });

        });
    }

    getLoadCargos() {

        this.loadCustomers = this.loadCustomers.filter(l => l.load.id == this.load.id);
        this.loadCustomers.forEach(l => {
            l.cargos.forEach(c => {
                this.cargos.push(c);
            });
        });

    }


}