﻿import { Component, Output, EventEmitter } from '@angular/core';
import { Equipment } from '../shared/equipment';
import { EquipmentService } from '../shared/equipmentService';

@Component({
    selector: "equipment-search",
    templateUrl: "equipmentSearch.component.html",
    styleUrls: ["equipmentSearch.component.css"]
})
export class EquipmentSearchComponent {

    @Output() equipmentEmitter = new EventEmitter<Equipment>();

    searchTerm: string;
    equipments: Equipment[] = [];
    errorMessage: string;

    constructor(private equipmentService: EquipmentService) { }

    onSearch() {
        this.errorMessage = "";

        this.equipmentService.searchEquipment(this.searchTerm)
            .subscribe(s => {
                if (s) {
                    this.equipments = this.equipmentService.equipments;

                    if (this.equipments.length == 0)
                        this.errorMessage = "No equipment found";
                }
                else {
                    this.errorMessage = "No equipment found";
                }
            });
    }

    onSelectEquipment(equipment: Equipment) {
        this.equipmentEmitter.emit(equipment);
    }

}