﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Contact } from '../shared/contact';
import { ContactService } from '../shared/contactService';

@Component({
    selector: "delete-contact",
    templateUrl: "contactDelete.component.html",
    styleUrls: ["contactDelete.component.css"]
})
export class ContactDeleteComponent {

    @Input() contact: Contact;
    @Output() toggle = new EventEmitter<boolean>();

    errorMessage: string = "";

    constructor(private contactService: ContactService) { }

    onDeleteContact() {

    }

}