﻿import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { Customer } from '../shared/customer';
import { CustomerService } from '../shared/customerService';
import { CompanyType } from '../shared/companyType';

@Component({
    selector: "customer-add",
    templateUrl: "customerAdd.component.html",
    styleUrls: ["customerAdd.component.css"]
})
export class CustomerAddComponent implements OnInit {

    @Input() customerName: string;

    public errorMessage: string;

    public customer = {
        id: 0,
        customerAccountNo:null,
        registeredName: null,
        tradingName: null,
        companyRegisteredNo: null,
        vatRegisteredNo: null,
        typeOfBusiness: null,
        dateTimeCommencedBusiness: null,
        companyType: null,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        addressLine4: null,
        addressLine5: null,
        city: null,
        postalAddressLine1: null,
        postalAddressLine2: null,
        postalAddressLine3: null,
        postalAddressLine4: null,
        postalAddressLine5: null,
        phone1: null,
        phone2: null,
        fax: null,
        email: null,
        bank: null,
        branchCode: null,
        accountNo: null,
        auditors: null,
        dateTimeSubmissionClosing: null,
        creditRequired: 0,
        associatedCompanies: null,
        customerStatus: null,
        dateTimeCreated: new Date(),
        isCreditApplicationForms: false,
        isDirectorIDsReceived: false
    }

    public contact = {
        id: 0,
        contactName: null,
        email: null,
        cell: null,
        isActive: true,
        isInternal:false
    }

    @Output() addCustomerEmitter = new EventEmitter<Customer>();

    constructor(private customerService: CustomerService) { }

    ngOnInit(): void {
        this.customer.tradingName = this.customerName;
    }
    
    onSubmit() {
        this.customerService.addCustomer(this.customer, this.contact)
            .subscribe(_ => {
                this.addCustomerEmitter.emit(this.customerService.customer);
            }, err => {
                this.errorMessage = 'Failed to add customer.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error;
                }
            });
    }
}