﻿import { Component, OnInit } from '@angular/core';
import { Supplier } from '../shared/supplier';

@Component({
    selector: "suppliers",
    templateUrl: "suppliers.component.html",
    styleUrls: ["suppliers.component.css"]
})
export class SuppliersComponent implements OnInit {

    public showSupplierList: boolean;
    public showSupplier: boolean;
    public showEditSupplier: boolean;

    public supplier: Supplier;

    ngOnInit(): void {
        this.clearSupplierView();
        this.showSupplierList = true;
    }

    clearSupplierView() {
        this.showSupplierList = false;
        this.showSupplier = false;
        this.showEditSupplier = false;
    }

    showNewSupplier() {
        this.clearSupplierView();
        this.showSupplier = true;
    }

    onShowSupplierList() {
        this.clearSupplierView();
        this.showSupplierList = true;
    }

    onSupplierAdded(supplier: Supplier) {
        this.clearSupplierView();
        this.showSupplierList = true;
    }

    onSupplierEdit(supplier: Supplier) {
        this.supplier = supplier;
        this.clearSupplierView();
        this.showEditSupplier = true;
    }

    onRefreshSupplierList(hideSupplier: boolean) {
        this.clearSupplierView();
        this.showSupplierList = hideSupplier;
    }

}