﻿import { Component, Input } from '@angular/core';
import { Customer } from '../../shared/customer';
import { CustomerDocument } from '../../shared/customerDocument';

@Component({
    selector: "customer-documents",
    templateUrl: "customerDocuments.component.html",
    styleUrls: ["customerDocuments.component.css"]
})
export class CustomerDocumentsComponent {

    @Input() customer: Customer;

    public showCustomerDocumentList: boolean;
    public showDeleteCustomerDocument: boolean;

    public customerDocument: CustomerDocument;

    ngOnInit(): void {
        this.clearCustomerDocumentView();
        this.showCustomerDocumentList = true;
    }

    clearCustomerDocumentView() {
        this.showCustomerDocumentList = false;
        this.showDeleteCustomerDocument = false;
    }

    onShowCustomerDocumentList() {
        this.clearCustomerDocumentView();
        this.showCustomerDocumentList = true;
    }

    onCustomerDocumentDelete(customerDocument: CustomerDocument) {
        this.customerDocument = customerDocument;
        this.clearCustomerDocumentView();
        this.showDeleteCustomerDocument = true;
    }

    onRefreshCustomerDocumentList(hideCustomerDocument: boolean) {
        this.clearCustomerDocumentView();
        this.showCustomerDocumentList = hideCustomerDocument;
    }

}
