﻿import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/authService';

@Component({
    selector: "login",
    templateUrl: "login.component.html",
    styleUrls: ["login.component.css"]
})
export class LoginComponent implements OnInit {

    isLoading: boolean;
    errorMessage: string;

    public creds = {
        id: null,
        firstName: "",
        surname: "",
        email: "",
        username: "",
        password: "",
        loginKey: "",
        isEnabled: true,
        store: ""
    };

    constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute) { }

    ngOnInit(): void {
        this.isLoading = false;
    }

    onLogin() {
        this.isLoading = true;
        this.creds.loginKey = this.route.snapshot.queryParamMap.get('id');
        // call login service
        this.auth.login(this.creds)
            .subscribe(success => {
                if (success) {
                    this.router.navigate([""]);
                }
            }, err => {
                this.isLoading = false;
                this.errorMessage = "Failed to login. " + err.error;
            });
    }

    onRegister() {
        this.router.navigate(['Register']);
    }

    onForgotPassword() {
        this.router.navigate(['ForgotPassword']);
    }
}