﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Equipment } from './equipment';
import { Cargo } from './cargo';

@Injectable()
export class EquipmentService {

    public equipment: Equipment;
    public equipments: Equipment[];

    constructor(private http: HttpClient) { }

    public loadEquipment(): Observable<boolean> {

        return this.http.get("/api/equipment")
            .pipe(map((data: any[]) => {
                this.equipments = data;
                return true;
            }));

    }

    public loadSelectedEquipment(cargoId: number): Observable<boolean> {

        return this.http.get("/api/equipment/ByCargo/" + cargoId)
            .pipe(map((data: any[]) => {
                this.equipments = data;
                return true;
            }));

    }

    public addEquipment(equipment: Equipment): Observable<boolean> {

        return this.http
            .post("/api/equipment", equipment)
            .pipe(map((data: any) => {
                this.equipment = data;
                return true;
            }));

    }

    public editEquipment(equipment: Equipment): Observable<boolean> {

        return this.http
            .put("/api/equipment", equipment)
            .pipe(map((data: any) => {
                this.equipment = data;
                return true;
            }));

    }

    public deleteEquipment(equipmentId: number): Observable<boolean> {

        return this.http
            .delete("/api/equipment/" + equipmentId)
            .pipe(map((data: any) => {
                this.equipment = data;
                return true;
            }));

    }

    public searchEquipment(searchTerm: string): Observable<boolean> {

        return this.http
            .get("/api/equipment/Search/" + searchTerm)
            .pipe(map((data: any) => {
                this.equipments = data;
                return true;
            }));

    }

    public addCargoEquipment(cargo: Cargo, equipment: Equipment): Observable<boolean> {

        return this.http
            .post("/api/equipment/AddToCargo/" + cargo.id + "/" + equipment.id, null)
            .pipe(map((data: any) => {
                this.equipment = data;
                return true;
            }));

    }

    
}