﻿import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Customer } from '../../shared/customer';
import { CustomerDocument } from '../../shared/customerDocument';
import { CustomerService } from '../../shared/customerService';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { HttpRequest, HttpClient, HttpEventType } from '@angular/common/http';
import { saveAs } from "file-saver";

@Component({
    selector: "customer-document-list",
    templateUrl: "customerDocumentList.component.html",
    styleUrls: ["customerDocumentList.component.css"]
})
export class CustomerDocumentsListComponent implements OnInit {
    
    @Input() customer: Customer;
    @Output() showDeleteCustomerDocumentEmitter = new EventEmitter<CustomerDocument>();

    isLoading: boolean;
    public uploadStatus: string;
    public files: UploadFile[] = [];

    public customerDocuments: CustomerDocument[] = [];

    constructor(private customerService: CustomerService, private http: HttpClient) { }

    ngOnInit(): void {
        this.loadCustomerDocuments();
        this.uploadStatus = "Drop documents to upload";
    }

    loadCustomerDocuments() {

        this.customerService.loadCustomerDocuments(this.customer.id)
            .subscribe(success => {
                if (success) {

                    this.customerDocuments = this.customerService.customerDocuments;
                }
            });

    }

    onDownloadCustomerDocument(customerDocument: CustomerDocument) {

        this.customerService.loadCustomerDocument(customerDocument.id).subscribe(
            (res) => {

                var file = new File([res], customerDocument.fileName, { type: "application/pdf" });
                saveAs(file);

            });

    }

    public dropped(event: UploadEvent) {
        this.isLoading = true;
        this.files = event.files;

        const fileCount = event.files.length;
        var fileCounter = 0;

        for (const droppedFile of event.files) {

            fileCounter++;

            // Is it a file?
            if (droppedFile.fileEntry.isFile) {

                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {

                    // You could upload it like this:
                    const formData = new FormData()
                    formData.append(droppedFile.relativePath, file)

                    const uploadReq = new HttpRequest('POST', 'api/upload/UploadCustomerDocument/' + this.customer.id, formData, {
                        reportProgress: true,
                    });

                    this.http.request(uploadReq).subscribe(event => {

                        if (event.type == HttpEventType.UploadProgress) {
                            this.uploadStatus = "Loading: " + (event.loaded / (event.total || event.loaded) * 100).toLocaleString('en-us', { maximumFractionDigits: 0 }) + '%';
                        }

                        if (event.type === HttpEventType.Response) {
                            if (event.body.toString() == 'Customer document upload successful.') {
                                this.isLoading = false;
                                alert('Document (' + droppedFile.relativePath + ') upload successful.');
                                if (fileCount == fileCounter) {
                                    this.loadCustomerDocuments();
                                }
                                this.uploadStatus = "Drop documents to add";
                            }
                        }

                        if (event.type === HttpEventType.ResponseHeader) {
                            if (event.status == 404) {
                                alert('Document upload failed. File is too large to upload.');
                                this.uploadStatus = "Drop documents to add";
                                this.isLoading = false;
                            } else if (event.status == 200) {
                                this.isLoading = false;
                                alert('Document (' + droppedFile.relativePath + ') upload successful.');
                                if (fileCount == fileCounter) {
                                    this.loadCustomerDocuments();
                                }
                                this.uploadStatus = "Drop documents to add";
                            }
                        }
                    });
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                this.isLoading = false;
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                alert("Error dropping directory!") 
                this.uploadStatus = "Drop documents to add";
            }
        }

    }

    onDeleteCustomerDocument(customerDocument: CustomerDocument) {
        this.showDeleteCustomerDocumentEmitter.emit(customerDocument);
    }
}
