﻿import { Component, OnInit, Input } from '@angular/core';
import { TradeReference } from '../../shared/tradeReference';
import { Customer } from '../../shared/customer';

@Component({
    selector: "trade-references",
    templateUrl: "tradeReferences.component.html",
    styleUrls: ["tradeReferences.component.css"]
})
export class TradeReferencesComponent implements OnInit {

    @Input() customer: Customer;

    public showTradeReferenceList: boolean;
    public showTradeReference: boolean;
    public showDeleteTradeReference: boolean;
    public showEditTradeReference: boolean;
    public showEditTradeReferenceQuestionnaire: boolean;

    public tradeReference: TradeReference;

    ngOnInit(): void {
        this.clearTradeReferenceView();
        this.showTradeReferenceList = true;
    }

    clearTradeReferenceView() {
        this.showTradeReferenceList = false;
        this.showTradeReference = false;
        this.showDeleteTradeReference = false;
        this.showEditTradeReference = false;
        this.showEditTradeReferenceQuestionnaire = false;
    }

    showNewTradeReference() {
        this.clearTradeReferenceView();
        this.showTradeReference = true;
    }

    onShowTradeReferenceList() {
        this.clearTradeReferenceView();
        this.showTradeReferenceList = true;
    }


    onTradeReferenceAdded(tradeReference: TradeReference) {
        this.clearTradeReferenceView();
        this.showTradeReferenceList = true;
    }

    onTradeReferenceEdit(tradeReference: TradeReference) {
        this.tradeReference = tradeReference;
        this.clearTradeReferenceView();
        this.showEditTradeReference = true;
    }

    onTradeReferenceQuestionnaireEdit(tradeReference: TradeReference) {
        this.tradeReference = tradeReference;
        this.clearTradeReferenceView();
        this.showEditTradeReferenceQuestionnaire = true;
    }

    onTradeReferenceDelete(tradeReference: TradeReference) {
        this.tradeReference = tradeReference;
        this.clearTradeReferenceView();
        this.showDeleteTradeReference = true;
    }

    onRefreshTradeReferenceList(hideTradeReference: boolean) {
        this.clearTradeReferenceView();
        this.showTradeReferenceList = hideTradeReference;
    }

}