﻿import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { TradeReference } from '../../shared/tradeReference';
import { CustomerService } from '../../shared/customerService';
import { Customer } from '../../shared/customer';

@Component({
    selector: "trade-reference-list",
    templateUrl: "tradeReferenceList.component.html",
    styleUrls: ["tradeReferenceList.component.css"]
})
export class TradeReferenceListComponent implements OnInit {

    @Input() customer: Customer;

    public tradeReferences: TradeReference[] = [];

    @Output() showEditTradeReferenceEmitter = new EventEmitter<TradeReference>();
    @Output() showDeleteTradeReferenceEmitter = new EventEmitter<TradeReference>();
    @Output() showEditTradeReferenceQuestionnaireEmitter = new EventEmitter<TradeReference>();

    constructor(private customerService: CustomerService) { }


    ngOnInit(): void {
        this.loadTradeReferences();
    }

    loadTradeReferences() {

        this.customerService.loadTradeReferences(this.customer.id)
            .subscribe(success => {
                if (success) {

                    this.tradeReferences = this.customerService.tradeReferences;
                }
            });

    }

    onEditTradeReference(tradeReference: TradeReference) {
        this.showEditTradeReferenceEmitter.emit(tradeReference);
    }

    onEditTradeReferenceQuestionnaire(tradeReference: TradeReference) {
        this.showEditTradeReferenceQuestionnaireEmitter.emit(tradeReference);
    }

    onDeleteTradeReference(tradeReference: TradeReference) {
        this.showDeleteTradeReferenceEmitter.emit(tradeReference);
    }

}