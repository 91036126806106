﻿import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CompanyDetail } from '../shared/companyDetail';
import { CompanyDetailService } from '../shared/companyDetailService';

@Component({
    selector: "company-detail",
    templateUrl: "companyDetail.component.html",
    styleUrls: ["companyDetail.component.css"]
})
export class CompanyDetailComponent implements OnInit {

    companyDetail: CompanyDetail;

    @Output() closeCompanyDetailEmitter = new EventEmitter<boolean>();

    errorMessage: string;

    constructor(private companyDetailService: CompanyDetailService) { }

    ngOnInit(): void {
        this.loadCompanyDetail();
    }

    loadCompanyDetail() {
        this.companyDetailService.loadCompanyDetail()
            .subscribe(x => {
                this.companyDetail = this.companyDetailService.companyDetail;
            });
    }

    onSubmit() {

        this.companyDetailService.editCompanyDetail(this.companyDetail)
            .subscribe(_ => {
                this.closeCompanyDetailEmitter.emit(true);
            }, err => {
                this.errorMessage = 'Failed to add company detail.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error + '. Please make sure the company detail is unique.'
                }
            });

    }

    onCancel() {
        this.closeCompanyDetailEmitter.emit(true);
    }

}