﻿import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Contact } from './contact';
import { Injectable } from '@angular/core';
import { ContactType } from './contactType';

@Injectable()
export class ContactService {

    constructor(private http: HttpClient) { }

    public contact: Contact;
    public contacts: Contact[];
    public contactTypes: ContactType[];

    public loadOpsContacts(): Observable<boolean> {

        return this.http.get("/api/contacts")
            .pipe(map((data: any[]) => {
                this.contacts = data;
                return true;
            }));
    }

    public addOpsContact(contact: Contact): Observable<boolean> {

        return this.http.post("/api/contacts", contact)
            .pipe(map((data: any) => {
                this.contact = data;
                return true;
            }));
    }

    public editOpsContact(contact: Contact): Observable<boolean> {

        return this.http
            .put("/api/contacts", contact)
            .pipe(map((data: any) => {
                this.contact = data;
                return true;
            }));

    }

    public deleteOpsContact(contactId: number): Observable<boolean> {

        return this.http
            .delete("/api/contacts/" + contactId)
            .pipe(map((data: any) => {
                this.contact = data;
                return true;
            }));

    }

    public loadContactTypes(): Observable<boolean> {

        return this.http.get("/api/contacts/ContactTypes")
            .pipe(map((data: any[]) => {
                this.contactTypes = data;
                return true;
            }));
    }
}