﻿import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { CustomerService } from '../../shared/customerService';
import { Customer } from '../../shared/customer';
import { CompanyDirector } from '../../shared/companyDirector';

@Component({
    selector: "company-director-list",
    templateUrl: "companyDirectorList.component.html",
    styleUrls: ["companyDirectorList.component.css"]
})
export class CompanyDirectorListComponent implements OnInit {

    @Input() customer: Customer;

    public companyDirectors: CompanyDirector[] = [];

    @Output() showEditCompanyDirectorEmitter = new EventEmitter<CompanyDirector>();
    @Output() showDeleteCompanyDirectorEmitter = new EventEmitter<CompanyDirector>();

    constructor(private customerService: CustomerService) { }


    ngOnInit(): void {
        this.loadCompanyDirectors();
    }

    loadCompanyDirectors() {

        this.customerService.loadCompanyDirectors(this.customer)
            .subscribe(success => {
                if (success) {

                    this.companyDirectors = this.customerService.companyDirectors;
                }
            });

    }

    onEditCompanyDirector(companyDirector: CompanyDirector) {
        this.showEditCompanyDirectorEmitter.emit(companyDirector);
    }

    onDeleteCompanyDirector(companyDirector: CompanyDirector) {
        this.showDeleteCompanyDirectorEmitter.emit(companyDirector);
    }

}