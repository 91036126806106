﻿import { Component, OnInit } from '@angular/core';
import { VehicleType } from '../shared/vehicleType';

@Component({
    selector: "vehicleTypes",
    templateUrl: "vehicleTypes.component.html",
    styleUrls: ["vehicleTypes.component.css"]
})
export class VehicleTypesComponent implements OnInit {

    public showVehicleTypeList: boolean;
    public showVehicleType: boolean;
    public showDeleteVehicleType: boolean;
    public showEditVehicleType: boolean;

    public vehicleType: VehicleType;

    ngOnInit(): void {
        this.clearVehicleTypeView();
        this.showVehicleTypeList = true;
    }

    clearVehicleTypeView() {
        this.showVehicleTypeList = false;
        this.showVehicleType = false;
        this.showDeleteVehicleType = false;
        this.showEditVehicleType = false;
    }

    showNewVehicleType() {
        this.clearVehicleTypeView();
        this.showVehicleType = true;
    }

    onShowVehicleTypeList() {
        this.clearVehicleTypeView();
        this.showVehicleTypeList = true;
    }

    onVehicleTypeAdded(vehicleType: VehicleType) {
        this.clearVehicleTypeView();
        this.showVehicleTypeList = true;
    }

    onVehicleTypeEdit(vehicleType: VehicleType) {
        this.vehicleType = vehicleType;
        this.clearVehicleTypeView();
        this.showEditVehicleType = true;
    }

    onVehicleTypeDelete(vehicleType: VehicleType) {
        this.vehicleType = vehicleType;
        this.clearVehicleTypeView();
        this.showDeleteVehicleType = true;
    }

    onRefreshVehicleTypeList(hideVehicleType: boolean) {
        this.clearVehicleTypeView();
        this.showVehicleTypeList = hideVehicleType;
    }

}