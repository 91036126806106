﻿import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { OrderNumberSeedService } from '../shared/orderNumberSeedService';
import { OrderNumberSeed } from '../shared/orderNumberSeed';

@Component({
    selector: "order-number-seed",
    templateUrl: "orderNumberSeed.component.html",
    styleUrls: ["orderNumberSeed.component.css"]
})
export class OrderNumberSeedComponent implements OnInit {

    orderNumberSeed: OrderNumberSeed;

    @Output() closeOrderNumberSeedEmitter = new EventEmitter<boolean>();

    errorMessage: string;

    constructor(private orderNumberSeedService: OrderNumberSeedService) { }

    ngOnInit(): void {
        this.loadOrderNumberSeed();
    }

    loadOrderNumberSeed() {
        this.orderNumberSeedService.loadOrderNumberSeed()
            .subscribe(x => {
                this.orderNumberSeed = this.orderNumberSeedService.orderNumberSeed;
            });
    }

    onSubmit() {

        this.orderNumberSeedService.editOrderNumberSeed(this.orderNumberSeed)
            .subscribe(_ => {
                this.closeOrderNumberSeedEmitter.emit(true);
            }, err => {
                    this.errorMessage = 'Failed to add order number seed.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error + '. Please make sure the order number seed is unique.'
                }
            });

    }

    onCancel() {
        this.closeOrderNumberSeedEmitter.emit(true);
    }

}