﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Equipment } from '../shared/equipment';
import { EquipmentService } from '../shared/equipmentService';

@Component({
    selector: "equipment-edit",
    templateUrl: "equipmentEdit.component.html",
    styleUrls: ["equipmentEdit.component.css"]
})
export class EquipmentEditComponent {

    @Input() equipment: Equipment;

    errorMessage: string;

    @Output() saveEquipmentEmitter = new EventEmitter<boolean>();

    constructor(private equipmentService: EquipmentService) { }     

    onSubmit() {

        this.equipmentService.editEquipment(this.equipment)
            .subscribe(_ => {
                this.saveEquipmentEmitter.emit(true);
            }, err => {
                this.errorMessage = 'Failed to add equipment.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error + '. Please make sure the equipment name is unique.'
                }
            });

    }

}