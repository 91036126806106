﻿import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { OrderNumberSeed } from './orderNumberSeed';

@Injectable()
export class OrderNumberSeedService {

    public orderNumberSeed: OrderNumberSeed;

    constructor(private http: HttpClient) { }

    public loadOrderNumberSeed(): Observable<boolean> {

        return this.http.get("/api/OrderNumberSeed")
            .pipe(map((data: any) => {
                this.orderNumberSeed = data;
                return true;
            }));
    }

    public editOrderNumberSeed(orderNumberSeed: OrderNumberSeed): Observable<boolean> {

        return this.http.put("/api/OrderNumberSeed/", orderNumberSeed)
            .pipe(map((data: any) => {
                this.orderNumberSeed = data;
                return (this.orderNumberSeed != null);
            }));

    }
}