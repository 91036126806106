﻿import { Component, Output, EventEmitter } from '@angular/core';
import { Equipment } from '../shared/equipment';
import { EquipmentService } from '../shared/equipmentService';

@Component({
    selector: "equipment-add",
    templateUrl: "equipmentAdd.component.html",
    styleUrls: ["equipmentAdd.component.css"]
})
export class EquipmentAddComponent {

    public errorMessage: string;

    public equipment = {
        id: 0,
        description: null,
        isActive: true,
        isChecked: false
    };

    @Output() saveEquipmentEmitter = new EventEmitter<Equipment>();

    constructor(private equipmentService: EquipmentService) { }

    onSubmit() {
        this.equipmentService.addEquipment(this.equipment)
            .subscribe(_ => {
                this.saveEquipmentEmitter.emit(this.equipmentService.equipment);
            }, err => {
                this.errorMessage = 'Failed to add equipment.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error + '. Please make sure the equipment name is unique.';
                }
            });
    }
}