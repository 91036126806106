﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Load } from './load';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Cargo } from './cargo';
import { Equipment } from './equipment';
import { CargoLocation } from './cargoLocation';
import { Location } from './location';
import { LoadCustomer } from './loadCustomer';
import { LoadSupplier } from './loadSupplier';
import { Supplier } from './supplier';
import { Contact } from './contact';
import { LoadDocument } from './loadDocument';

@Injectable()
export class LoadService {

    public loads: Load[];
    public load: Load;
    public cargo: Cargo;
    public supplier: Supplier;
    public cargos: Cargo[];
    public cargoLocations: CargoLocation[];
    public origin: Location;
    public destination: Location;
    public loadCustomer: LoadCustomer;
    public loadCustomers: LoadCustomer[];
    public loadSupplier: LoadSupplier;
    public loadSuppliers: LoadSupplier[];
    public supplierCMSOpsContact: Contact;
    public supplierContact: Contact;
    public loadDocument: LoadDocument;
    public loadDocuments: LoadDocument[];

    constructor(private http: HttpClient) { }

    public getLoads(): Observable<boolean> {

        return this.http.get("/api/loads")
            .pipe(map((data: any[]) => {
                this.loads = data;
                return true;
            }));

    }

    public getLoadsByDate(year:number, month:number, day: number): Observable<boolean> {

        return this.http.get("/api/loads/GetLoadsByDate/" + year + "/" + month + "/" + day)
            .pipe(map((data: any[]) => {
                this.loads = data;
                return true;
            }));

    }

    public getLoadCustomersNoDate(): Observable<boolean> {

        return this.http.get("/api/loads/GetLoadCustomersNoDate/")
            .pipe(map((data: any[]) => {
                this.loadCustomers = data;
                return true;
            }));

    }

    public getLoadCustomersByDate(year: number, month: number, day: number): Observable<boolean> {

        return this.http.get("/api/loads/GetLoadCustomersByDate/" + year + "/" + month + "/" + day)
            .pipe(map((data: any[]) => {
                this.loadCustomers = data;
                return true;
            }));

    }

    public getLoadSuppliersNoDate(): Observable<boolean> {

        return this.http.get("/api/loads/GetLoadSuppliersNoDate/")
            .pipe(map((data: any[]) => {
                this.loadSuppliers = data;
                return true;
            }));

    }

    public getLoadSuppliersByDate(year: number, month: number, day: number): Observable<boolean> {

        return this.http.get("/api/loads/GetLoadSuppliersByDate/" + year + "/" + month + "/" + day)
            .pipe(map((data: any[]) => {
                this.loadSuppliers = data;
                return true;
            }));

    }

    public getCargoLocationsByDate(year: number, month: number, day: number): Observable<boolean> {

        return this.http.get("/api/loads/GetCargoLocationsByDate/" + year + "/" + month + "/" + day)
            .pipe(map((data: any[]) => {
                this.cargoLocations = data;
                return true;
            }));

    }

    public getLoadsByLoadDate(year: number, month: number, day: number): Observable<boolean> {

        return this.http.get("/api/loads/GetLoadsByLoadDate/" + year + "/" + month + "/" + day)
            .pipe(map((data: any[]) => {
                this.loads = data;
                return true;
            }));

    }

    public getLoadCustomersByLoadDate(year: number, month: number, day: number): Observable<boolean> {

        return this.http.get("/api/loads/GetLoadCustomersByLoadDate/" + year + "/" + month + "/" + day)
            .pipe(map((data: any[]) => {
                this.loadCustomers = data;
                return true;
            }));

    }

    public getLoadSuppliersByLoadDate(year: number, month: number, day: number): Observable<boolean> {

        return this.http.get("/api/loads/GetLoadSuppliersByLoadDate/" + year + "/" + month + "/" + day)
            .pipe(map((data: any[]) => {
                this.loadSuppliers = data;
                return true;
            }));

    }

    public getCargoLocationsByLoadDate(year: number, month: number, day: number): Observable<boolean> {

        return this.http.get("/api/loads/GetCargoLocationsByLoadDate/" + year + "/" + month + "/" + day)
            .pipe(map((data: any[]) => {
                this.cargoLocations = data;
                return true;
            }));

    }

    public addLoad(load: Load): Observable<boolean> {

        return this.http.post("/api/loads/", load)
            .pipe(map((data: any) => {
                this.load = data;
                return (this.load != null);
            }), catchError(err => { return of(false); }));

    }

    public updateLoad(load: Load): Observable<boolean> {
        
        return this.http.put("/api/loads/", load)
            .pipe(map((data: any) => {
                this.load = data;
                return (this.load != null);
            }));

    }

    public deleteLoad(loadId: number): Observable<boolean> {

        return this.http
            .delete("/api/loads/" + loadId)
            .pipe(map((data: any) => {
                return data.deleteStatus;
            }));

    }

    public approveLoad(loadId: number): Observable<boolean> {

        return this.http.put("/api/loads/" + loadId + "/Approve", null)
            .pipe(map((data: any) => {
                this.load = data;
                return (this.load != null);
            }));

    }

    public addDriverToLoad(load: Load, driverId: number): Observable<boolean> {

        return this.http.put("/api/loads/AddDriver/" + driverId, load)
            .pipe(map((data: any) => {
                this.load = data;
                return (this.load != null);
            }));

    }

    public addVehicleToLoad(load: Load, vehicleId: number): Observable<boolean> {

        return this.http.put("/api/loads/AddVehicle/" + vehicleId, load)
            .pipe(map((data: any) => {
                this.load = data;
                return (this.load != null);
            }));

    }

    public addCustomerToLoad(load: Load, customerId: number): Observable<boolean> {

        return this.http.put("/api/loads/AddCustomer/" + customerId, load)
            .pipe(map((data: any) => {
                this.load = data;
                return (this.load != null);
            }));

    }

    public addCustomerContactToLoad(load: Load, customerContactId: number): Observable<boolean> {

        return this.http.put("/api/loads/AddCustomerContact/" + customerContactId, load)
            .pipe(map((data: any) => {
                this.load = data;
                return (this.load != null);
            }));

    }

    public addSupplierToLoad(loadSupplier: LoadSupplier): Observable<boolean> {

        return this.http.post("/api/loads/AddLoadSupplier/", loadSupplier)
            .pipe(map((data: any) => {
                this.loadSupplier = data;
                return (this.loadSupplier != null);
            }));

    }

    public addSupplierContactToLoad(load: Load, supplierContactId: number): Observable<boolean> {

        return this.http.put("/api/loads/AddSupplierContact/" + supplierContactId, load)
            .pipe(map((data: any) => {
                this.load = data;
                return (this.load != null);
            }));

    }

    public addCargoToLoad(loadId: number, cargo: Cargo): Observable<boolean> {

        return this.http.put("/api/loads/" + loadId + "/AddCargo/" + cargo.id, null)
            .pipe(map((data: any) => {
                this.load = data.load;
                this.cargo = data.cargo;
                return (this.load != null);
            }));

    }

    public addEquipmentToLoad(loadId: number, equipment: Equipment): Observable<boolean> {

        return this.http.put("/api/loads/AddEquipment/" + loadId, equipment)
            .pipe(map((data: any) => {
                this.load = data;
                return (this.load != null);
            }));

    }

    public getCargo(loadCustomerId: number): Observable<boolean> {

        return this.http.get("/api/loads/GetCargo/" + loadCustomerId)
            .pipe(map((data: any[]) => {
                this.cargos = data;
                return this.cargos != null;
            }));

    }

    //public getVehicle(loadId: number): Observable<boolean> {
    // //get vehicle type
    //    return this.http.get("/api/loads/GetVehicle/" + loadId)
    //        .pipe(map((data: any) => {
    //            this.vehicle = data;
    //            return this.vehicle != null;
    //        }));

    //}

    public getCargoLocations(loadId: number): Observable<boolean> {

        return this.http.get("/api/loads/GetCargoLocations/" + loadId)
            .pipe(map((data: any[]) => {
                this.cargoLocations = data;
                return this.cargoLocations != null;
            }));

    }

    public getOrigin(cargoId: number): Observable<boolean> {

        return this.http.get("/api/loads/GetOrigin/" + cargoId)
            .pipe(map((data: any) => {
                this.origin = data;
                return this.origin != null;
            }));

    }

    public getDestination(cargoId: number): Observable<boolean> {

        return this.http.get("/api/loads/GetDestination/" + cargoId)
            .pipe(map((data: any) => {
                this.destination = data;
                return this.destination != null;
            }));

    }

    public getLoadCustomers(loadId: number): Observable<boolean> {

        return this.http.get("/api/loads/GetLoadCustomers/" + loadId)
            .pipe(map((data: any) => {
                this.loadCustomers = data;
                return this.loadCustomers != null;
            }));

    }

    public getSupplier(loadId: number): Observable<boolean> {

        return this.http.get("/api/loads/GetSupplier/" + loadId)
            .pipe(map((data: any) => {
                this.supplier = data.supplier;
                this.loadSupplier = data.loadSupplier;
                return this.supplier != null;
            }));
    }

    public getSupplierContacts(loadId: number): Observable<boolean> {

        return this.http.get("/api/loads/GetSupplierContacts/" + loadId)
            .pipe(map((data: any) => {
                this.supplierCMSOpsContact = data.supplierCMSOpsContact;
                this.supplierContact = data.supplierContact;
                return this.supplier != null;
            }));
    }

    public addLoadCustomers(loadCustomers: LoadCustomer[], load: Load): Observable<boolean> {

        return this.http.post("/api/loads/AddLoadCustomers/" + load.id, loadCustomers)
            .pipe(map((data: any) => {
                this.loadCustomers = data;
                return (this.loadCustomers != null);
            }));

    }

    public addLoadCustomer(loadCustomer: LoadCustomer, loadId: number): Observable<boolean> {

        return this.http.post("/api/loads/AddLoadCustomer/" + loadId, loadCustomer)
            .pipe(map((data: any) => {
                this.loadCustomer = data;
                return (this.loadCustomer != null);
            }), catchError(err => { return of(false); }));

    }

    public editLoadCustomer(loadCustomer: LoadCustomer, loadId: number): Observable<boolean> {

        return this.http.put("/api/loads/EditLoadCustomer/" + loadId, loadCustomer)
            .pipe(map((data: any) => {
                this.loadCustomer = data;
                return (this.loadCustomer != null);
            }), catchError(err => { return of(false); }));

    }

    public removeCustomerFromLoad(loadCustomer: LoadCustomer): Observable<boolean> {
        return this.http.delete("/api/loads/RemoveLoadCustomer/" + loadCustomer.id)
            .pipe(map((data: any) => {
                return data.status;
            }));
    }

    public printPurchaseOrder(loadId: number) {

        return this.http
            .get("/api/print/PurchaseOrder/" + loadId, { responseType: "blob" })
            .pipe(map(blob => {
                return new Blob([blob], { type: "application/pdf" });
            }));

    }

    public printSalesOrder(loadCustomerId: number) {

        return this.http
            .get("/api/print/SalesOrder/" + loadCustomerId, { responseType: "blob" })
            .pipe(map(blob => {
                return new Blob([blob], { type: "application/pdf" });
            }));

    }

    public printProofOfDelivery(loadCustomerId: number) {

        return this.http
            .get("/api/print/ProofOfDelivery/" + loadCustomerId, { responseType: "blob" })
            .pipe(map(blob => {
                return new Blob([blob], { type: "application/pdf" });
            }));

    }

    public loadLoadDocuments(loadId: number): Observable<boolean> {

        return this.http
            .get("/api/loads/LoadDocuments/" + loadId)
            .pipe(map((data: any) => {
                this.loadDocuments = data;
                return true;
            }));

    }

    public deleteLoadDocument(loadDocumentId: number): Observable<boolean> {

        return this.http
            .delete("/api/loads/LoadDocuments/" + loadDocumentId)
            .pipe(map((data: any) => {
                this.loadDocument = data;
                return true;
            }));
    }

    public loadLoadDocument(loadDocumentId: number) {
        return this.http.get('/api/loads/CustomerDocuments/' + loadDocumentId + "/file", { responseType: "blob" })
            .pipe(map(blob => {
                return new Blob([blob], { type: "application/pdf" });
            }));
    }
}