﻿import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { VehicleType } from '../shared/vehicleType';
import { VehicleService } from '../shared/vehicleService';
import { CompanyType } from '../shared/companyType';

@Component({
    selector: "vehicleType-add",
    templateUrl: "vehicleTypeAdd.component.html",
    styleUrls: ["vehicleTypeAdd.component.css"]
})
export class VehicleTypeAddComponent implements OnInit {

    public errorMessage: string;

    dropdownSettings = {};

    public vehicleType = {
        id: 0,
        type: null,
        isActive: true
    }

    @Output() addVehicleTypeEmitter = new EventEmitter<VehicleType>();

    constructor(private vehicleService: VehicleService) { }

    ngOnInit(): void {
        this.configureDropDownSettings();
    }

    configureDropDownSettings() {

        this.dropdownSettings = {
            singleSelection: true,
            idField: 'id',
            textField: 'type',
            itemsShowLimit: 4,
            allowSearchFilter: false
        };


    }
    
    onSubmit() {
        this.vehicleService.addVehicleType(this.vehicleType)
            .subscribe(_ => {
                this.vehicleType = this.vehicleService.vehicleType;
                this.addVehicleTypeEmitter.emit(this.vehicleService.vehicleType);
            }, err => {
                this.errorMessage = 'Failed to add vehicleType.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error + '. Please make sure the vehicle types are unique.';
                }
            });
    }

  
}