﻿import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CustomerService } from '../../shared/customerService';
import { CustomerContact } from '../../shared/customerContact';
import { ContactType } from '../../shared/contactType';
import { ContactService } from '../../shared/contactService';

@Component({
    selector: "customer-contact-edit",
    templateUrl: "customerContactEdit.component.html",
    styleUrls: ["customerContactEdit.component.css"]
})
export class CustomerContactEditComponent {

    @Input() customerContact: CustomerContact;

    errorMessage: string;

    dropdownSettings = {};

    contactTypes: ContactType[] = [];
    contactType: ContactType;

    @Output() editCustomerContactEmitter = new EventEmitter<boolean>();

    constructor(private customerService: CustomerService, private contactService: ContactService) { }

    ngOnInit(): void {
        this.loadContactTypes();
    }

    loadContactTypes() {
        this.contactService.loadContactTypes()
            .subscribe(s => {

                this.contactTypes = this.contactService.contactTypes;
                this.contactType = this.contactTypes.find(t => t.id == this.customerContact.contactType.id);

            });
    }

    onContactTypeSelect(id) {
        this.contactType = this.contactTypes.find(t => t.id == id);
    }

    onSubmit() {
        this.customerService.editCustomerContact(this.customerContact.contact, this.customerContact.customer.id, this.contactType.id)
            .subscribe(_ => {

                this.customerContact = this.customerService.customerContact;
                this.editCustomerContactEmitter.emit(true);

            }, err => {
                this.errorMessage = 'Failed to edit customer.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error;
                }
            });
    }

}
