﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SupplierService } from '../../shared/supplierService';
import { SupplierContact } from '../../shared/supplierContact';

@Component({
    selector: "supplier-contact-delete",
    templateUrl: "supplierContactDelete.component.html",
    styleUrls: ["supplierContactDelete.component.css"]
})
export class SupplierContactDeleteComponent {
    public errorMessage: string;
    @Input() supplierContact: SupplierContact;

    @Output() deleteSupplierContactEmitter = new EventEmitter<SupplierContact>();

    constructor(private supplierService: SupplierService) { }

    onDelete() {
        this.supplierService.deleteSupplierContact(this.supplierContact.id)
            .subscribe(_ => {
                this.deleteSupplierContactEmitter.emit(this.supplierService.supplierContact);
            });
    }
}