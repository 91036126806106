﻿import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Component } from '@angular/core';
import { AuthService } from '../auth/authService';

@Component({
    selector: "customer-login",
    templateUrl: "customerLogin.component.html",
    styleUrls: ["customerLogin.component.css"]
})
export class CustomerLoginComponent {

    errorMessage: string = "";

    constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute) { }

    ngOnInit() {
        let id = this.route.snapshot.paramMap.get('id');

        // call login service
        this.auth.customerLogin(id)
            .subscribe(success => {
                if (success) {
                    this.router.navigate([""]);
                }
            }, err => {
                this.errorMessage = "Failed to login. " + err.error;
            });
    }


}