﻿import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { SupplierService } from '../../shared/supplierService';
import { Supplier } from '../../shared/supplier';
import { Contact } from '../../shared/contact';
import { SupplierContact } from '../../shared/supplierContact';
import { ContactType } from '../../shared/contactType';
import { ContactService } from '../../shared/contactService';

@Component({
    selector: "supplier-contact-add",
    templateUrl: "supplierContactAdd.component.html",
    styleUrls: ["supplierContactAdd.component.css"]
})
export class SupplierContactAddComponent implements OnInit {

    @Input() supplier: Supplier;
    public errorMessage: string;

    dropdownSettings = {};

    public contact = {
        id: 0,
        contactName: null,
        email: null,
        cell: null,
        isInternal: false,
        isActive: true
    }

    contactTypes: ContactType[] = [];
    contactType: ContactType;

    @Output() addSupplierContactEmitter = new EventEmitter<SupplierContact>();

    constructor(private supplierService: SupplierService, private contactService: ContactService) { }

    ngOnInit(): void {
        this.loadContactTypes();
    }

    loadContactTypes() {
        this.contactService.loadContactTypes()
            .subscribe(s => {

                this.contactTypes = this.contactService.contactTypes;

            });
    }

    onContactTypeSelect(id) {
        this.contactType = this.contactTypes.find(t => t.id == id);
    }

    onSubmit() {
        this.supplierService.addSupplierContact(this.supplier.id, this.contact, this.contactType.id)
            .subscribe(_ => {

                this.addSupplierContactEmitter.emit(this.supplierService.supplierContact);

            }, err => {
                this.errorMessage = 'Failed to add contact.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error;
                }
            });
    }
}