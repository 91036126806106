﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CustomerDocument } from '../../shared/customerDocument';
import { CustomerService } from '../../shared/customerService';

@Component({
    selector: "customer-document-delete",
    templateUrl: "customerDocumentDelete.component.html",
    styleUrls: ["customerDocumentDelete.component.css"]
})
export class CustomerDocumentsDeleteComponent {

    @Input() customerDocument: CustomerDocument;
    @Output() deleteCustomerDocumentEmitter = new EventEmitter<CustomerDocument>();
    @Output() cancelEmitter = new EventEmitter<boolean>();

    constructor(private customerService: CustomerService) { }

    onDelete() {
        this.customerService.deleteCustomerDocument(this.customerDocument.id)
            .subscribe(_ => {
                this.deleteCustomerDocumentEmitter.emit(this.customerService.customerDocument);
            });
    }

    onCancelDelete() {
        this.cancelEmitter.emit(true);
    }
}
