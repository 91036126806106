﻿import { Component, Input } from '@angular/core';
import { Customer } from '../../shared/customer';
import { CompanyDirector } from '../../shared/companyDirector';

@Component({
    selector: "company-directors",
    templateUrl: "companyDirectors.component.html",
    styleUrls: ["companyDirectors.component.css"]
})
export class CompanyDirectorsComponent {
    @Input() customer: Customer;

    public showCompanyDirectorList: boolean;
    public showCompanyDirector: boolean;
    public showDeleteCompanyDirector: boolean;
    public showEditCompanyDirector: boolean;
    public showEditCompanyDirectorQuestionnaire: boolean;

    public companyDirector: CompanyDirector;

    ngOnInit(): void {
        this.clearCompanyDirectorView();
        this.showCompanyDirectorList = true;
    }

    clearCompanyDirectorView() {
        this.showCompanyDirectorList = false;
        this.showCompanyDirector = false;
        this.showDeleteCompanyDirector = false;
        this.showEditCompanyDirector = false;
        this.showEditCompanyDirectorQuestionnaire = false;
    }

    showNewCompanyDirector() {
        this.clearCompanyDirectorView();
        this.showCompanyDirector = true;
    }

    onShowCompanyDirectorList() {
        this.clearCompanyDirectorView();
        this.showCompanyDirectorList = true;
    }


    onCompanyDirectorAdded(companyDirector: CompanyDirector) {
        this.clearCompanyDirectorView();
        this.showCompanyDirectorList = true;
    }

    onCompanyDirectorEdit(companyDirector: CompanyDirector) {
        this.companyDirector = companyDirector;
        this.clearCompanyDirectorView();
        this.showEditCompanyDirector = true;
    }

    onCompanyDirectorQuestionnaireEdit(companyDirector: CompanyDirector) {
        this.companyDirector = companyDirector;
        this.clearCompanyDirectorView();
        this.showEditCompanyDirectorQuestionnaire = true;
    }

    onCompanyDirectorDelete(companyDirector: CompanyDirector) {
        this.companyDirector = companyDirector;
        this.clearCompanyDirectorView();
        this.showDeleteCompanyDirector = true;
    }

    onRefreshCompanyDirectorList(hideCompanyDirector: boolean) {
        this.clearCompanyDirectorView();
        this.showCompanyDirectorList = hideCompanyDirector;
    }
}