﻿import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Cargo } from '../shared/cargo';
import { CargoLocation } from '../shared/cargoLocation';
import { CargoService } from '../shared/cargoService';
import { ContainerType } from '../shared/containerType';
import { Equipment } from '../shared/equipment';
import { EquipmentService } from '../shared/equipmentService';
import { LoadCustomer } from '../shared/loadCustomer';
import { Location } from '../shared/location';
import { LocationService } from '../shared/locationService';
import { SupplierService } from '../shared/supplierService';
import { isUndefined } from 'util';
import { CargoEquipment } from '../shared/cargoEquipment';
import { config } from 'rxjs';

@Component({
    selector: "cargo-add",
    templateUrl: "cargoAdd.component.html",
    styleUrls: ["cargoAdd.component.css", "../app.component.css"]
})
export class CargoAddComponent implements OnInit {

    isPageLoaded: boolean = false;

    //@Input() loadCustomer: LoadCustomer;
    @Output() addCargoEmitter = new EventEmitter<Cargo>();
    @Output() addCargoLocationEmitter = new EventEmitter<CargoLocation>();
    @Output() addCargoEquipmentEmitter = new EventEmitter<CargoEquipment[]>();
    @Output() cancelCargoEmitter = new EventEmitter<boolean>();

    //private _pullCargo: boolean;

    //@Input() set pullCargo(value: boolean) {
    //    this._pullCargo = value;

    //    if (this.isPageLoaded)
    //        this.onSubmit();
    //}

    //get pullCargo(): boolean {
    //    return this._pullCargo;
    //}
    
    public errorMessage: string;

    @Input() cargo: Cargo;// = {
    //    id: 0,
    //    description: null,
    //    weight: 0,
    //    size: 0,
    //    containerType: null,
    //    dateTimeCollectionCustomer: null,
    //    dateTimeCollectionSupplier: null,
    //    dateTimeDeliveryCustomer: null,
    //    dateTimeDeliverySupplier: null,
    //    dateTimeBooking: null
    //}


    selectedItemsContainerType: ContainerType = {
        id: 0,
        type: ''
    };
    containerTypes: ContainerType[] = [];

    equipments: Equipment[];

    selectedEquipment: Equipment[] = [];
    //@Input() cargoEquipment: CargoEquipment[] = [];
    equipmentDropdownSettings = {};
    containerTypeDropdownSettings = {};

    origin: Location;
    destination: Location;

    constructor(private supplierService: SupplierService, private equipmentService: EquipmentService, private cargoService: CargoService, private locationService: LocationService) { }

    ngOnInit(): void {

        this.loadContainerTypes();
        this.loadEquipment();
        //this.loadSelectedContainerType();
        this.loadOrigin();
        this.loadDestination();

        //this.configureDropDownSettings();
        this.isPageLoaded = true;

    }

    //configureDropDownSettings() {

    //    this.equipmentDropdownSettings = {
    //        singleSelection: false,
    //        idField: 'id',
    //        textField: 'description',
    //        selectAllText: 'Select All',
    //        unSelectAllText: 'UnSelect All',
    //        itemsShowLimit: 3,
    //        allowSearchFilter: false
    //    };

    //    this.containerTypeDropdownSettings = {
    //        singleSelection: true,
    //        idField: 'id',
    //        textField: 'type',
    //        itemsShowLimit: 3,
    //        allowSearchFilter: false
    //    };


    //}

    loadEquipment() {

        this.equipmentService.loadEquipment()
            .subscribe(s => {
                if (s) {
                    this.equipments = this.equipmentService.equipments;

                    this.cargo.equipments = [];
                    this.equipments.forEach(e => {
                        var ce: CargoEquipment = { id: 0, cargo: this.cargo, equipment: e };
                        this.cargo.equipments.push(ce.equipment);
                    });

                    if (this.cargo.id > 0) {

                        this.equipmentService.loadSelectedEquipment(this.cargo.id)
                            .subscribe(s => {
                                if (s) {
                                    this.selectedEquipment = this.equipmentService.equipments;

                                    this.equipments.forEach(e => {
                                        if (this.selectedEquipment.find(s => s.description == e.description)) {
                                            e.isChecked = true;
                                        } else {
                                            e.isChecked = false;
                                        }
                                    });
                                }
                                else {
                                    this.equipments.forEach(e => {
                                        e.isChecked = false;
                                    });
                                }
                            });

                        

                    }
                    else {
                        this.equipments.forEach(e => {
                            e.isChecked = false;
                        });
                    }

                    
                }
            });
    }

    loadContainerTypes() {

        this.supplierService.loadContainerTypes()
            .subscribe(s => {
                if (s) {
                    this.containerTypes = this.supplierService.containerTypes;
                }
            });

    }

    //loadSelectedContainerType() {

    //    this.cargoService.getContainerType(this.cargo.id)
    //        .subscribe(x => {

    //            if (x) {
    //                //this.selectedItemsContainerTypes = [];
    //                //this.selectedItemsContainerTypes.push(this.cargoService.containerType);
    //                this.selectedItemsContainerType = this.cargoService.containerType;
    //            }
    //        });

    //}


    //onSubmit() {

    //    this.errorMessage = "";

    //    if (!isUndefined(this.selectedItemsContainerType)) {

    //        this.cargo.containerType = this.selectedItemsContainerType.type;

    //        var d = new Date('2018-01-01');
    //        var o = - d.getTimezoneOffset();

    //        if (this.cargo.dateTimeCollectionCustomer == null) { this.cargo.dateTimeCollectionCustomer = new Date(1900, 0, 1, 0, o, 0); } else { this.cargo.dateTimeCollectionCustomer = new Date(this.cargo.dateTimeCollectionCustomer.getTime() + o * 60000); }
    //        if (this.cargo.dateTimeCollectionSupplier == null) { this.cargo.dateTimeCollectionSupplier = new Date(1900, 0, 1, 0, o, 0); } else { this.cargo.dateTimeCollectionSupplier = new Date(this.cargo.dateTimeCollectionSupplier.getTime() + o * 60000); }
    //        if (this.cargo.dateTimeDeliveryCustomer == null) { this.cargo.dateTimeDeliveryCustomer = new Date(1900, 0, 1, 0, o, 0); } else { this.cargo.dateTimeDeliveryCustomer = new Date(this.cargo.dateTimeDeliveryCustomer.getTime() + o * 60000); }
    //        if (this.cargo.dateTimeDeliverySupplier == null) { this.cargo.dateTimeDeliverySupplier = new Date(1900, 0, 1, 0, o, 0); } else { this.cargo.dateTimeDeliverySupplier = new Date(this.cargo.dateTimeDeliverySupplier.getTime() + o * 60000); }
    //        if (this.cargo.dateTimeBooking == null) { this.cargo.dateTimeBooking = new Date(1900, 0, 1, 0, o, 0); } else { this.cargo.dateTimeBooking = new Date(this.cargo.dateTimeBooking.getTime() + o * 60000); }

    //        this.cargoService.addCargo(this.cargo, this.loadCustomer)
    //            .subscribe(s => {

    //                if (s) {

    //                    this.cargo = this.cargoService.cargo;

    //                    if (this.origin != null) {

    //                        this.addCargoOrigin();

    //                    } else {

    //                        if (this.destination != null) {

    //                            this.addCargoDestination();

    //                        } else {

    //                            if (this.equipments.filter(e => e.isChecked == true).length > 0) {

    //                                this.addCargoEquipment();

    //                            }

    //                            this.addCargoEmitter.emit(this.cargo);

    //                        }

    //                    }


    //                }

    //            });
    //    }
    //    else {
    //        this.errorMessage = "Information missing! Please select container type.";
    //        //this._pullCargo = false;
    //        this.cancelCargoEmitter.emit(true);
    //    }
    //}

    addCargoEquipment() {
        // add each equipment
        this.equipments.filter(e => e.isChecked == true).forEach(e => {
            this.selectedEquipment.push(e);
        });

        this.selectedEquipment.forEach(e => {

            this.cargoService.addCargoEquipment(this.cargo.id, e.id)
                .subscribe(x => {
                    console.log("Equipment added");
                });

        });
    }

    loadOrigin() {

        this.locationService.loadOrigin(this.cargo)
            .subscribe(s => {
                if (s) {
                    if (this.locationService.origin) {
                        this.origin = this.locationService.origin;
                    }
                }
            });

    }

    loadDestination() {

        this.locationService.loadDestination(this.cargo)
            .subscribe(s => {
                if (s) {
                    if (this.locationService.destination) {
                        this.destination = this.locationService.destination;
                    }
                }
            });

    }

    addCargoOrigin() {
        this.locationService.addCargoOrigin(this.cargo, this.origin)
            .subscribe(x => {
                //this.addCargoLocationEmitter.emit(this.locationService.cargoOrigin);

                if (this.destination != null) {

                    this.addCargoDestination();

                } else {

                    this.addCargoEmitter.emit(this.cargo);

                    if (this.equipments.filter(e => e.isChecked == true).length > 0) {

                        this.addCargoEquipment();

                    }

                }
            });
    }

    addCargoDestination() {
        this.locationService.addCargoDestination(this.cargo, this.destination)
            .subscribe(x => {
                //this.addCargoLocationEmitter.emit(this.locationService.cargoDestination);

                this.addCargoEmitter.emit(this.cargo);

                if (this.equipments.filter(e => e.isChecked == true).length > 0) {

                    this.addCargoEquipment();

                }
            });
    }

    //onContainerTypeSelect(item: any) {
    //    this.cargo.containerType = item.type;
    //}
    //onContainerTypeDeselect(item: any) {
    //    this.cargo.containerType = null;
    //}

    onChangeDateTimeCollectionCustomer(data: any) {
        //var date = new Date(data);
        this.cargo.dateTimeCollectionSupplier = data;
    }

    onChangeDateTimeDeliveryCustomer(data:any) {
        this.cargo.dateTimeDeliverySupplier = data;
        this.cargo.dateTimeBooking = data;
    }

    onEquipmentSelect(item: any) {
        this.selectedEquipment.push(item);
    }
    onEquipmentDeselect(item: any) {
        this.selectedEquipment = this.selectedEquipment.filter(e => e != item);
    }
    onSelectAllEquipment(items: any) {
        this.selectedEquipment = this.equipments;
    }
    onDeselectAllEquipment(items: any) {
        this.selectedEquipment = [];
    }

    onCancelCargo() {
        this.cancelCargoEmitter.emit(true);
    }

    onSetOrigin(origin: Location) {
        this.origin = origin;

        var cargoLocation: CargoLocation = { id: 0, cargo: this.cargo, location: this.origin, type: 'Origin' };
        this.addCargoLocationEmitter.emit(cargoLocation);
    }

    onSetDestination(destination: Location) {
        this.destination = destination;

        var cargoLocation: CargoLocation = { id: 0, cargo: this.cargo, location: this.destination, type: 'Destination' };
        this.addCargoLocationEmitter.emit(cargoLocation);
    }
}