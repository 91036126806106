﻿import { Component, OnInit } from '@angular/core';
import { Customer } from '../shared/customer';

@Component({
    selector: "customers",
    templateUrl: "customers.component.html",
    styleUrls: ["customers.component.css"]
})
export class CustomersComponent implements OnInit {

    public showCustomerList: boolean;
    public showCustomer: boolean;
    public showEditCustomer: boolean;

    public customer: Customer;

    ngOnInit(): void {
        this.clearCustomerView();
        this.showCustomerList = true;
    }

    clearCustomerView() {
        this.showCustomerList = false;
        this.showCustomer = false;
        this.showEditCustomer = false;
    }

    showNewCustomer() {
        this.clearCustomerView();
        this.showCustomer = true;
    }

    onShowCustomerList() {
        this.clearCustomerView();
        this.showCustomerList = true;
    }

    onCustomerAdded(customer: Customer) {
        this.clearCustomerView();
        this.showCustomerList = true;
    }

    onCustomerEdit(customer: Customer) {
        this.customer = customer;
        this.clearCustomerView();
        this.showEditCustomer = true;
    }

    onRefreshCustomerList(hideCustomer: boolean) {
        this.clearCustomerView();
        this.showCustomerList = hideCustomer;
    }

}