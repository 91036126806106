﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SupplierDocument } from '../../shared/supplierDocument';
import { SupplierService } from '../../shared/supplierService';

@Component({
    selector: "supplier-document-delete",
    templateUrl: "supplierDocumentDelete.component.html",
    styleUrls: ["supplierDocumentDelete.component.css"]
})
export class SupplierDocumentsDeleteComponent {

    @Input() supplierDocument: SupplierDocument;
    @Output() deleteSupplierDocumentEmitter = new EventEmitter<SupplierDocument>();
    @Output() cancelEmitter = new EventEmitter<boolean>();

    constructor(private supplierService: SupplierService) { }

    onDelete() {
        this.supplierService.deleteSupplierDocument(this.supplierDocument.id)
            .subscribe(_ => {
                this.deleteSupplierDocumentEmitter.emit(this.supplierService.supplierDocument);
            });
    }

    onCancelDelete() {
        this.cancelEmitter.emit(true);
    }
}
