﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { User } from '../shared/user';
import { UserService } from '../shared/userService';

@Component({
    selector: "user-disable",
    templateUrl: "userDisable.component.html",
    styleUrls: ["userDisable.component.css"]
})
export class UserDisableComponent {

    public errorMessage: string;

    @Input() user: User;

    @Output() saveUserEmitter = new EventEmitter<User>();

    constructor(private userService: UserService) { }

    onDisable() {
        this.userService.disableUser(this.user)
            .subscribe(_ => {
                this.saveUserEmitter.emit(this.userService.user);
            }, err => {
                this.errorMessage = 'Failed to disable user.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error;
                }
            });
    }
}