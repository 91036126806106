﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CustomerService } from '../../shared/customerService';
import { CustomerContact } from '../../shared/customerContact';

@Component({
    selector: "customer-contact-delete",
    templateUrl: "customerContactDelete.component.html",
    styleUrls: ["customerContactDelete.component.css"]
})
export class CustomerContactDeleteComponent {
    public errorMessage: string;
    @Input() customerContact: CustomerContact;

    @Output() deleteCustomerContactEmitter = new EventEmitter<CustomerContact>();

    constructor(private customerService: CustomerService) { }

    onDelete() {
        this.customerService.deleteCustomerContact(this.customerContact.id)
            .subscribe(_ => {
                this.deleteCustomerContactEmitter.emit(this.customerService.customerContact);
            });
    }
}