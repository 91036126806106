﻿import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CustomerService } from '../../shared/customerService';
import { CompanyDirector } from '../../shared/companyDirector';
import { ContactType } from '../../shared/contactType';
import { ContactService } from '../../shared/contactService';

@Component({
    selector: "company-director-edit",
    templateUrl: "companyDirectorEdit.component.html",
    styleUrls: ["companyDirectorEdit.component.css"]
})
export class CompanyDirectorEditComponent {

    @Input() companyDirector: CompanyDirector;

    errorMessage: string;

    dropdownSettings = {};

    @Output() editCompanyDirectorEmitter = new EventEmitter<boolean>();

    constructor(private customerService: CustomerService, private contactService: ContactService) { }

    onSubmit() {
        this.customerService.editCompanyDirector(this.companyDirector)
            .subscribe(_ => {

                this.companyDirector = this.customerService.companyDirector;
                this.editCompanyDirectorEmitter.emit(true);

            }, err => {
                this.errorMessage = 'Failed to edit customer.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error;
                }
            });
    }

}
