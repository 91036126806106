﻿import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TradeReference } from '../../shared/tradeReference';
import { CustomerService } from '../../shared/customerService';

@Component({
    selector: "trade-reference-edit",
    templateUrl: "tradeReferenceEdit.component.html",
    styleUrls: ["tradeReferenceEdit.component.css"]
})
export class TradeReferenceEditComponent {

    @Input() tradeReference: TradeReference;

    errorMessage: string;

    dropdownSettings = {};

    @Output() editTradeReferenceEmitter = new EventEmitter<boolean>();

    constructor(private customerService: CustomerService) { }

    onSubmit() {
        this.customerService.editTradeReference(this.tradeReference)
            .subscribe(_ => {

                this.tradeReference = this.customerService.tradeReference;
                this.editTradeReferenceEmitter.emit(true);

            });
    }

}
