﻿import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Customer } from '../../shared/customer';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { CustomerService } from '../../shared/customerService';
import { startWith, map, concat } from 'rxjs/operators';
import { isUndefined } from 'util';
import { Load } from '../../shared/load';
import { LoadCustomer } from '../../shared/loadCustomer';
import { Contact } from '../../shared/contact';
import { LoadService } from '../../shared/loadService';
import { Cargo } from '../../shared/cargo';
import { CargoLocation } from '../../shared/cargoLocation';
import { CargoEquipment } from '../../shared/cargoEquipment';
import { Equipment } from '../../shared/equipment';
import { CargoService } from '../../shared/cargoService';
import { saveAs } from "file-saver";

@Component({
    selector: "load-customer",
    templateUrl: "loadCustomer.component.html",
    styleUrls: ["loadCustomer.component.css", "../../app.component.css"]
})
export class LoadCustomerComponent implements OnInit, OnChanges  {
    
    @Input() load: Load;
    @Input() contacts: Contact[];

    pullCargo: boolean;

    private _pullLoadCustomer: boolean;

    @Input() set pullLoadCustomer(value: boolean) {
        this._pullLoadCustomer = value;
        //if (this._pullLoadCustomer) {
        if (this.pullCargo)
            this.pullCargo = false;
        else
            this.pullCargo = true;
            //this.onSubmit();
        //}
    }
    get pullLoadCustomer(): boolean {
        return this._pullLoadCustomer;
    }

    @Output() customerNameEmitter = new EventEmitter<string>();
    @Output() openModalEmitter = new EventEmitter<string>();
    @Output() addLoadCustomerEmitter = new EventEmitter<LoadCustomer>();
    @Output() cancelLoadCustomerEmitter = new EventEmitter<boolean>();
    @Output() addCargoLocationEmitter = new EventEmitter<CargoLocation>();
    @Output() addCargoEquipmentEmitter = new EventEmitter<CargoEquipment[]>();
    @Output() emailIdEmitter = new EventEmitter<number>();
    @Output() emailRecipientEmitter = new EventEmitter<string>();

    customerControl = new FormControl();

    customers: Customer[] = [];
    filteredCustomers: Observable<Customer[]>;

    @Input() customer: Customer;

    customerName: string;

    customerContacts: Contact[] = [];
    customerContact: Contact;
    customerCMSOpsContact: Contact;

    errorMessage: string;

    @Input() public loadCustomer: LoadCustomer;// = {
    //    id: 0,
    //    purchaseOrderNumber: null,
    //    customerRate: 0,
    //    commission: 0,
    //    customerInvoiceNumber: null,
    //    orderReference: null,
    //    dateTimeCustomerInvoice: null,
    //    customer: null,
    //    load: null,
    //    customerContact: null,
    //    cmsContact: null,
    //    cargos:null
    //}

    private _filterCustomers(value: string): Customer[] {
        const filterValue = this._normalizeValue(value);
        return this.customers.filter(c => this._normalizeValue(c.tradingName).includes(filterValue));
    }

    private _normalizeValue(value: string): string {
        return value.toLowerCase().replace(/\s/g, '');
    }

    constructor(private customerService: CustomerService, private loadService:LoadService, private cargoService: CargoService) { }

    ngOnInit(): void {
        this.loadCustomers();
        this.loadCargos();

        if (this.loadCustomer.customer.id > 0) {

            this.customer = this.loadCustomer.customer;

            this.customerControl.setValue(this.customer.tradingName);

            if (this.customers.find(c => c == this.customer) == null)
                this.customers.push(this.customer);

            this.getCustomer(this.customer.tradingName);
            this.pushCustomerChanges();

        }

        //if (this.cargos.length == 0)
        //    this.addCargo();
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (!isUndefined(this.customer)) {
            this.pushCustomerChanges();
        }
    }

    pushCustomerChanges() {
        this.customerControl.setValue(this.customer.tradingName);

        if (this.customers.find(c => c == this.customer) == null)
            this.customers.push(this.customer);

        this.getCustomer(this.customer.tradingName);
    }

    loadCustomers() {

        this.customerService.loadCustomers()
            .subscribe(s => {
                if (s) {
                    this.customers = this.customerService.customers;

                    this.filteredCustomers = this.customerControl.valueChanges
                        .pipe(
                            startWith(''),
                            map(value => typeof value === 'string' ? value : value),
                            map(name => name ? this._filterCustomers(name) : [])
                        );

                }
            });

    }

    loadCargos() {

        if (!isUndefined(this.loadCustomer.load)) {
            this.loadService.getCargo(this.loadCustomer.id)
                .subscribe(s => {
                    if (s) {
                        this.cargos = this.loadService.cargos;

                        if (this.cargos.length > 0) {
                            this.loadCustomer.cargos = this.cargos;

                            this.loadCustomer.cargos.forEach(c => {
                                c.key = Math.random()
                            });

                            this.selected.setValue(this.cargos.length - 1);
                        }
                        else {
                            this.addCargo();
                        }
                    } else {
                        this.addCargo();
                    }
                });
        } else 
            this.addCargo();
    }

    onCustomerChange() {
        // when a customer has been selected from the autocomplete list
        this.getCustomer(this.customerControl.value);
    }

    onOpenModal(data) { 
        // add new customer modal
        this.customerName = data;
        //this.openModalEmitter.emit(this.customerName);
        this.customerNameEmitter.emit(this.customerName);
        this.openModalEmitter.emit('CreateCustomer');
    }

    onValidateCustomer(data) {
        // set customer based on selection
        this.getCustomer(this.customerControl.value);
    }

    getCustomer(customerName) {


        this.customerName = customerName;
        this.customer = this.customers.find(c => c.tradingName == this.customerName);

        if (!isUndefined(this.customer)) {

            if (this.customers.find(c => c == this.customer) == null)
                this.customers.push(this.customer);

            this.loadCustomer.customer = this.customer;
            this.loadCustomer.load = this.load;

            this.customerService.loadContacts(this.customer)
                .subscribe(s => {
                    if (s) {
                        this.customerContacts = this.customerService.contacts;
                    }
                });

            //this.addLoadCustomerEmitter.emit(this.loadCustomer);

        }
    }

    onSubmit() {

        console.log("Submit load customer called.");
        //if (this.customer != null && this.customers.find(c => c == this.customer) == null)
        //    this.customers.push(this.customer);

        //this.customer = this.customers.find(c => c.tradingName == this.customerName);

        //this.loadCustomer.customer = this.customer;
        //this.loadCustomer.load = this.load;
        //this.loadCustomer.customerContact = this.customerContact;
        //this.loadCustomer.cmsContact = this.customerCMSOpsContact;

        //var d = new Date('2018-01-01');
        //var o = - d.getTimezoneOffset();
        
        //if (this.loadCustomer.dateTimeCustomerInvoice == null) { this.loadCustomer.dateTimeCustomerInvoice = new Date(1900, 0, 1, 0, o, 0); } else { this.loadCustomer.dateTimeCustomerInvoice = new Date(this.loadCustomer.dateTimeCustomerInvoice.getTime() + o * 60000); }

        //this.loadService.addLoadCustomer(this.loadCustomer)
        //    .subscribe(s => {
        //        if (s) {
        //            this.loadCustomer = this.loadService.loadCustomer;
        //            this.addLoadCustomerEmitter.emit(this.loadCustomer);
        //        }
        //    });

    }

    onCancelCustomer() {
        this.cancelLoadCustomerEmitter.emit(true);
    }

    onCustomerContactSelect(contactId: number) { 
        this.customerContact = this.customerContacts.find(c => c.id == contactId);
        this.loadCustomer.customerContact = this.customerContact;
        //this.addLoadCustomerEmitter.emit(this.loadCustomer);
    }

    onCustomerCMSContactSelect(contactId: number) {
        this.customerCMSOpsContact = this.contacts.find(c => c.id == contactId);
        this.loadCustomer.cmsContact = this.customerCMSOpsContact;
        //this.addLoadCustomerEmitter.emit(this.loadCustomer);
    }

    onDateTimeCustomerInvoiceDateChange() {

        var d = new Date('2018-01-01');
        var o = - d.getTimezoneOffset();

        if (this.loadCustomer.dateTimeCustomerInvoice == null) { this.loadCustomer.dateTimeCustomerInvoice = new Date(1900, 0, 1, 0, o, 0); } else { this.loadCustomer.dateTimeCustomerInvoice = new Date(this.loadCustomer.dateTimeCustomerInvoice.getTime() + o * 60000); }
        //this.addLoadCustomerEmitter.emit(this.loadCustomer);

    }

    openCustomerAdd() {

    }

    onCargoAdded(cargo: Cargo) {
        var cargos: Cargo[] = [];
        cargos.push(cargo);

        this.loadCustomer.cargos = cargos;

        this.onSubmit();
    }

    onCargoLocationAdded(cargoLocation: CargoLocation) {
        this.addCargoLocationEmitter.emit(cargoLocation);
    }

    onCargoEquipmentAdded(cargoEquipments: CargoEquipment[]) {
        this.addCargoEquipmentEmitter.emit(cargoEquipments);
    }

    onCargoCancel(cancel: boolean) {
        //console.log(this.pullCargo);
        this.pullCargo = false;
        //console.log(this.pullCargo);
        //this._pullLoadCustomer = false;
        this.cancelLoadCustomerEmitter.emit(true);
    }

    selected = new FormControl(0);

    cargos: Cargo[] = [];

    addCargo() {

        var cargo = {
            id: 0,
            ordinal: 1,
            description: 'Cargo',
            quantity: 0,
            weight: 0,
            weightUnit: 'ton',
            size: 0,
            sizeUnit: 'm',
            containerType: { id: 0, type: null },
            dateTimeCollectionCustomer: null,
            dateTimeCollectionSupplier: null,
            dateTimeDeliveryCustomer: null,
            dateTimeDeliverySupplier: null,
            dateTimeBooking: null,
            equipments: null,
            key: Math.random()
        }

        this.cargos.push(cargo);
        this.loadCustomer.cargos = this.cargos;
        this.selected.setValue(this.cargos.length - 1);
    } 

    removeCargo(index: number) {

        if (this.cargos[index].id > 0) {

            this.cargoService.deleteCargo(this.cargos[index])
                .subscribe(s => {
                    if (s)
                        console.log("Cargo removed");
                })

        }

        this.cargos.splice(index, 1);
        this.loadCustomer.cargos = this.cargos;
    }

    onPrintSalesOrder() {
        this.loadService.printSalesOrder(this.loadCustomer.id)
            .subscribe(
                (res) => {

                    var file = new File([res], "Sales Order - " + this.loadCustomer.load.id + ".pdf", { type: "application/pdf" });
                    saveAs(file);

                });
    }

    onPrintProofOfDelivery() {
        this.loadService.printProofOfDelivery(this.loadCustomer.id)
            .subscribe(
                (res) => {

                    var file = new File([res], "ProofOfDelivery - " + this.loadCustomer.load.id + ".pdf", { type: "application/pdf" });
                    saveAs(file);

                });
    }

    onEmailSalesOrder() {
        this.emailIdEmitter.emit(this.loadCustomer.id);
        this.emailRecipientEmitter.emit(this.loadCustomer.customerContact.email);
        this.openModalEmitter.emit("EmailSalesOrder");
    }

    onEmailProofOfDelivery() {
        this.emailIdEmitter.emit(this.loadCustomer.id);
        //this.emailRecipientEmitter.emit(this.loadCustomer.customerContact.email);
        this.openModalEmitter.emit("EmailProofOfDelivery");
    }
}