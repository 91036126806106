﻿import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: "search-customers",
    templateUrl: "searchCustomers.component.html",
    styleUrls: ["searchCustomers.component.css"]
})
export class SearchCustomersComponent implements OnInit {


    closeResult: string;

    constructor(private modalService: NgbModal) { }

    ngOnInit(): void {

       

    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

}