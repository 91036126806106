﻿import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { HttpRequest, HttpClient, HttpEventType } from '@angular/common/http';
import { GeneralDocument } from '../shared/generalDocument';
import { DocumentService } from '../shared/documentService';
import { saveAs } from "file-saver";

@Component({
    selector: "document-list",
    templateUrl: "documentList.component.html",
    styleUrls: ["documentList.component.css"]
})
export class DocumentsListComponent implements OnInit {
    
    @Output() showDeleteGeneralDocumentEmitter = new EventEmitter<GeneralDocument>();

    isLoading: boolean;
    public uploadStatus: string;
    public files: UploadFile[] = [];

    public generalDocuments: GeneralDocument[] = [];

    constructor(private documentService: DocumentService, private http: HttpClient) { }

    ngOnInit(): void {
        this.loadGeneralDocuments();
        this.uploadStatus = "Drop documents to upload";
    }

    loadGeneralDocuments() {

        this.documentService.loadGeneralDocuments()
            .subscribe(success => {
                if (success) {

                    this.generalDocuments = this.documentService.generalDocuments;
                }
            });

    }

    public dropped(event: UploadEvent) {
        this.isLoading = true;
        this.files = event.files;

        const fileCount = event.files.length;
        var fileCounter = 0;

        for (const droppedFile of event.files) {

            fileCounter++;

            // Is it a file?
            if (droppedFile.fileEntry.isFile) {

                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {

                    // You could upload it like this:
                    const formData = new FormData()
                    formData.append(droppedFile.relativePath, file)

                    const uploadReq = new HttpRequest('POST', 'api/upload/UploadGeneralDocument/', formData, {
                        reportProgress: true,
                    });

                    this.http.request(uploadReq).subscribe(event => {

                        if (event.type == HttpEventType.UploadProgress) {
                            this.uploadStatus = "Loading: " + (event.loaded / (event.total || event.loaded) * 100).toLocaleString('en-us', { maximumFractionDigits: 0 }) + '%';
                        }

                        if (event.type === HttpEventType.Response) {
                            if (event.body.toString() == 'Document upload successful.') {
                                this.isLoading = false;
                                alert('Document (' + droppedFile.relativePath + ') upload successful.');
                                if (fileCount == fileCounter) {
                                    this.loadGeneralDocuments();
                                }
                                this.uploadStatus = "Drop documents to add";
                            }
                        }

                        if (event.type === HttpEventType.ResponseHeader) {
                            if (event.status == 404) {
                                alert('Document upload failed. File is too large to upload.');
                                this.uploadStatus = "Drop documents to add";
                                this.isLoading = false;
                            } else if (event.status == 200) {
                                this.isLoading = false;
                                alert('Document (' + droppedFile.relativePath + ') upload successful.');
                                if (fileCount == fileCounter) {
                                    this.loadGeneralDocuments();
                                }
                                this.uploadStatus = "Drop documents to add";
                            }
                        }
                    });
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                this.isLoading = false;
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                alert("Error dropping directory!") 
                this.uploadStatus = "Drop documents to add";
            }
        }

    }

    onDeleteGeneralDocument(generalDocument: GeneralDocument) {
        this.showDeleteGeneralDocumentEmitter.emit(generalDocument);
    }

    onDownloadGeneralDocument(generalDocument: GeneralDocument) {

        this.documentService.loadDocument(generalDocument.id).subscribe(
            (res) => {

                var file = new File([res], generalDocument.fileName, { type: "application/pdf" });
                saveAs(file);

            });

    }
}
