﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { VehicleType } from './vehicleType';

@Injectable()
export class VehicleService {

    public vehicleType: VehicleType;
    public vehicleTypes: VehicleType[];
    public selectedVehicleTypes: VehicleType[];

    constructor(private http: HttpClient) { }

    public loadVehicleTypes(): Observable<boolean> {

        return this.http.get("/api/vehicles/GetVehicleTypes")
            .pipe(map((data: any[]) => {
                this.vehicleTypes = data;
                return true;
            }));
    }

    public loadVehicleType(id: number): Observable<boolean> {

        return this.http.get("/api/vehicles/GetVehicleTypes/" + id)
            .pipe(map((data: any) => {
                this.vehicleType = data;
                return true;
            }));
    }

    public loadSelectedVehicleTypes(vehicleId: number): Observable<boolean> {

        return this.http.get("/api/vehicles/" + vehicleId + "/GetVehicleTypes")
            .pipe(map((data: any[]) => {
                this.selectedVehicleTypes = data;
                return true;
            }));
    }

    public addVehicleType(vehicleType: VehicleType): Observable<boolean> {

        return this.http
            .post("/api/vehicles/AddVehicleType", vehicleType)
            .pipe(map((data: any) => {
                this.vehicleType = data;
                return true;
            }));

    }

    public addVehicleTypeToVehicle(vehicleId: number, vehicleTypeId: number): Observable<boolean> {

        return this.http
            .put("/api/vehicles/" + vehicleId + "/AddVehicleType/" + vehicleTypeId, null)
            .pipe(map((data: any) => {
                return true;
            }));

    }

    public updateVehicleType(vehicleType: VehicleType): Observable<boolean> {

        return this.http
            .put("/api/vehicles/UpdateVehicleType", vehicleType)
            .pipe(map((data: any) => {
                this.vehicleType = data;
                return true;
            }));

    }

    public deleteVehicleType(vehicleTypeId: number): Observable<boolean> {

        return this.http
            .delete("/api/vehicles/DeleteVehicleType/" + vehicleTypeId)
            .pipe(map((data: any) => {
                this.vehicleType = data;
                return true;
            }));
    }


}