﻿import { Component, OnInit, ViewChild, ElementRef, NgZone, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Location } from '../shared/location';
import { isUndefined } from 'util';

@Component({
    selector: "location-add",
    templateUrl: "locationAdd.component.html",
    styleUrls: ["locationAdd.component.css", "../app.component.css"]
})
export class LocationAddComponent implements OnInit, OnChanges {

    @Input() originLocation: Location;
    @Input() destinationLocation: Location;

    @Output() originEmitter = new EventEmitter<Location>();
    @Output() destinationEmitter = new EventEmitter<Location>();

    title: string = 'Get Location';
    zoom: number;
    address: string;
    private geoCoder;

    public origin: { lat: number, lng: number };
    public destination: { lat: number, lng: number };

    public renderOptions = {
        suppressMarkers: true,
    }

    public markerOptions = {
        origin: {
            draggable: true,
        },
        destination: {
            draggable: true,
        }
    };

    @ViewChild('searchOrigin')
    public searchOriginElementRef: ElementRef;

    @ViewChild('searchDestination')
    public searchDestinationElementRef: ElementRef;

    constructor(
        private mapsAPILoader: MapsAPILoader,
        private ngZone: NgZone
    ) { }

    ngOnChanges(changes: SimpleChanges): void {

        if (!isUndefined(this.originLocation)) {
            this.origin = { lat: this.originLocation.latitude, lng: this.originLocation.longitude };
        }

        if (!isUndefined(this.originLocation)) {
            this.destination = { lat: this.destinationLocation.latitude, lng: this.destinationLocation.longitude };
        }

    }

    ngOnInit() {

        // origin and destination set to CMS current location
        if (this.originLocation == null) {

            this.origin = { lat: -33.916684, lng: 25.577149 };

            this.originLocation = {
                id: 0,
                companyName: null,
                name: null,
                addressLine1: null,
                addressLine2: null,
                addressLine3: null,
                addressLine4: null,
                addressLine5: null,
                longitude: 0,
                latitude: 0
            };
        }
        else 
            this.origin = { lat: this.originLocation.latitude, lng: this.originLocation.longitude };

        if (this.destinationLocation == null) {

            this.destination = { lat: -33.916684, lng: 25.577149 };

            this.destinationLocation = {
                id: 0,
                companyName: null,
                name: null,
                addressLine1: null,
                addressLine2: null,
                addressLine3: null,
                addressLine4: null,
                addressLine5: null,
                longitude: 0,
                latitude: 0
            };
        }
        else
            this.destination = { lat: this.destinationLocation.latitude, lng: this.destinationLocation.longitude };

        //load Places Autocomplete
        this.mapsAPILoader.load().then(() => {
            //this.setCurrentLocation();
            this.geoCoder = new google.maps.Geocoder;

            let originAutocomplete = new google.maps.places.Autocomplete(this.searchOriginElementRef.nativeElement, {
                types: []
            });
            originAutocomplete.addListener("place_changed", () => {
                this.ngZone.run(() => {
                    //get the place result
                    let place: google.maps.places.PlaceResult = originAutocomplete.getPlace();

                    //verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    //set latitude, longitude and zoom
                    this.origin = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };

                    var address = place.formatted_address.split(',');

                    this.originLocation.name = address[0];
                    this.originLocation.addressLine1 = address[0];
                    this.originLocation.addressLine2 = address[1];
                    this.originLocation.addressLine3 = address[2];
                    this.originLocation.addressLine4 = address[3];
                    this.originLocation.addressLine5 = address[4];
                    this.originLocation.longitude = this.origin.lng;
                    this.originLocation.latitude = this.origin.lat;
                    this.originLocation.id = 0;

                    this.originEmitter.emit(this.originLocation);

                    this.zoom = 12;
                });
            });

            let destinationAutocomplete = new google.maps.places.Autocomplete(this.searchDestinationElementRef.nativeElement, {
                types: []
            });
            destinationAutocomplete.addListener("place_changed", () => {
                this.ngZone.run(() => {
                    //get the place result
                    let place: google.maps.places.PlaceResult = destinationAutocomplete.getPlace();

                    //verify result
                    if (place.geometry === undefined || place.geometry === null) {
                        return;
                    }

                    //set latitude, longitude and zoom
                    this.destination = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };

                    var address = place.formatted_address.split(',');

                    this.destinationLocation.name = address[0];
                    this.destinationLocation.addressLine1 = address[0];
                    this.destinationLocation.addressLine2 = address[1];
                    this.destinationLocation.addressLine3 = address[2];
                    this.destinationLocation.addressLine4 = address[3];
                    this.destinationLocation.addressLine5 = address[4];
                    this.destinationLocation.longitude = this.destination.lng;
                    this.destinationLocation.latitude = this.destination.lat;
                    this.destinationLocation.id = 0;

                    this.destinationEmitter.emit(this.destinationLocation);

                    this.zoom = 12;
                });
            });
        });
    }

}