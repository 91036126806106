﻿import { Component, Output, EventEmitter } from '@angular/core';
import { Customer } from '../shared/customer';
import { CustomerService } from '../shared/customerService';

@Component({
    selector: "customer-search",
    templateUrl: "customerSearch.component.html",
    styleUrls: ["customerSearch.component.css"]
})
export class CustomerSearchComponent {

    @Output() customerEmitter = new EventEmitter<Customer>();

    searchTerm: string;
    customers: Customer[] = [];
    errorMessage: string;

    constructor(private customerService: CustomerService) { }

    onSearch() {
        this.errorMessage = "";

        this.customerService.searchCustomers(this.searchTerm)
            .subscribe(s => {
                if (s) {
                    this.customers = this.customerService.customers;

                    if (this.customers.length == 0)
                        this.errorMessage = "No customers found";
                }
                else {
                    this.errorMessage = "No customers found";
                }
            });
    }

    onSelectCustomer(customer: Customer) {
        this.customerEmitter.emit(customer);
    }

}