﻿import { Component } from '@angular/core';
import { Equipment } from '../shared/equipment';

@Component({
    selector: "equipments",
    templateUrl: "equipments.component.html",
    styleUrls: ["equipments.component.css"]
})
export class EquipmentsComponent {

    public showEquipmentList: boolean;
    public showAddEquipment: boolean;
    public showEquipment: boolean;
    public showDeleteEquipment: boolean;
    public showEditEquipment: boolean;

    equipment: Equipment;

    ngOnInit(): void {
        this.clearEquipmentView();
        this.showEquipmentList = true;
    }

    clearEquipmentView() {
        this.showEquipmentList = false;
        this.showEquipment = false;
        this.showDeleteEquipment = false;
        this.showEditEquipment = false;
        this.showAddEquipment = false;
    }

    onShowNewEquipment() {
        this.clearEquipmentView();
        this.showAddEquipment = true;
    }

    onShowEquipmentList() {
        this.clearEquipmentView();
        this.showEquipmentList = true;
    }

    onEquipmentSaved(isSaved: boolean) {
        this.clearEquipmentView();
        this.showEquipmentList = true;
    }

    onEditEquipment(equipment: Equipment) {
        this.equipment = equipment;
        this.clearEquipmentView();
        this.showEditEquipment = true;
    }

    onDeleteEquipment(equipment: Equipment) {
        this.equipment = equipment;
        this.clearEquipmentView();
        this.showDeleteEquipment = true;
    }
}