﻿import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { SupplierService } from '../../shared/supplierService';
import { Supplier } from '../../shared/supplier';
import { SupplierContact } from '../../shared/supplierContact';

@Component({
    selector: "supplier-contact-list",
    templateUrl: "supplierContactList.component.html",
    styleUrls: ["supplierContactList.component.css"]
})
export class SupplierContactListComponent implements OnInit {

    @Input() supplier: Supplier;

    public supplierContacts: SupplierContact[] = [];

    @Output() showEditSupplierContactEmitter = new EventEmitter<SupplierContact>();
    @Output() showDeleteSupplierContactEmitter = new EventEmitter<SupplierContact>();

    constructor(private supplierService: SupplierService) { }


    ngOnInit(): void {
        this.loadSupplierContacts();
    }

    loadSupplierContacts() {

        this.supplierService.loadSupplierContacts(this.supplier)
            .subscribe(success => {
                if (success) {

                    this.supplierContacts = this.supplierService.supplierContacts;
                }
            });

    }

    onEditSupplierContact(supplierContact: SupplierContact) {
        this.showEditSupplierContactEmitter.emit(supplierContact);
    }

    onDeleteSupplierContact(supplierContact: SupplierContact) {
        this.showDeleteSupplierContactEmitter.emit(supplierContact);
    }

}