﻿import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { CustomerService } from '../../shared/customerService';
import { Customer } from '../../shared/customer';
import { Contact } from '../../shared/contact';
import { CompanyDirector } from '../../shared/companyDirector';
import { ContactType } from '../../shared/contactType';
import { ContactService } from '../../shared/contactService';

@Component({
    selector: "company-director-add",
    templateUrl: "companyDirectorAdd.component.html",
    styleUrls: ["companyDirectorAdd.component.css"]
})
export class CompanyDirectorAddComponent {

    @Input() customer: Customer;
    public errorMessage: string;

    dropdownSettings = {};

    public companyDirector = {
        id: 0,
        firstName: null,
        surname: null,
        residentialAddress: null,
        capacity: null
    }

    @Output() addCompanyDirectorEmitter = new EventEmitter<CompanyDirector>();

    constructor(private customerService: CustomerService) { }

    onSubmit() {
        this.customerService.addCompanyDirector(this.customer.id, this.companyDirector)
            .subscribe(_ => {

                this.addCompanyDirectorEmitter.emit(this.customerService.companyDirector);

            }, err => {
                this.errorMessage = 'Failed to add company director.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error;
                }
            });
    }
}