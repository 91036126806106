﻿import { Component } from '@angular/core';
import { AuthService } from '../auth/authService';
import { Router, ActivatedRoute } from '@angular/router';
import { StorageService } from '../shared/storageService';
import { UserService } from '../shared/userService';

@Component({
    selector: "reset-password",
    templateUrl: "resetPassword.component.html",
    styleUrls: ["resetPassword.component.css"]
})
export class ResetPasswordComponent {
    errorMessage: string = "";

    passwordConfirmationFailed = true;
    public passwordConfirmation = {
        newPassword: "",
        newPasswordConfirmation: ""
    };

    constructor(private auth: AuthService, private router: Router, private route: ActivatedRoute, private storage: StorageService, private userService: UserService) { }

    checkPasswordReset() {
        if (this.passwordConfirmation.newPassword === this.passwordConfirmation.newPasswordConfirmation) {
            this.passwordConfirmationFailed = false;
        } else {

            this.passwordConfirmationFailed = true;
        }
    }

    onPasswordChange() {
        //var userToken = this.route.snapshot.queryParamMap.get('token');
        // call login service
        if (this.passwordConfirmation.newPassword === this.passwordConfirmation.newPasswordConfirmation) {

            this.storage.store("token", this.route.snapshot.queryParamMap.get('token'));

            this.userService.resetPassword(this.passwordConfirmation.newPassword)
                .subscribe(success => {
                    if (success) {
                        alert("User password updated successfully.");
                        this.storage.clear("group");
                        this.storage.clear("token");
                        this.storage.clear("tokenExpiration");
                        this.router.navigate(['Login']);
                    }
                }, err => {
                    if (err.error.length <= 500) {
                        this.errorMessage = "Error: " + err.error;
                    }
                    else {
                        this.errorMessage = "Failed to reset password. Please try again.";
                    }
                });
        } else {
            this.errorMessage = "Please make sure password and password confirmation match.";
        }
    }
}