﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Contact } from '../shared/contact';
import { ContactService } from '../shared/contactService';

@Component({
    selector: "opsContact-delete",
    templateUrl: "opsContactDelete.component.html",
    styleUrls: ["opsContactDelete.component.css"]
})
export class OpsContactDeleteComponent {

    public errorMessage: string;

    @Input() opsContact: Contact;

    @Output() saveOpsContactEmitter = new EventEmitter<Contact>();

    constructor(private opsContactService: ContactService) { }

    onDelete() {
        this.opsContactService.deleteOpsContact(this.opsContact.id)
            .subscribe(_ => {
                this.saveOpsContactEmitter.emit(this.opsContactService.contact);
            }, err => {
                this.errorMessage = 'Failed to delete opsContact.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error;
                }
            });
    }
}