﻿import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Contact } from '../shared/contact';
import { Subject } from 'rxjs';
import { ContactService } from '../shared/contactService';

@Component({
    selector: "opsContact-list",
    templateUrl: "opsContactList.component.html",
    styleUrls: ["opsContactList.component.css"]
})
export class OpsContactListComponent implements OnInit, OnDestroy {

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

    public opsContacts: Contact[] = [];

    @Output() showEditOpsContactEmitter = new EventEmitter<Contact>();
    @Output() showDeleteOpsContactEmitter = new EventEmitter<Contact>();

    constructor(private opsContactService: ContactService) { }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngOnInit(): void {
        this.loadOpsContact();
    }

    loadOpsContact() {

        this.opsContactService.loadOpsContacts()
            .subscribe(success => {
                if (success) {

                    this.dtOptions = {
                        pagingType: 'full_numbers',
                        pageLength: 10
                    };

                    this.opsContacts = this.opsContactService.contacts;
                    this.dtTrigger.next();
                }
            });

    }

    onEditOpsContact(opsContact: Contact) {
        this.showEditOpsContactEmitter.emit(opsContact);
    }

    onDeleteOpsContact(opsContact: Contact) {
        this.showDeleteOpsContactEmitter.emit(opsContact);
    }
}