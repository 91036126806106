﻿import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Email } from './email';

@Injectable()
export class EmailService {

    constructor(private http: HttpClient) { }

    emailConfirmation: string;

    public sendEmail(email: Email, reportId: number): any {

        //var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

        return this.http.post("/api/print/sendEmail/" + reportId, email)
            .pipe(map((data: any) => {
                this.emailConfirmation = data.result;
                return true;
            }));

    }

}