﻿import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { StorageService } from '../shared/storageService';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { User } from '../shared/user';

@Injectable()
export class AuthService {

    constructor(
        private http: HttpClient,
        private router: Router,
        private storage: StorageService
    ) { }

    private loggedIn = new BehaviorSubject<boolean>(false);
    private loggedOut = new BehaviorSubject<boolean>(false);
    public isAdmin: boolean;
    public isManager: boolean;
    public isCustomerSupplier: boolean;

    get isLoggedIn() {

        const token = localStorage.getItem('token');

        if (token == null) {
            this.loggedIn.next(false);
        }
        else {
            if (moment().isBefore(this.getExpiration())) {
                this.loggedIn.next(true);
            }
        }

        return this.loggedIn.asObservable();
    }

    get isLoggedOut() {

        const token = localStorage.getItem('token');

        if (token == null) {
            this.loggedOut.next(true);
        }
        else {
            if (moment().isBefore(this.getExpiration())) {
                this.loggedOut.next(false);
            }
        }

        return this.loggedOut.asObservable();

    }

    get isManagerLoggedIn() {

        return this.http
            .get("/api/users/isManager")
            .pipe(map((data: any) => {
                this.isManager = data;
                return this.isManager;
            }));

    }

    public isUserManager() {

        return this.http
            .get("/api/users/IsManager")
            .pipe(map((data: any) => {
                this.isManager = data;
                return this.isManager;
            }));

    }

    public isAdminLoggedIn() {

        return this.http
            .get("/api/users/isAdmin")
            .pipe(map((data: any) => {
                this.isAdmin = data;
                return this.isAdmin;
            }));

    }

    public isCustomerSupplierLoggedIn() {

        return this.http
            .get("/api/users/isCustomerSupplier")
            .pipe(map((data: any) => {
                this.isCustomerSupplier = data;
                return this.isCustomerSupplier;
            }));

    }

    customerLogin(customerId: string): Observable<boolean> {
        return this.http
            .get("/api/Login/" + customerId)
            .pipe(map((data: any) => {
                if (!(data.token.length == 0 || data.expiration > new Date())) {
                    this.storage.store('token', data.token);
                    this.storage.store('tokenExpiration', JSON.stringify(data.expiration.valueOf()));

                    return true;
                }
            }));
    }

    login(user: User): Observable<boolean> {
        return this.http
            .post("/Account/CreateToken", user)
            .pipe(map((data: any) => {
                if (!(data.token.length == 0 || data.expiration > new Date())) {
                    this.storage.store('token', data.token);
                    this.storage.store('tokenExpiration', JSON.stringify(data.expiration.valueOf()));

                    return true;
                }
            }));
    }

    logout() {
        this.storage.clear('token');
        this.storage.clear('tokenExpiration');
        this.loggedIn.next(false);
        this.router.navigate(["Login"]);
    }

    getExpiration() {
        const expiration = localStorage.getItem("tokenExpiration");
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }  

}