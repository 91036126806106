﻿import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { Equipment } from '../shared/equipment';
import { Subject } from 'rxjs';
import { EquipmentService } from '../shared/equipmentService';

@Component({
    selector: "equipment-list",
    templateUrl: "equipmentList.component.html",
    styleUrls: ["equipmentList.component.css"]
})
export class EquipmentListComponent implements OnInit, OnDestroy {

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

    public equipments: Equipment[] = [];

    @Output() showEditEquipmentEmitter = new EventEmitter<Equipment>();
    @Output() showDeleteEquipmentEmitter = new EventEmitter<Equipment>();

    constructor(private equipmentService: EquipmentService) { }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngOnInit(): void {
        this.loadEquipment();
    }

    loadEquipment() {

        this.equipmentService.loadEquipment()
            .subscribe(success => {
                if (success) {

                    this.dtOptions = {
                        pagingType: 'full_numbers',
                        pageLength: 10
                    };

                    this.equipments = this.equipmentService.equipments;
                    this.dtTrigger.next();
                }
            });

    }

    onEditEquipment(equipment: Equipment) {
        this.showEditEquipmentEmitter.emit(equipment);
    }

    onDeleteEquipment(equipment: Equipment) {
        this.showDeleteEquipmentEmitter.emit(equipment);
    }
}