﻿import { Component, Input } from '@angular/core';
import { GeneralDocument } from '../shared/generalDocument';

@Component({
    selector: "documents",
    templateUrl: "documents.component.html",
    styleUrls: ["documents.component.css"]
})
export class DocumentsComponent {

    public showGeneralDocumentList: boolean;
    public showDeleteGeneralDocument: boolean;

    public generalDocument: GeneralDocument;

    ngOnInit(): void {
        this.clearGeneralDocumentView();
        this.showGeneralDocumentList = true;
    }

    clearGeneralDocumentView() {
        this.showGeneralDocumentList = false;
        this.showDeleteGeneralDocument = false;
    }

    onShowGeneralDocumentList() {
        this.clearGeneralDocumentView();
        this.showGeneralDocumentList = true;
    }

    onGeneralDocumentDelete(generalDocument: GeneralDocument) {
        this.generalDocument = generalDocument;
        this.clearGeneralDocumentView();
        this.showDeleteGeneralDocument = true;
    }

    onRefreshGeneralDocumentList(hideGeneralDocument: boolean) {
        this.clearGeneralDocumentView();
        this.showGeneralDocumentList = hideGeneralDocument;
    }

}
