﻿import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Customer } from '../shared/customer';
import { CustomerService } from '../shared/customerService';
import { CompanyType } from '../shared/companyType';
import { TradeReference } from '../shared/tradeReference';
import { saveAs } from "file-saver";
import { HttpClient, HttpRequest, HttpEventType } from '@angular/common/http';
import { isUndefined } from 'util';

@Component({
    selector: "customer-edit",
    templateUrl: "customerEdit.component.html",
    styleUrls: ["customerEdit.component.css"]
})
export class CustomerEditComponent implements OnInit {

    isLoading: boolean;
    public uploadStatus: string;
    public message: string;

    @Input() customer: Customer;
    companyTypes: CompanyType[] = [];
    companyTypeId: number;
    tradeReferences: TradeReference[] = [];

    dateTimeCommencedBusiness: string;
    dateTimeSubmissionClosing: string;

    errorMessage: string;

    @Output() editCustomerEmitter = new EventEmitter<boolean>();

    constructor(private customerService: CustomerService, private http: HttpClient) { }

    ngOnInit(): void {
        this.loadDates();
        this.loadCompanyTypes();
        this.loadTradeReferences();
        this.getCustomerAccountNo();
    }

    loadDates() {
        if (this.customer.dateTimeCommencedBusiness != null) this.dateTimeCommencedBusiness = this.customer.dateTimeCommencedBusiness.toString().substring(0, 10);
        if (this.customer.dateTimeSubmissionClosing != null) this.dateTimeSubmissionClosing = this.customer.dateTimeSubmissionClosing.toString().substring(0, 10);
        
    }

    loadCompanyType() {
        this.customerService.loadCompanyType(this.customer)
            .subscribe(c => {
                if (c) {
                    if (!isUndefined(this.customerService.companyType) && this.customerService.companyType != null) {
                        this.companyTypeId = this.customerService.companyType.id;
                    }
                }
            });
    }

    getCustomerAccountNo() {

        if (this.customer.customerAccountNo == null) {
            this.customer.customerAccountNo = this.customer.tradingName.substring(0, 3);
            if (this.customer.id < 10) {
                this.customer.customerAccountNo += "00" + this.customer.id;
            }
            else if (this.customer.id < 100) {
                this.customer.customerAccountNo += "0" + this.customer.id;
            }
            else {
                this.customer.customerAccountNo += this.customer.id;
            }
        }

    }

    loadCompanyTypes() {
        this.customerService.loadCompanyTypes()
            .subscribe(c => {
                if (c) {
                    this.companyTypes = this.customerService.companyTypes;
                    this.loadCompanyType();
                }
            });
    }

    loadTradeReferences() {
        this.customerService.loadTradeReferences(this.customer.id)
            .subscribe(s => {
                if (s) {
                    this.tradeReferences = this.customerService.tradeReferences;
                }
            });
    }

    onCompanyTypeSelect(id) {
        this.customer.companyType = this.companyTypes.find(t => t.id == id);
    }

    onSubmit() {

        this.customerService.editCustomer(this.customer, this.dateTimeSubmissionClosing, this.dateTimeCommencedBusiness)
            .subscribe(s => {
                this.customer = this.customerService.customer;
                this.editCustomerEmitter.emit(true);
            });
    }

    onPrintCreditApplication() {
        this.customerService.printCreditApplication(this.customer)
            .subscribe(
                (res) => {

                    var file = new File([res], "Credit Application - " + this.customer.tradingName + ".pdf", { type: "application/pdf" });
                    saveAs(file);

                });
    }

    onPrintAccountConfirmation() {
        this.customerService.printAccountConfirmation(this.customer)
            .subscribe(
                (res) => {

                    var file = new File([res], "Account Confirmation - " + this.customer.tradingName + ".pdf", { type: "application/pdf" });
                    saveAs(file);

                });
    }

    onPrintBankDetailsLetter() {
        this.customerService.printBankDetailsLetter()
            .subscribe(
                (res) => {

                    var file = new File([res], "BankDetailsLetter.pdf", { type: "application/pdf" });
                    saveAs(file);

                },
                err => {
                    alert("Unable to find BankDetailsLetter.pdf. Please ensure that one is uploaded.");
                });
    }
}