﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { VehicleType } from '../shared/vehicleType';
import { VehicleService } from '../shared/vehicleService';

@Component({
    selector: "vehicleType-delete",
    templateUrl: "vehicleTypeDelete.component.html",
    styleUrls: ["vehicleTypeDelete.component.css"]
})
export class VehicleTypeDeleteComponent {
    public errorMessage: string;
    @Input() vehicleType: VehicleType;

    @Output() deleteVehicleTypeEmitter = new EventEmitter<VehicleType>();

    constructor(private vehicleService: VehicleService) { }

    onDelete() {
        this.vehicleService.deleteVehicleType(this.vehicleType.id)
            .subscribe(_ => {
                this.deleteVehicleTypeEmitter.emit(this.vehicleService.vehicleType);
            }, err => {
                this.errorMessage = 'Failed to delete vehicle type.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error;
                }
            });
    }
}