﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GeneralDocument } from '../shared/generalDocument';
import { DocumentService } from '../shared/documentService';

@Component({
    selector: "document-delete",
    templateUrl: "documentDelete.component.html",
    styleUrls: ["documentDelete.component.css"]
})
export class DocumentsDeleteComponent {

    @Input() generalDocument: GeneralDocument;
    @Output() deleteGeneralDocumentEmitter = new EventEmitter<GeneralDocument>();
    @Output() cancelEmitter = new EventEmitter<boolean>();

    constructor(private documentService: DocumentService) { }

    onDelete() {
        this.documentService.deleteGeneralDocument(this.generalDocument.id)
            .subscribe(_ => {
                this.deleteGeneralDocumentEmitter.emit(this.documentService.generalDocument);
            });
    }

    onCancelDelete() {
        this.cancelEmitter.emit(true);
    }
}
