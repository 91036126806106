import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { FileDropModule } from 'ngx-file-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AgmCoreModule } from '@agm/core';
import { AgmDirectionModule } from 'agm-direction';
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';

import { AuthGuard } from './auth/authGuard';
import { AuthInterceptor } from './auth/authInterceptor';
import { AuthService } from './auth/authService';

import { HomeComponent } from './home/home.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';

import { CargosComponent } from './cargo/cargos.component';
import { CargoAddComponent } from './cargo/cargoAdd.component';
import { CargoEditComponent } from './cargo/cargoEdit.component';
import { CargoDeleteComponent } from './cargo/cargoDelete.component';
import { CargoListComponent } from './cargo/cargoList.component';

import { ContactsComponent } from './contacts/contacts.component';
import { ContactAddComponent } from './contacts/contactAdd.component';
import { ContactEditComponent } from './contacts/contactEdit.component';
import { ContactDeleteComponent } from './contacts/contactDelete.component';
import { ContactListComponent } from './contacts/contactList.component';

import { CompanyDetailComponent } from './companyDetail/companyDetail.component';

import { CustomersComponent } from './customers/customers.component';
import { CustomerAddComponent } from './customers/customerAdd.component';
import { CustomerEditComponent } from './customers/customerEdit.component';
import { CustomerListComponent } from './customers/customerList.component';
import { CustomerSearchComponent } from './customers/customerSearch.component';

import { CustomerContactsComponent } from './customers/customerContacts/customerContacts.component';
import { CustomerContactAddComponent } from './customers/customerContacts/customerContactAdd.component';
import { CustomerContactDeleteComponent } from './customers/customerContacts/customerContactDelete.component';
import { CustomerContactEditComponent } from './customers/customerContacts/customerContactEdit.component';
import { CustomerContactListComponent } from './customers/customerContacts/customerContactList.component';

import { CompanyDirectorsComponent } from './customers/companyDirectors/companyDirectors.component';
import { CompanyDirectorAddComponent } from './customers/companyDirectors/companyDirectorAdd.component';
import { CompanyDirectorListComponent } from './customers/companyDirectors/companyDirectorList.component';
import { CompanyDirectorEditComponent } from './customers/companyDirectors/companyDirectorEdit.component';
import { CompanyDirectorDeleteComponent } from './customers/companyDirectors/companyDirectorDelete.component';

import { CustomerDocumentsComponent } from './customers/customerDocuments/customerDocuments.component';
import { CustomerDocumentsListComponent } from './customers/customerDocuments/customerDocumentList.component';
import { CustomerDocumentsDeleteComponent } from './customers/customerDocuments/customerDocumentDelete.component';

import { DocumentsComponent } from './documents/documents.component';
import { DocumentsDeleteComponent } from './documents/documentDelete.component';
import { DocumentsListComponent } from './documents/documentList.component';

import { EmailDocumentComponent } from './emailDocument/emailDocument.component';

import { EquipmentsComponent } from './equipment/equipments.component';
import { EquipmentAddComponent } from './equipment/equipmentAdd.component';
import { EquipmentDeleteComponent } from './equipment/equipmentDelete.component';
import { EquipmentEditComponent } from './equipment/equipmentEdit.component';
import { EquipmentListComponent } from './equipment/equipmentList.component';
import { EquipmentSearchComponent } from './equipment/equipmentSearch.component';

import { LoadDocumentsComponent } from './loads/loadDocuments/loadDocuments.component';
import { LoadDocumentsListComponent } from './loads/loadDocuments/loadDocumentList.component';
import { LoadDocumentsDeleteComponent } from './loads/loadDocuments/loadDocumentDelete.component';

import { OpsContactsComponent } from './opsContacts/opsContacts.component';
import { OpsContactAddComponent } from './opsContacts/opsContactAdd.component';
import { OpsContactEditComponent } from './opsContacts/opsContactEdit.component';
import { OpsContactDeleteComponent } from './opsContacts/opsContactDelete.component';
import { OpsContactListComponent } from './opsContacts/opsContactList.component';

import { OrderNumberSeedComponent } from './orderNumberSeed/orderNumberSeed.component';

import { SuppliersComponent } from './suppliers/suppliers.component';
import { SupplierAddComponent } from './suppliers/supplierAdd.component';
import { SupplierEditComponent } from './suppliers/supplierEdit.component';
import { SupplierListComponent } from './suppliers/supplierList.component';
import { SupplierSearchComponent } from './suppliers/supplierSearch.component';

import { SupplierContactsComponent } from './suppliers/supplierContacts/supplierContacts.component';
import { SupplierContactAddComponent } from './suppliers/supplierContacts/supplierContactAdd.component';
import { SupplierContactDeleteComponent } from './suppliers/supplierContacts/supplierContactDelete.component';
import { SupplierContactEditComponent } from './suppliers/supplierContacts/supplierContactEdit.component';
import { SupplierContactListComponent } from './suppliers/supplierContacts/supplierContactList.component';

import { SupplierDocumentsComponent } from './suppliers/supplierDocuments/supplierDocuments.component';
import { SupplierDocumentsListComponent } from './suppliers/supplierDocuments/supplierDocumentList.component';
import { SupplierDocumentsDeleteComponent } from './suppliers/supplierDocuments/supplierDocumentDelete.component';


import { SearchCustomersComponent } from './searchCustomers/searchCustomers.component';

import { UserDisableComponent } from './users/userDisable.component';
import { UserEnableComponent } from './users/userEnable.component';
import { UserListComponent } from './users/userList.component';
import { UsersComponent } from './users/users.component';

import { CustomerLoginComponent } from './customerLogin/customerLogin.component';

import { LoadsComponent } from './loads/loads.component';
import { LoadListComponent } from './loads/loadList.component';
import { LoadAddComponent } from './loads/loadAdd.component';
import { LoadEditComponent } from './loads/loadEdit.component';
import { LoadDetailsComponent } from './loads/loadDetails.component';

import { LoadCustomerDeleteComponent } from './loads/loadCustomers/loadCustomerDelete.component';
import { LoadCustomerListComponent } from './loads/loadCustomers/loadCustomerList.component';
import { LoadCustomersComponent } from './loads/loadCustomers/loadCustomers.component';
import { LoadCustomerComponent } from './loads/loadCustomers/loadCustomer.component';
import { LoadCustomerEditComponent } from './loads/loadCustomers/loadCustomerEdit.component';

import { LocationAddComponent } from './locations/locationAdd.component';

import { TradeReferencesComponent } from './customers/tradeReferences/tradeReferences.component';
import { TradeReferenceAddComponent } from './customers/tradeReferences/tradeReferenceAdd.component';
import { TradeReferenceDeleteComponent } from './customers/tradeReferences/tradeReferenceDelete.component';
import { TradeReferenceEditComponent } from './customers/tradeReferences/tradeReferenceEdit.component';
import { TradeReferenceListComponent } from './customers/tradeReferences/tradeReferenceList.component';
import { TradeReferenceQuestionnaireComponent } from './customers/tradeReferences/tradeReferenceQuestionnaire/tradeReferenceQuestionnaire.component';

import { VehicleTypesComponent } from './vehicleTypes/vehicleTypes.component';
import { VehicleTypeAddComponent } from './vehicleTypes/vehicleTypeAdd.component';
import { VehicleTypeDeleteComponent } from './vehicleTypes/vehicleTypeDelete.component';
import { VehicleTypeEditComponent } from './vehicleTypes/vehicleTypeEdit.component';
import { VehicleTypeListComponent } from './vehicleTypes/vehicleTypeList.component';

import { RegisterComponent } from './register/register.component';
import { ResetPasswordComponent } from './resetPassword/resetPassword.component';
import { ForgotPasswordComponent } from './forgotPassword/forgotPassword.component';

import { LoginComponent } from './login/login.component';

import { CustomerService } from './shared/customerService';
import { ContactService } from './shared/contactService';
import { StorageService } from './shared/storageService';
import { UserService } from './shared/userService';
import { LoadService } from './shared/loadService';
import { SupplierService } from './shared/supplierService';
import { EquipmentService } from './shared/equipmentService';
import { LocationService } from './shared/locationService';
import { CargoService } from './shared/cargoService';
import { VehicleService } from './shared/vehicleService';
import { DocumentService } from './shared/documentService';
import { CompanyDetailService } from './shared/companyDetailService';
import { OrderNumberSeedService } from './shared/orderNumberSeedService';
import { EmailService } from './shared/emailService';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataTablesModule } from 'angular-datatables';

let routes = [
    { path: "", component: HomeComponent, canActivate: [AuthGuard] },
    { path: "CustomerLogin/:id", component: CustomerLoginComponent },
    { path: "Login", component: LoginComponent },
    { path: "Register", component: RegisterComponent },
    { path: "ForgotPassword", component: ForgotPasswordComponent },
    { path: "ResetPassword", component: ResetPasswordComponent }
];

@NgModule({
  declarations: [
      AppComponent,
      HomeComponent,
      SidebarComponent,
      NavbarComponent,
      LoginComponent,
      ContactsComponent,
      ContactAddComponent,
      ContactDeleteComponent,
      ContactEditComponent,
      ContactListComponent,
      CustomersComponent,
      CustomerAddComponent,
      CustomerEditComponent,
      CustomerListComponent,
      RegisterComponent,
      ResetPasswordComponent,
      ForgotPasswordComponent,
      CustomerLoginComponent,
      LoadsComponent,
      LoadListComponent,
      LoadAddComponent,
      LoadDetailsComponent,
      LoadCustomersComponent,
      LoadCustomerListComponent,
    LoadCustomerDeleteComponent,
        LoadCustomerEditComponent,
      LoadCustomerComponent,
      SearchCustomersComponent,
      CustomerSearchComponent,
      SupplierAddComponent,
    SupplierSearchComponent,
    SuppliersComponent,
    SupplierListComponent,
      SupplierEditComponent,
      CargosComponent,
      CargoAddComponent,
      CargoEditComponent,
      CargoDeleteComponent,
      CargoListComponent,
      LoadEditComponent,
      EquipmentAddComponent,
      EquipmentDeleteComponent,
      EquipmentEditComponent,
      EquipmentListComponent,
      EquipmentsComponent,
      EquipmentSearchComponent,
      LocationAddComponent,
      VehicleTypeAddComponent,
      VehicleTypeDeleteComponent,
      VehicleTypeEditComponent,
      VehicleTypeListComponent,
      VehicleTypesComponent,
      OpsContactAddComponent,
      OpsContactDeleteComponent,
      OpsContactEditComponent,
      OpsContactListComponent,
      OpsContactsComponent,
      UserListComponent,
      UsersComponent,
      UserDisableComponent,
        UserEnableComponent,
        TradeReferenceAddComponent,
        TradeReferenceDeleteComponent,
        TradeReferenceEditComponent,
        TradeReferenceListComponent,
        TradeReferencesComponent,
        TradeReferenceQuestionnaireComponent,
        CustomerDocumentsComponent,
        CustomerDocumentsDeleteComponent,
        CustomerDocumentsListComponent,
        CustomerContactsComponent,
        CustomerContactAddComponent,
        CustomerContactEditComponent,
        CustomerContactListComponent,
        CustomerContactDeleteComponent,
        CompanyDirectorsComponent,
        CompanyDirectorAddComponent,
        CompanyDirectorEditComponent,
        CompanyDirectorListComponent,
        CompanyDirectorDeleteComponent,
        DocumentsComponent,
        DocumentsDeleteComponent,
        DocumentsListComponent,
        SupplierContactsComponent,
        SupplierContactAddComponent,
        SupplierContactDeleteComponent,
        SupplierContactEditComponent,
        SupplierContactListComponent,
        SupplierDocumentsComponent,
        SupplierDocumentsListComponent,
        SupplierDocumentsDeleteComponent,
        CompanyDetailComponent,
        LoadDocumentsComponent,
        LoadDocumentsListComponent,
        LoadDocumentsDeleteComponent,
        OrderNumberSeedComponent,
        EmailDocumentComponent
  ],
  imports: [
      FormsModule,
      ReactiveFormsModule,
      NgbModule,
      HttpClientModule,
      BrowserModule,
      BrowserAnimationsModule,
      DataTablesModule,
      FileDropModule,
      OwlDateTimeModule,
      OwlNativeDateTimeModule,
      MatFormFieldModule,
      MatRadioModule,
      MatCheckboxModule,
      MatAutocompleteModule,
      MatTabsModule,
      AgmCoreModule.forRoot({
          apiKey: 'AIzaSyDM4CQwj4TgqftLY3jeI2sljLYHvXJOwVU',
          libraries: ['places']
      }),
      AgmDirectionModule,
      NgMultiSelectDropDownModule.forRoot(),
      RouterModule.forRoot(routes, {
            useHash: true,
            enableTracing: false
        })
  ],
    providers: [
        AuthService,
        AuthGuard,
        StorageService,
        ContactService,
        CustomerService,
        UserService,
        LoadService,
        SupplierService,
        EquipmentService,
        LocationService,
        CargoService,
        VehicleService,
        DocumentService,
        CompanyDetailService,
        OrderNumberSeedService,
        EmailService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: OWL_DATE_TIME_LOCALE, useValue: 'sa' }
    ],
  bootstrap: [AppComponent]
})
export class AppModule { }
