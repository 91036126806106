﻿import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Cargo } from '../shared/cargo';
import { CargoLocation } from '../shared/cargoLocation';
import { LoadCustomer } from '../shared/loadCustomer';
import { LoadService } from '../shared/loadService';
import { Location } from '../shared/location';

@Component({
    selector: "cargo-list",
    templateUrl: "cargoList.component.html",
    styleUrls: ["cargoList.component.css"]
})
export class CargoListComponent implements OnInit {

    @Input() isEditable: boolean;
    @Input() loadCustomer: LoadCustomer;
    @Input() cargos: Cargo[];
    @Input() cargoLocations: CargoLocation[];

    @Output() emitCargo = new EventEmitter<Cargo>();
    @Output() emitOrigin = new EventEmitter<Location>();
    @Output() emitDestination = new EventEmitter<Location>();
    @Output() emitCargoDelete = new EventEmitter<Cargo>();
    
    constructor(private loadService: LoadService) { }

    ngOnInit(): void {
        if (this.cargoLocations == null || this.cargoLocations.length == 0) {
            this.loadCargoLocations();
        }
        if (this.cargos == null || this.cargos.length == 0) {
            this.loadCargo();
        }
    }

    loadCargo() {

        if (this.loadCustomer.load.id != 0) {
            this.loadService.getCargo(this.loadCustomer.id)
                .subscribe(s => {
                    if (s) {
                        this.cargos = this.loadService.cargos;
                    }
                });
        }
    }

    loadCargoLocations() {

        if (this.loadCustomer.load.id != 0) {
            this.loadService.getCargoLocations(this.loadCustomer.load.id)
                .subscribe(s => {
                    if (s) {
                        this.cargoLocations = this.loadService.cargoLocations;
                    }
                });
        }

    }

    getOriginName(c: Cargo) {
        if (this.cargoLocations != null) {
            var originLocation = this.cargoLocations.find(l => l.cargo.id == c.id && l.type == "Origin");

            if (originLocation != null) {
                return originLocation.location.name;
            }
        }
    }

    getDestinationName(c: Cargo) {
        if (this.cargoLocations != null) {
            var destinationLocation = this.cargoLocations.find(l => l.cargo.id == c.id && l.type == "Destination");

            if (destinationLocation != null) {
                return destinationLocation.location.name;
            }
        }
    }

    onEditCargo(c: Cargo) {

        var originLocation = this.cargoLocations.find(l => l.cargo.id == c.id && l.type == "Origin");
        if (originLocation != null) {
            var origin = originLocation.location;
            this.emitOrigin.emit(origin);
        }

        var destinationLocation = this.cargoLocations.find(l => l.cargo.id == c.id && l.type == "Destination");

        if (destinationLocation != null) {
            var destination = destinationLocation.location;
            this.emitDestination.emit(destination);
        }

        this.emitCargo.emit(c);
    }

    onDeleteCargo(c: Cargo) {
        this.emitCargoDelete.emit(c);
    }

}