﻿import { Injectable } from '@angular/core';
import { Cargo } from './cargo';
import { Location } from './location';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { CargoLocation } from './cargoLocation';

@Injectable()
export class LocationService {

    origin: Location;
    destination: Location;
    cargoOrigin: CargoLocation;
    cargoDestination: CargoLocation;

    constructor(private http: HttpClient) { }

    public loadOrigin(cargo: Cargo): Observable<boolean> {
        return this.http.get("/api/locations/Origin/" + cargo.id)
            .pipe(map((data: any) => {
                this.origin = data;
                return this.origin == data;
            }));
    }

    public loadDestination(cargo: Cargo): Observable<boolean> {
        return this.http.get("/api/locations/Destination/" + cargo.id)
            .pipe(map((data: any) => {
                this.destination = data;
                return this.destination == data;
            }));
    }

    public addCargoOrigin(cargo: Cargo, origin: Location): Observable<boolean> {

        return this.http
            .post("/api/locations/AddOriginToCargo/" + cargo.id, origin)
            .pipe(map((data: any) => {
                this.cargoOrigin = data;
                return this.cargoOrigin == data;
            }));

    }

    public addCargoDestination(cargo: Cargo, destination: Location): Observable<boolean> {

        return this.http
            .post("/api/locations/AddDestinationToCargo/" + cargo.id, destination)
            .pipe(map((data: any) => {
                this.cargoDestination = data;
                return this.cargoDestination == data;
            }));

    }

}
