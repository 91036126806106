﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LoadDocument } from '../../shared/loadDocument';
import { LoadService } from '../../shared/loadService';

@Component({
    selector: "load-document-delete",
    templateUrl: "loadDocumentDelete.component.html",
    styleUrls: ["loadDocumentDelete.component.css"]
})
export class LoadDocumentsDeleteComponent {

    @Input() loadDocument: LoadDocument;
    @Output() deleteLoadDocumentEmitter = new EventEmitter<LoadDocument>();
    @Output() cancelEmitter = new EventEmitter<boolean>();

    constructor(private loadService: LoadService) { }

    onDelete() {
        this.loadService.deleteLoadDocument(this.loadDocument.id)
            .subscribe(_ => {
                this.deleteLoadDocumentEmitter.emit(this.loadService.loadDocument);
            });
    }

    onCancelDelete() {
        this.cancelEmitter.emit(true);
    }
}
