﻿import { Injectable } from '@angular/core';
import { Customer } from './customer';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyType } from './companyType';
import { Contact } from './contact';
import { CustomerContact } from './customerContact';
import { isUndefined } from 'util';
import { CompilePipeMetadata } from '@angular/compiler';
import { TradeReference } from './tradeReference';
import { TradeReferenceQuestionnaire } from './tradeReferenceQuestionnaire';
import { CustomerDocument } from './customerDocument';
import { CompanyDirector } from './companyDirector';

@Injectable()
export class CustomerService {

    public customer: Customer;
    public customers: Customer[];
    public customerDocument: CustomerDocument;
    public customerDocuments: CustomerDocument[];
    public companyType: CompanyType;
    public companyTypes: CompanyType[];
    public contacts: Contact[];
    public customerContact: CustomerContact;
    public customerContacts: CustomerContact[];
    public tradeReference: TradeReference;
    public tradeReferences: TradeReference[];
    public tradeReferenceQuestionnaire: TradeReferenceQuestionnaire;
    public companyDirector: CompanyDirector;
    public companyDirectors: CompanyDirector[];

    constructor(private http: HttpClient) { }

    public loadCompanyTypes(): Observable<boolean> {

        return this.http.get("/api/customers/GetCompanyTypes")
            .pipe(map((data: any[]) => {
                this.companyTypes = data;
                return true;
            }));
    }

    public loadCompanyType(customer:Customer): Observable<boolean> {

        return this.http.get("/api/customers/" + customer.id + "/CompanyType")
            .pipe(map((data: any) => {
                this.companyType = data;
                return true;
            }));
    }

    public loadCustomers(): Observable<boolean> {

        return this.http.get("/api/customers")
            .pipe(map((data: any[]) => {
                this.customers = data;
                return true;
            }));
    }

    public loadCustomer(id: number): Observable<boolean> {

        return this.http.get("/api/customers/" + id)
            .pipe(map((data: any[]) => {
                this.customers = data;
                return true;
            }));
    }


    public getCustomer(): Observable<boolean> {

        return this.http.get("/api/customers/GetCustomer/")
            .pipe(map((data: any) => {
                this.customer = data;
                return true;
            }));
    }

    public getCustomerContact(customer: Customer, contact: Contact): Observable<boolean> {

        return this.http.get("/api/customers/GetCustomerContact/" + customer.id + "/" + contact.id)
            .pipe(map((data: any) => {
                this.customerContact = data;
                return true;
            }));

    }

    public addCustomer(customer: Customer, contact: Contact): Observable<boolean> {

        var queryString = "?contactName=" + contact.contactName + "&contactCell=" + contact.cell;
        if (contact.email != "" && contact.email != null && !isUndefined(contact.email)) queryString = queryString + "&contactEmail=" + contact.email;

        return this.http
            .post("/api/customers/" + queryString, customer )
            .pipe(map((data: any) => {
                this.customer = data;
                return true;
            }));

    }

    public editCustomer(customer: Customer, dateTimeSubmissionClosing: string, dateTimeCommencedBusiness: string): Observable<boolean> {

        var queryString = "?";

        if (customer.companyType != null)
            queryString += "companyTypeId=" + customer.companyType.id + "&";

        if (dateTimeSubmissionClosing != "" && !isUndefined(dateTimeSubmissionClosing))
            queryString += "dateTimeSubmissionClosing=" + dateTimeSubmissionClosing + "&";

        if (dateTimeCommencedBusiness != "" && !isUndefined(dateTimeCommencedBusiness))
            queryString += "dateTimeCommencedBusiness=" + dateTimeCommencedBusiness + "&";

        if (queryString == "?") queryString = "";

        return this.http
            .put("/api/customers" + queryString, customer)
            .pipe(map((data: any) => {
                this.customer = data;
                return true;
            }));

    }

    public addCustomerContact(customerId: number, contact: Contact, contactTypeId: number): Observable<boolean> {

        return this.http
            .post("/api/customers/" + customerId + "/Contact/" + contactTypeId, contact)
            .pipe(map((data: any) => {
                this.customerContact = data;
                return true;
            }));

    }

    public addCompanyDirector(customerId: number, companyDirector: CompanyDirector): Observable<boolean> {

        return this.http
            .post("/api/customers/" + customerId + "/CompanyDirector/", companyDirector)
            .pipe(map((data: any) => {
                this.companyDirector = data;
                return true;
            }));

    }

    public editCustomerContact(contact: Contact, customerId: number, contactTypeId: number): Observable<boolean> {

        return this.http
            .put("/api/customers/" + customerId + "/Contact/" + contactTypeId, contact)
            .pipe(map((data: any) => {
                this.customerContact = data;
                return true;
            }));

    }

    public editCompanyDirector(companyDirector: CompanyDirector): Observable<boolean> {

        return this.http
            .put("/api/customers/CompanyDirector/", companyDirector)
            .pipe(map((data: any) => {
                this.companyDirector = data;
                return true;
            }));

    }

    public deleteCustomerContact(customerContactId: number): Observable<boolean> {

        return this.http
            .delete("/api/customers/Contact/" + customerContactId)
            .pipe(map((data: any) => {
                this.customerContact = data;
                return true;
            }));

    }

    public deleteCompanyDirector(companyDirectorId: number): Observable<boolean> {

        return this.http
            .delete("/api/customers/CompanyDirector/" + companyDirectorId)
            .pipe(map((data: any) => {
                return true;
            }));

    }

    public searchCustomers(searchTerm: string): Observable<boolean> {

        return this.http
            .get("/api/customers/Search/" + searchTerm)
            .pipe(map((data: any) => {
                this.customers = data;
                return true;
            }));

    }

    public loadContacts(customer: Customer): Observable<boolean> {

        return this.http
            .get("/api/customers/GetContacts/" + customer.id)
            .pipe(map((data: any) => {
                this.contacts = data;
                return true;
            }));

    }

    public loadCustomerContacts(customer: Customer): Observable<boolean> {

        return this.http
            .get("/api/customers/GetCustomerContacts/" + customer.id)
            .pipe(map((data: any) => {
                this.customerContacts = data;
                return true;
            }));

    }

    public loadCompanyDirectors(customer: Customer): Observable<boolean> {

        return this.http
            .get("/api/customers/" + customer.id + "/CompanyDirector")
            .pipe(map((data: any) => {
                this.companyDirectors = data;
                return true;
            }));

    }

    public loadTradeReferences(customerId: number): Observable<boolean> {

        return this.http
            .get("/api/customers/TradeReferences/" + customerId)
            .pipe(map((data: any) => {
                this.tradeReferences = data;
                return true;
            }));

    }

    public addTradeReference(customerId: number, tradeReference: TradeReference): Observable<boolean> {

        return this.http
            .post("/api/customers/TradeReferences/" + customerId, tradeReference)
            .pipe(map((data: any) => {
                this.tradeReference = data;
                return true;
            }));

    }

    public editTradeReference(tradeReference: TradeReference): Observable<boolean> {

        return this.http
            .put("/api/customers/TradeReferences/" + tradeReference.id, tradeReference)
            .pipe(map((data: any) => {
                this.tradeReference = data;
                return true;
            }));

    }

    public deleteTradeReference(tradeReferenceId: number): Observable<boolean> {

        return this.http
            .delete("/api/customers/TradeReferences/" + tradeReferenceId)
            .pipe(map((data: any) => {
                this.tradeReference = data;
                return true;
            }));
    }

    public loadQuestionnaire(tradeReferenceId: number): Observable<boolean> {

        return this.http
            .get("/api/customers/TradeReferences/" + tradeReferenceId + "/Questionnaire")
            .pipe(map((data: any) => {
                this.tradeReferenceQuestionnaire = data;
                return data != null;
            }));

    }

    public editTradeReferenceQuestionnaire(tradeReferenceId: number, tradeReferenceQuestionnaire: TradeReferenceQuestionnaire): Observable<boolean> {

        return this.http
            .put("/api/customers/TradeReferences/" + tradeReferenceId + "/Questionnaire", tradeReferenceQuestionnaire)
            .pipe(map((data: any) => {
                this.tradeReferenceQuestionnaire = data;
                return true;
            }));

    }

    public printCreditApplication(customer: Customer) {

        return this.http
            .get("/api/print/CreditApplication/" + customer.id, { responseType: "blob" })
            .pipe(map(blob => {
                return new Blob([blob], { type: "application/pdf" });
            }));

    }

    public printAccountConfirmation(customer: Customer) {

        return this.http
            .get("/api/print/AccountConfirmation/" + customer.id, { responseType: "blob" })
            .pipe(map(blob => {
                return new Blob([blob], { type: "application/pdf" });
            }));

    }

    public printBankDetailsLetter() {

        return this.http
            .get("/api/documents/PrintBankDetailsLetter/", { responseType: "blob" })
            .pipe(map(blob => {
                return new Blob([blob], { type: "application/pdf" });
            }));

    }

    public loadCustomerDocuments(customerId: number): Observable<boolean> {

        return this.http
            .get("/api/customers/CustomerDocuments/" + customerId)
            .pipe(map((data: any) => {
                this.customerDocuments = data;
                return true;
            }));

    }

    public deleteCustomerDocument(customerDocumentId: number): Observable<boolean> {

        return this.http
            .delete("/api/customers/CustomerDocuments/" + customerDocumentId)
            .pipe(map((data: any) => {
                this.customerDocument = data;
                return true;
            }));
    }

    public loadCustomerDocument(customerDocumentId: number) {
        return this.http.get('/api/customers/CustomerDocuments/' + customerDocumentId + "/file", { responseType: "blob" })
            .pipe(map(blob => {
                return new Blob([blob], { type: "application/pdf" });
            }));
    }
    

}