﻿import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { VehicleType } from '../shared/vehicleType';
import { VehicleService } from '../shared/vehicleService';

@Component({
    selector: "vehicleType-list",
    templateUrl: "vehicleTypeList.component.html",
    styleUrls: ["vehicleTypeList.component.css"]
})
export class VehicleTypeListComponent implements OnInit, OnDestroy {

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

    public vehicleTypes: VehicleType[] = [];

    @Output() showEditVehicleTypeEmitter = new EventEmitter<VehicleType>();
    @Output() showDeleteVehicleTypeEmitter = new EventEmitter<VehicleType>();

    constructor(private vehicleService: VehicleService) { }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngOnInit(): void {
        this.loadVehicleTypes();
    }

    loadVehicleTypes() {

        this.vehicleService.loadVehicleTypes()
            .subscribe(success => {
                if (success) {

                    this.dtOptions = {
                        pagingType: 'full_numbers',
                        pageLength: 10
                    };

                    this.vehicleTypes = this.vehicleService.vehicleTypes;
                    this.dtTrigger.next();
                }
            });

    }

    onEditVehicleType(vehicleType: VehicleType) {
        this.showEditVehicleTypeEmitter.emit(vehicleType);
    }

    onDeleteVehicleType(vehicleType: VehicleType) {
        this.showDeleteVehicleTypeEmitter.emit(vehicleType);
    }
}