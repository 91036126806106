﻿import { Component, OnInit } from '@angular/core';
import { UserService } from '../shared/userService';
import { Role } from '../shared/role';
import { Router } from '@angular/router';

@Component({
    selector: "register",
    templateUrl: "register.component.html",
    styleUrls: ["register.component.css"]
})
export class RegisterComponent implements OnInit {
    
    errorMessage: string = "";
    passwordConfirmationFailed = false;
    roles: Role[] = [];
    role: string;

    public creds = {
        id: null,
        firstName: "",
        surname: "",
        email: "",
        username: "",
        password: "",
        loginKey: "",
        isEnabled: false
    };

    constructor(private userService: UserService, private router: Router) {
    }

    ngOnInit(): void {
        this.loadRoles();
    }

    loadRoles() {
        this.userService.loadRoles()
            .subscribe(success => {
                if (success) {
                    this.roles = this.userService.roles;
                }
            });
    }

    onRoleSelect(role: string) {
        this.role = role;
    }

    public passwordConfirmation = {
        newPassword: "",
        newPasswordConfirmation: ""
    };

    confirmPassword() {
        if (this.creds.password === this.passwordConfirmation.newPasswordConfirmation) {
            this.passwordConfirmationFailed = false;
        } else {
            this.passwordConfirmationFailed = true;
        }
    }

    onRegister() {

        this.errorMessage = "";

        if (this.role != "" && this.role != null) {

            this.userService.register(this.creds, this.role)
                .subscribe(data => {

                    alert("Your registration is almost complete. The CRM administrator needs to authorise your account. Following that your account will be active for you to use.");
                    this.creds.firstName = "";
                    this.creds.surname = "";
                    this.creds.email = "";
                    this.creds.username = "";
                    this.creds.password = "";
                    this.creds.loginKey = "";
                    this.passwordConfirmation.newPassword = "";
                    this.passwordConfirmation.newPasswordConfirmation = "";
                    this.errorMessage = "";

                    this.router.navigate(['Login']);

                }, err => {
                    if (err.error.length <= 500) {
                        this.errorMessage = "Failed to register. " + err.error;
                    }
                    else {
                        this.errorMessage = "Failed to register. Please try again.";
                    }
                });

        } else {
            this.errorMessage = "Please select appropriate role.";
        }
    }

    onLogin() {
        this.router.navigate(['Login']);
    }

    onForgotPassword() {
        this.router.navigate(['ForgotPassword']);
    }

}