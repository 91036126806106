﻿import { Component, OnInit } from '@angular/core';
import { Load } from '../shared/load';

@Component({
    selector: "loads",
    templateUrl: "loads.component.html",
    styleUrls: ["loads.component.css"]
})
export class LoadsComponent implements OnInit {

    public showLoadList: boolean;
    public showAddLoad: boolean;
    public showLoad: boolean;
    public showDeleteLoad: boolean;
    public showEditLoad: boolean;

    load: Load;

    ngOnInit(): void {
        this.clearLoadView();
        this.showLoadList = true;
    }

    clearLoadView() {
        this.showLoadList = false;
        this.showLoad = false;
        this.showDeleteLoad = false;
        this.showEditLoad = false;
        this.showAddLoad = false;
    }

    showNewLoad() {
        this.clearLoadView();
        this.showAddLoad = true;
    }

    onShowLoadList() {
        this.clearLoadView();
        this.showLoadList = true;
    }

    onCancelAddLoad(isDeleted: boolean) {
        this.clearLoadView();
        this.showLoadList = true;
    }


    onLoadSaved(isSaved:boolean) {
        this.clearLoadView();
        this.showLoadList = true;
    }

    onEditLoad(load: Load) {
        this.load = load;
        this.clearLoadView();
        this.showEditLoad = true;
    }
}