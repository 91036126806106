﻿import { Supplier } from './supplier';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Contact } from './contact';
import { ContainerType } from './containerType';
import { isUndefined } from 'util';
import { SupplierDocument } from './supplierDocument';
import { SupplierContact } from './supplierContact';
import { CompanyType } from './companyType';

@Injectable()
export class SupplierService {

    public supplier: Supplier;
    public suppliers: Supplier[];
    public contacts: Contact[];
    public containerTypes: ContainerType[];
    public companyType: CompanyType;
    public companyTypes: CompanyType[];
    public supplierDocument: SupplierDocument;
    public supplierDocuments: SupplierDocument[];
    public supplierContact: SupplierContact;
    public supplierContacts: SupplierContact[];

    constructor(private http: HttpClient) { }

    public loadCompanyTypes(): Observable<boolean> {

        return this.http.get("/api/suppliers/GetCompanyTypes")
            .pipe(map((data: any[]) => {
                this.companyTypes = data;
                return true;
            }));
    }

    public loadCompanyType(supplier: Supplier): Observable<boolean> {

        return this.http.get("/api/suppliers/" + supplier.id + "/CompanyType")
            .pipe(map((data: any) => {
                this.companyType = data;
                return true;
            }));
    }

    public loadSuppliers(): Observable<boolean> {

        return this.http.get("/api/Suppliers")
            .pipe(map((data: any[]) => {
                this.suppliers = data;
                return true;
            }));
    }

    public loadSupplier(id: number): Observable<boolean> {

        return this.http.get("/api/Suppliers/" + id)
            .pipe(map((data: any[]) => {
                this.suppliers = data;
                return true;
            }));
    }

    public getSupplier(): Observable<boolean> {

        return this.http.get("/api/Suppliers/GetSupplier/")
            .pipe(map((data: any) => {
                this.supplier = data;
                return true;
            }));
    }

    public addSupplier(supplier: Supplier, contact: Contact): Observable<boolean> {

        var queryString = "?contactName=" + contact.contactName + "&contactCell=" + contact.cell;
        if (contact.email != "" && contact.email != null && !isUndefined(contact.email)) queryString = queryString + "&contactEmail=" + contact.email;

        return this.http
            .post("/api/Suppliers/" + queryString, supplier)
            .pipe(map((data: any) => {
                this.supplier = data;
                return true;
            }));

    }

    public editSupplier(supplier: Supplier, dateTimeCommencedBusiness: string): Observable<boolean> {

        var queryString = "?";

        if (supplier.companyType != null)
            queryString += "companyTypeId=" + supplier.companyType.id + "&";

        if (dateTimeCommencedBusiness != "" && !isUndefined(dateTimeCommencedBusiness))
            queryString += "dateTimeCommencedBusiness=" + dateTimeCommencedBusiness + "&";

        if (queryString == "?") queryString = "";

        return this.http
            .put("/api/suppliers" + queryString, supplier)
            .pipe(map((data: any) => {
                this.supplier = data;
                return true;
            }));

    }

    public searchSuppliers(searchTerm: string): Observable<boolean> {

        return this.http
            .get("/api/suppliers/Search/" + searchTerm)
            .pipe(map((data: any) => {
                this.suppliers = data;
                return true;
            }));

    }

    public loadContacts(supplier: Supplier): Observable<boolean> {

        return this.http
            .get("/api/suppliers/GetContacts/" + supplier.id)
            .pipe(map((data: any) => {
                this.contacts = data;
                return true;
            }));

    }

    public loadContainerTypes(): Observable<boolean> {

        return this.http
            .get("/api/suppliers/GetContainerTypes/")
            .pipe(map((data: any) => {
                this.containerTypes = data;
                return true;
            }));

    }

    public addSupplierContact(supplierId: number, contact: Contact, contactTypeId: number): Observable<boolean> {

        return this.http
            .post("/api/suppliers/" + supplierId + "/Contact/" + contactTypeId, contact)
            .pipe(map((data: any) => {
                this.supplierContact = data;
                return true;
            }));

    }

    public deleteSupplierContact(supplierContactId: number): Observable<boolean> {

        return this.http
            .delete("/api/suppliers/Contact/" + supplierContactId)
            .pipe(map((data: any) => {
                this.supplierContact = data;
                return true;
            }));

    }

    public editSupplierContact(contact: Contact, supplierId: number, contactTypeId: number): Observable<boolean> {

        return this.http
            .put("/api/suppliers/" + supplierId + "/Contact/" + contactTypeId, contact)
            .pipe(map((data: any) => {
                this.supplierContact = data;
                return true;
            }));

    }

    public loadSupplierContacts(supplier: Supplier): Observable<boolean> {

        return this.http
            .get("/api/suppliers/GetSupplierContacts/" + supplier.id)
            .pipe(map((data: any) => {
                this.supplierContacts = data;
                return true;
            }));

    }

    public loadSupplierDocuments(supplierId: number): Observable<boolean> {

        return this.http
            .get("/api/suppliers/SupplierDocuments/" + supplierId)
            .pipe(map((data: any) => {
                this.supplierDocuments = data;
                return true;
            }));

    }

    public deleteSupplierDocument(supplierDocumentId: number): Observable<boolean> {

        return this.http
            .delete("/api/suppliers/SupplierDocuments/" + supplierDocumentId)
            .pipe(map((data: any) => {
                this.supplierDocument = data;
                return true;
            }));
    }

    public loadSupplierDocument(supplierDocumentId: number) {
        return this.http.get('/api/suppliers/SupplierDocuments/' + supplierDocumentId + "/file", { responseType: "blob" })
            .pipe(map(blob => {
                return new Blob([blob], { type: "application/pdf" });
            }));
    }

    public printTransportAgreement() {

        return this.http
            .get("/api/documents/PrintTransportAgreement/", { responseType: "blob" })
            .pipe(map(blob => {
                return new Blob([blob], { type: "application/pdf" });
            }));

    }

}