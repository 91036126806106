﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Equipment } from '../shared/equipment';
import { EquipmentService } from '../shared/equipmentService';

@Component({
    selector: "equipment-delete",
    templateUrl: "equipmentDelete.component.html",
    styleUrls: ["equipmentDelete.component.css"]
})
export class EquipmentDeleteComponent {

    public errorMessage: string;

    @Input() equipment: Equipment;

    @Output() saveEquipmentEmitter = new EventEmitter<Equipment>();

    constructor(private equipmentService: EquipmentService) { }

    onDelete() {
        this.equipmentService.deleteEquipment(this.equipment.id)
            .subscribe(_ => {
                this.saveEquipmentEmitter.emit(this.equipmentService.equipment);
            }, err => {
                this.errorMessage = 'Failed to delete equipment.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error;
                }
            });
    }
}