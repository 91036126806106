﻿import { Component, Input } from '@angular/core';
import { Supplier } from '../../shared/supplier';
import { SupplierDocument } from '../../shared/supplierDocument';

@Component({
    selector: "supplier-documents",
    templateUrl: "supplierDocuments.component.html",
    styleUrls: ["supplierDocuments.component.css"]
})
export class SupplierDocumentsComponent {

    @Input() supplier: Supplier;

    public showSupplierDocumentList: boolean;
    public showDeleteSupplierDocument: boolean;

    public supplierDocument: SupplierDocument;

    ngOnInit(): void {
        this.clearSupplierDocumentView();
        this.showSupplierDocumentList = true;
    }

    clearSupplierDocumentView() {
        this.showSupplierDocumentList = false;
        this.showDeleteSupplierDocument = false;
    }

    onShowSupplierDocumentList() {
        this.clearSupplierDocumentView();
        this.showSupplierDocumentList = true;
    }

    onSupplierDocumentDelete(supplierDocument: SupplierDocument) {
        this.supplierDocument = supplierDocument;
        this.clearSupplierDocumentView();
        this.showDeleteSupplierDocument = true;
    }

    onRefreshSupplierDocumentList(hideSupplierDocument: boolean) {
        this.clearSupplierDocumentView();
        this.showSupplierDocumentList = hideSupplierDocument;
    }

}
