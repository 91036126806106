﻿import { Component, OnInit, EventEmitter, Output, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Customer } from '../../shared/customer';
import { FormControl } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { CustomerService } from '../../shared/customerService';
import { startWith, map, concat } from 'rxjs/operators';
import { isUndefined } from 'util';
import { Load } from '../../shared/load';
import { LoadCustomer } from '../../shared/loadCustomer';
import { Contact } from '../../shared/contact';
import { LoadService } from '../../shared/loadService';
import { saveAs } from "file-saver";

@Component({
    selector: "load-customer-edit",
    templateUrl: "loadCustomerEdit.component.html",
    styleUrls: ["loadCustomerEdit.component.css", "../../app.component.css"]
})
export class LoadCustomerEditComponent implements OnInit, OnChanges {
    
    @Input() loadCustomer: LoadCustomer;
    
    private _contacts: Contact[];
    @Input() set contacts(value: Contact[]) {
        this._contacts = value;
        console.log(this._contacts);
    }

    get contacts(): Contact[] {
        return this._contacts;
    }

    @Output() openModalEmitter = new EventEmitter<string>();
    @Output() editLoadCustomerEmitter = new EventEmitter<LoadCustomer>();
    @Output() cancelLoadCustomerEmitter = new EventEmitter<boolean>();

    customerControl = new FormControl();

    customers: Customer[] = [];
    filteredCustomers: Observable<Customer[]>;

    @Input() customer: Customer;

    customerName: string;

    customerContacts: Contact[] = [];
    customerContact: Contact;
    customerCMSOpsContact: Contact;

    errorMessage: string;

    private _filterCustomers(value: string): Customer[] {
        const filterValue = this._normalizeValue(value);
        return this.customers.filter(c => this._normalizeValue(c.tradingName).includes(filterValue));
    }

    private _normalizeValue(value: string): string {
        return value.toLowerCase().replace(/\s/g, '');
    }

    constructor(private customerService: CustomerService, private loadService: LoadService) { }

    ngOnInit(): void {
        this.loadCustomers();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!isUndefined(this.customer)) {
            this.customerControl.setValue(this.customer.tradingName);

            if (this.customers.find(c => c == this.customer) == null)
                this.customers.push(this.customer);

            this.getCustomer(this.customer.tradingName);
        }
    }

    loadCustomers() {

        this.customerService.loadCustomers()
            .subscribe(s => {
                if (s) {
                    this.customers = this.customerService.customers;

                    this.filteredCustomers = this.customerControl.valueChanges
                        .pipe(
                            startWith(''),
                            map(value => typeof value === 'string' ? value : value),
                            map(name => name ? this._filterCustomers(name) : [])
                        );

                }
            });

    }

    onCustomerChange() {
        // when a customer has been selected from the autocomplete list
        this.getCustomer(this.customerControl.value);
    }

    onOpenModal(data) { 
        // add new customer modal
        this.customerName = data;
        this.openModalEmitter.emit(this.customerName);
    }

    onValidateCustomer(data) {
        // set customer based on selection
        this.getCustomer(this.customerControl.value);
    }

    getCustomer(customerName) {

        this.customerName = customerName;
        this.customer = this.customers.find(c => c.tradingName == this.customerName);

        if (!isUndefined(this.customer)) {
            this.customerService.loadContacts(this.customer)
                .subscribe(s => {
                    if (s) {
                        this.customerContacts = this.customerService.contacts;
                    }
                });
        }
    }

    onSubmit() {

        if (this.customer != null && this.customers.find(c => c == this.customer) == null)
            this.customers.push(this.customer);

        this.customer = this.customers.find(c => c.tradingName == this.customerName);

        if (this.loadCustomer.customer != this.customer) {
            this.loadCustomer.customer = this.customer;
            this.loadCustomer.customerContact == null;
        }

        if (this.customerContact != null) this.loadCustomer.customerContact = this.customerContact;
        if (this.customerCMSOpsContact != null) this.loadCustomer.cmsContact = this.customerCMSOpsContact;

        var d = new Date('2018-01-01');
        var o = - d.getTimezoneOffset();

        if (this.loadCustomer.dateTimeCustomerInvoice == null) { this.loadCustomer.dateTimeCustomerInvoice = new Date(1900, 0, 1, 0, o, 0); } else { this.loadCustomer.dateTimeCustomerInvoice = new Date(this.loadCustomer.dateTimeCustomerInvoice.getTime() + o * 60000); }

        this.loadService.editLoadCustomer(this.loadCustomer, this.loadCustomer.load.id)
            .subscribe(s => {
                if (s) {
                    this.loadCustomer = this.loadService.loadCustomer;
                    this.editLoadCustomerEmitter.emit(this.loadCustomer);
                }
            });

    }

    onCancelCustomer() {
        this.cancelLoadCustomerEmitter.emit(true);
    }

    onCustomerContactSelect(contactId: number) { 
        this.customerContact = this.customerContacts.find(c => c.id == contactId);
    }

    onCustomerCMSContactSelect(contactId: number) {
        this.customerCMSOpsContact = this.contacts.find(c => c.id == contactId);
    }

    onPrintSalesOrder() {
        this.loadService.printSalesOrder(this.loadCustomer.id)
            .subscribe(
                (res) => {

                    var file = new File([res], "Sales Order - " + this.loadCustomer.load.id + ".pdf", { type: "application/pdf" });
                    saveAs(file);

                });
    }

    onPrintProofOfDelivery() {
        this.loadService.printProofOfDelivery(this.loadCustomer.id)
            .subscribe(
                (res) => {

                    var file = new File([res], "ProofOfDelivery - " + this.loadCustomer.load.id + ".pdf", { type: "application/pdf" });
                    saveAs(file);

                });
    }
}