﻿import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { ContactService } from '../shared/contactService';
import { Contact } from '../shared/contact';

@Component({
    selector: "contact-list",
    templateUrl: "contactList.component.html",
    styleUrls: ["contactList.component.css"]
})
export class ContactListComponent implements OnInit, OnDestroy {

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

    public contacts: Contact[] = [];

    @Output() emitEdit = new EventEmitter<Contact>();
    @Output() emitDelete = new EventEmitter<Contact>();

    constructor(private contactService: ContactService) { }

    ngOnInit(): void {

        this.loadContacts();

    }

    loadContacts() {

        

    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }

    onEditContact(contact: Contact) {
        this.emitEdit.emit(contact);
    }

    onDeleteContact(contact: Contact) {
        this.emitDelete.emit(contact);
    }

}