﻿import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { AuthService } from '../auth/authService';
import { take, map } from 'rxjs/operators';


@Component({
    selector: "sidebar",
    templateUrl: "sidebar.component.html",
    styleUrls: ["sidebar.component.css"]
})
export class SidebarComponent implements OnInit {

    @Input() isCustomerSupplier: boolean;
    isManager: boolean;
    isAdmin: boolean;

    @Output() customerEmitter = new EventEmitter<boolean>();
    @Output() supplierEmitter = new EventEmitter<boolean>();
    @Output() userEmitter = new EventEmitter<boolean>();
    @Output() opsContactEmitter = new EventEmitter<boolean>();
    @Output() equipmentEmitter = new EventEmitter<boolean>();
    @Output() homeEmitter = new EventEmitter<boolean>();
    @Output() loadEmitter = new EventEmitter<boolean>();
    @Output() vehicleTypeEmitter = new EventEmitter<boolean>();
    @Output() documentsEmitter = new EventEmitter<boolean>();
    @Output() companyDetailsEmitter = new EventEmitter<boolean>();
    @Output() orderNumberSeedEmitter = new EventEmitter<boolean>();
    
    constructor(private auth: AuthService) {
    }

    ngOnInit(): void {
        this.checkAdminStatus();
    }

    checkAdminStatus() {

        this.auth.isAdminLoggedIn()
            .subscribe(data => {
                if (data)
                    this.isAdmin = this.auth.isAdmin;
            });

    }

    onShowHome() {
        this.homeEmitter.emit(true);
    }

    onShowDocuments() {
        this.documentsEmitter.emit(true);
    }

    onShowLoads() {
        this.loadEmitter.emit(true);
    }

    onShowCustomers() {
        this.customerEmitter.emit(true);
    }

    onShowSuppliers() {
        this.supplierEmitter.emit(true);
    }

    onShowUsers() {
        this.userEmitter.emit(true);
    }

    onShowOpsContacts() {
        this.opsContactEmitter.emit(true);
    }

    onShowEquipment() {
        this.equipmentEmitter.emit(true);
    }

    onShowVehicleTypes() {
        this.vehicleTypeEmitter.emit(true);
    }

    onShowCompanyDetail() {
        this.companyDetailsEmitter.emit(true);
    }

    onShowOrderNumberSeed() {
        this.orderNumberSeedEmitter.emit(true);
    }
}