﻿import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { User } from '../shared/user';
import { Subject } from 'rxjs';
import { UserService } from '../shared/userService';

@Component({
    selector: "user-list",
    templateUrl: "userList.component.html",
    styleUrls: ["userList.component.css"]
})
export class UserListComponent implements OnInit, OnDestroy {

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

    public users: User[] = [];

    @Output() showEnableUserEmitter = new EventEmitter<User>();
    @Output() showDisableUserEmitter = new EventEmitter<User>();

    constructor(private userService: UserService) { }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngOnInit(): void {
        this.loadUser();
    }

    loadUser() {

        this.userService.loadUsers()
            .subscribe(success => {
                if (success) {

                    this.dtOptions = {
                        pagingType: 'full_numbers',
                        pageLength: 10
                    };

                    this.users = this.userService.users;
                    this.dtTrigger.next();
                }
            });

    }

    onEnableUser(user: User) {
        this.showEnableUserEmitter.emit(user);
    }

    onDisableUser(user: User) {
        this.showDisableUserEmitter.emit(user);
    }
}