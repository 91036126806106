﻿import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Load } from '../shared/load';
import { LoadService } from '../shared/loadService';
import { Cargo } from '../shared/cargo';
import { LoadCustomer } from '../shared/loadCustomer';
import { LoadSupplier } from '../shared/loadSupplier';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';
import { CargoLocation } from '../shared/cargoLocation';
import { isUndefined } from 'util';

@Component({
    selector: "loadList",
    templateUrl: "loadList.component.html",
    styleUrls: ["loadList.component.css"]
})
export class LoadListComponent implements OnInit {

    @Output() editLoadEmitter = new EventEmitter<Load>();

    public loads: Load[] = [];
    public loadCustomers: LoadCustomer[] = [];
    public loadSuppliers: LoadSupplier[] = [];
    public selectedLoadCustomers: LoadCustomer[] = [];
    public selectedLoadSuppliers: LoadSupplier[] = [];
    public cargoLocations: CargoLocation[] = [];

    isTravelDate: boolean = false;

    date: NgbDate;
    dateCreated: NgbDate;
    maxDate: NgbDate;

    constructor(private calendar: NgbCalendar, private loadService: LoadService) {
        this.date = calendar.getToday();
        this.dateCreated = calendar.getToday();
        this.maxDate = calendar.getToday();
    }

    ngOnInit(): void {
        this.refreshData(true);
    }

    refreshData(hasDate: boolean) {
        if (hasDate) {
            if (this.isTravelDate) {
                this.getLoadsByDate();
                this.getLoadCustomersByDate();
                this.getLoadSuppliersByDate();
                this.getCargoLocationsByDate();
            }
            else {
                this.getLoadsByLoadDate();
                this.getLoadCustomersByLoadDate();
                this.getLoadSuppliersByLoadDate();
                this.getCargoLocationsByLoadDate();
            }
        }
        else {
            this.getLoads();
            this.getLoadCustomers();
            this.getLoadSuppliers();
            this.getCargoLocations();
        }
    }

    getLoadsByLoadDate() {

        this.loadService.getLoadsByLoadDate(this.dateCreated.year, this.dateCreated.month, this.dateCreated.day)
            .subscribe(success => {
                if (success) {

                    this.loads = this.loadService.loads;
                    this.loads.forEach(l => {
                        l.isDetailVisible = false;
                    });
                }
            });
    }

    getLoadCustomersByLoadDate() {

        this.loadService.getLoadCustomersByLoadDate(this.dateCreated.year, this.dateCreated.month, this.dateCreated.day)
            .subscribe(success => {
                if (success) {
                    this.loadCustomers = this.loadService.loadCustomers;
                }
            });
    }
 
    getLoadSuppliersByLoadDate() {

        this.loadService.getLoadSuppliersByLoadDate(this.dateCreated.year, this.dateCreated.month, this.dateCreated.day)
            .subscribe(success => {
                if (success) {
                    this.loadSuppliers = this.loadService.loadSuppliers;
               }
            });
    }

    getCargoLocationsByLoadDate() {

        this.loadService.getCargoLocationsByLoadDate(this.dateCreated.year, this.dateCreated.month, this.dateCreated.day)
            .subscribe(success => {
                if (success) {
                    this.cargoLocations = this.loadService.cargoLocations;
                }
            });
    }

    getLoadsByDate() {

        this.loadService.getLoadsByDate(this.date.year, this.date.month, this.date.day)
            .subscribe(success => {
                if (success) {

                    this.loads = this.loadService.loads;
                    this.loads.forEach(l => {
                        l.isDetailVisible = false;
                    });
                }
            });
    }

    getLoadCustomersByDate() {

        this.loadService.getLoadCustomersByDate(this.date.year, this.date.month, this.date.day)
            .subscribe(success => {
                if (success) {
                    this.loadCustomers = this.loadService.loadCustomers;
                }
            });
    }

    getLoadSuppliersByDate() {

        this.loadService.getLoadSuppliersByDate(this.date.year, this.date.month, this.date.day)
            .subscribe(success => {
                if (success) {
                    this.loadSuppliers = this.loadService.loadSuppliers;
                }
            });
    }

    getCargoLocationsByDate() {

        this.loadService.getCargoLocationsByDate(this.date.year, this.date.month, this.date.day)
            .subscribe(success => {
                if (success) {
                    this.cargoLocations = this.loadService.cargoLocations;
                }
            });
    }

    getLoads() {

        this.loadService.getLoads()
            .subscribe(success => {
                if (success) {

                    this.loads = this.loadService.loads;
                    this.loads.forEach(l => {
                        l.isDetailVisible = false;
                    });
                }
            });
    }

    getLoadCustomers() {

        this.loadService.getLoadCustomersNoDate()
            .subscribe(success => {
                if (success) {
                    this.loadCustomers = this.loadService.loadCustomers;
                }
            });
    }

    getLoadSuppliers() {

        this.loadService.getLoadSuppliersNoDate()
            .subscribe(success => {
                if (success) {
                    this.loadSuppliers = this.loadService.loadSuppliers;
                }
            });
    }

    getCargoLocations() {

        this.cargoLocations = [];
    }

    onDetails(id: number) {
        this.loads.forEach(l => l.isDetailVisible = false);
        this.loads.find(l => l.id == id).isDetailVisible = !this.loads.find(l => l.id == id).isDetailVisible;
        this.selectedLoadCustomers = this.loadCustomers.filter(l => l.load.id == id);
        this.selectedLoadSuppliers = this.loadSuppliers.filter(l => l.load.id == id);
    }

    onEditLoad(id: number) {
        var load = this.loads.find(l => l.id == id);
        this.editLoadEmitter.emit(load);
    }

    onUpdateCreateDate(date: any) {
        this.dateCreated = date;
        this.isTravelDate = false;
        this.refreshData(true);
    }

    onUpdateTravelDate(date: any) {
        this.date = date;
        this.isTravelDate = true;
        this.refreshData(true);
    }

    getLoadCargos(load: Load) {

        var cargoList;
        var r = "";
        var counter = 0;

        this.loadService.getCargo(load.id)
            .subscribe(s => {
                if (s) {

                    if (counter == 0) {

                        cargoList = this.loadService.cargos;

                        cargoList.forEach(c => {
                            r += "<div>" + c.description + "</div>";
                        });

                    }

                }
            });

        return r;

    }

    getCustomerDetails(load: Load): string {

        var toReturn = "";
        var lc = this.loadCustomers.filter(l => l.load.id == load.id);

        lc.forEach(l => {
            toReturn += "<div>";
            if (!isUndefined(l.purchaseOrderNumber) && l.purchaseOrderNumber != null) toReturn += "P/O number: <strong>" + l.purchaseOrderNumber + "</strong><br />";
            if (!isUndefined(l.customer) && l.customer != null) toReturn += "Customer: " + l.customer.tradingName + "<br />";
            if (!isUndefined(l.customerContact) && l.customerContact != null) toReturn += "Contact: " + l.customerContact.contactName + "<br />";
            if (!isUndefined(l.cmsContact) && l.cmsContact != null) toReturn += "Logistic Solutions Contact: " + l.cmsContact.contactName + "<br />";

            l.cargos.forEach(c => {
                var origin = this.cargoLocations.find(o => o.cargo.id == c.id && o.type == "Origin");
                if (!isUndefined(origin) && origin != null) toReturn += "<strong>Origin: " + origin.location.name + "</strong><br />";
                var destination = this.cargoLocations.find(o => o.cargo.id == c.id && o.type == "Destination");
                if (!isUndefined(destination) && destination != null) toReturn += "<strong>Desination: " + destination.location.name + "</strong><br />";
            });

            toReturn += "</div><br />";
        });
        return toReturn;

    }

    getSupplierDetails(load: Load): string {

        var toReturn = "";
        var ls = this.loadSuppliers.find(l => l.load.id == load.id);
        if (!isUndefined(ls)) {
            if (!isUndefined(ls.supplier) && ls.supplier != null) toReturn += "Supplier: " + ls.supplier.tradingName + "<br />";
            if (!isUndefined(ls.supplierContact) && ls.supplierContact != null) toReturn += "Contact: " + ls.supplierContact.contactName + "<br />";
            if (!isUndefined(ls.cmsContact) && ls.cmsContact != null) toReturn += "Logistic Solutions Contact: " + ls.cmsContact.contactName + "<br />";
            toReturn += "</div><br />";
        }
        return toReturn;

    }

    onUndated() {
        this.refreshData(false);
    }

}