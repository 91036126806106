﻿import { Component, Input, OnInit } from '@angular/core';
import { EmailService } from '../shared/emailService';

@Component({
    selector: "email-document",
    templateUrl: "emailDocument.component.html",
    styleUrls: ["emailDocument.component.css", "../app.component.css"]
})
export class EmailDocumentComponent implements OnInit {

    ngOnInit(): void {
        this.email.recipient = this.recipient;
        this.email.type = this.title.replace("Email ", "");
        this.email.subject = this.title.replace("Email ", "");
        this.email.message = "Hi there,\n\nPlease find attached " + this.email.subject + ".\n\nKind regards,\nThe Logistics Solution Team";
    }

    @Input() title: string;
    @Input() recipient: string;
    @Input() emailId: number;

    public errorMessage: string;

    public email = {
        id: 0,
        type: null,
        recipient: null,
        subject: null,
        message: null
    }

    constructor(private emailService: EmailService) { }

    onSubmit() {
        this.emailService.sendEmail(this.email, this.emailId)
            .subscribe(data => {
                alert("Email has been sent.");
            }, err => {
                if (err.error.length <= 500) {
                    this.errorMessage = "Failed to send email. " + err.error;
                }
                else {
                    this.errorMessage = "Failed to send email. Please try again.";
                }
            });
    }

}