﻿import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { isUndefined } from 'util';
import { Contact } from '../shared/contact';
import { ContactService } from '../shared/contactService';
import { Customer } from '../shared/customer';
import { CustomerService } from '../shared/customerService';
import { Load } from '../shared/load';
import { LoadCustomer } from '../shared/loadCustomer';
import { LoadService } from '../shared/loadService';
import { Supplier } from '../shared/supplier';
import { SupplierService } from '../shared/supplierService';
import { VehicleService } from '../shared/vehicleService';
import { saveAs } from "file-saver";
import { AuthService } from '../auth/authService';
import { VehicleType } from '../shared/vehicleType';
import { CargoService } from '../shared/cargoService';
import { LocationService } from '../shared/locationService';
import { CargoLocation } from '../shared/cargoLocation';
import { Cargo } from '../shared/cargo';
import { Equipment } from '../shared/equipment';
import { Location } from '../shared/location';

@Component({
    selector: "load-edit",
    templateUrl: "loadEdit.component.html",
    styleUrls: ["loadEdit.component.css", "../app.component.css"]
})
export class LoadEditComponent implements OnInit {

    @Output() saveLoadEmitter = new EventEmitter<boolean>();
    @Output() cancelLoadEmitter = new EventEmitter<boolean>();
    isAdmin: boolean;

    supplierControl = new FormControl();
    suppliers: Supplier[] = [];
    filteredSuppliers: Observable<Supplier[]>;

    supplierName: string;
    supplier: Supplier;
    supplierContacts: Contact[] = [];
    supplierContact: Contact;
    supplierCMSOpsContact: Contact;

    customerError: string = "";

    customerName: string;
    customer: Customer;

    loadCustomers: LoadCustomer[] = [];
    vehicleTypes: VehicleType[] = [];

    cargoLocations: CargoLocation[] = [];

    recipient: string = "";
    emailId: number = 0;

    closeResult: string;
    modalHeader: string;

    isAddCustomer: boolean;
    isSearchCustomer: boolean;
    isAddSupplier: boolean;
    isSearchSupplier: boolean;
    isAddCargo: boolean;
    isAddEquipment: boolean;
    isSearchEquipment: boolean;
    isEmailDocument: boolean;

    public loadSupplier = {
        id: 0,
        supplierRate: 0,
        supplierInvoiceNumber: null,
        dateTimeSupplierInvoice: null,
        supplier: null,
        load: null,
        supplierContact: null,
        cmsContact: null
    }

    @Input() load: Load;

    contacts: Contact[] = [];

    private _filterSuppliers(value: string): Supplier[] {
        const filterValue = this._normalizeValue(value);
        return this.suppliers.filter(s => this._normalizeValue(s.tradingName).includes(filterValue));
    }

    private _normalizeValue(value: string): string {
        return value.toLowerCase().replace(/\s/g, '');
    }

    constructor(private loadService: LoadService, private customerService: CustomerService, private contactService: ContactService, private modalService: NgbModal, private supplierService: SupplierService, private vehicleService: VehicleService, private cargoService: CargoService, private locationService: LocationService, private auth: AuthService) { }

    ngOnInit(): void {
        this.loadSuppliers();
        this.loadVehicleTypes();
        this.loadOpsContacts();
        this.loadLoadCustomers();
        this.loadLoadSupplier();
        this.checkAdminStatus();
    }

    onCargoLocationAdded(cargoLocation: CargoLocation) {
        this.cargoLocations.push(cargoLocation);
    }

    checkAdminStatus() {

        this.auth.isAdminLoggedIn()
            .subscribe(data => {
                if (data)
                    this.isAdmin = this.auth.isAdmin;
            });

    }

    loadLoadSupplier() {

        this.loadService.getSupplier(this.load.id)
            .subscribe(s => {
                if (s) {
                    this.loadSupplier = this.loadService.loadSupplier;
                    this.onSupplierSelected(this.loadService.supplier);
                    this.loadLoadSupplierContacts();
                }
            });

    }

    loadLoadSupplierContacts() {

        this.loadService.getSupplierContacts(this.load.id)
            .subscribe(s => {
                if (s) {
                    this.supplierCMSOpsContact = this.loadService.supplierCMSOpsContact;
                    this.supplierContact = this.loadService.supplierContact;
                }
            });

    }

    // load vehicle types
    //loadVehicle() {

    //    this.loadService.getVehicle(this.load.id)
    //        .subscribe(s => {
    //            if (s) {
    //                this.onVehicleSelected(this.loadService.vehicle);
    //            }
    //        });

    //}

    loadSuppliers() {

        this.supplierService.loadSuppliers()
            .subscribe(s => {
                if (s) {
                    this.suppliers = this.supplierService.suppliers;

                    this.filteredSuppliers = this.supplierControl.valueChanges
                        .pipe(
                            startWith(''),
                            map(value => typeof value === 'string' ? value : value),
                            map(name => name ? this._filterSuppliers(name) : [])
                        );

                }
            });

    }

    loadVehicleTypes() {

        this.vehicleService.loadVehicleTypes()
            .subscribe(s => {
                if (s) {
                    this.vehicleTypes = this.vehicleService.vehicleTypes;
                }
            });

    }

    // load vehicle types???
    //loadVehicles() {

    //    this.vehicleService.loadVehicles()
    //        .subscribe(s => {
    //            if (s) {
    //                this.vehicles = this.vehicleService.vehicles;
    //                this.loadVehicle();

    //                this.filteredVehicles = this.vehicleControl.valueChanges
    //                    .pipe(
    //                        startWith(''),
    //                        map(value => typeof value === 'string' ? value : value),
    //                        map(name => name ? this._filterVehicles(name) : [])
    //                    );
    //            }
    //        });

    //}

    loadOpsContacts() {
        this.contactService.loadOpsContacts()
            .subscribe(s => {
                if (s) {
                    this.contacts = this.contactService.contacts;
                }
            });
    }

    loadLoadCustomers() {

        this.loadService.getLoadCustomers(this.load.id)
            .subscribe(s => {
                if (s) {
                    this.loadCustomers = this.loadService.loadCustomers;
                        
                    if (this.loadCustomers.length == 0) {
                        this.addLoadCustomer();
                    }
                }
            });

        
    }

    clearModal() {
        this.isSearchCustomer = false;
        this.isAddCustomer = false;
        this.isSearchSupplier = false;
        this.isAddSupplier = false;
        this.isAddCargo = false;
        this.isAddEquipment = false;
        this.isSearchEquipment = false;
        this.isEmailDocument = false;
    }

    onCustomerSelected(customer: Customer) {
        this.customer = customer;
        this.modalService.dismissAll()
    }

    onSupplierSelected(supplier: Supplier) {
        this.supplier = supplier;

        if (!isUndefined(this.supplier)) {
            this.supplierControl.setValue(this.supplier.tradingName);

            if (this.suppliers.find(s => s == this.supplier) == null)
                this.suppliers.push(this.supplier);

            this.getSupplier(this.supplier.tradingName);
        }

        this.loadSupplierContacts();
        this.modalService.dismissAll()
    }

    loadSupplierContacts() {
        this.supplierService.loadContacts(this.supplier)
            .subscribe(s => {
                if (s) {
                    this.supplierContacts = this.supplierService.contacts;
                }
            });
    }


    onSupplierContactSelect(contactId: number) {
        this.supplierContact = this.supplierContacts.find(c => c.id == contactId);
    }

    onSupplierCMSContactSelect(contactId: number) {
        this.supplierCMSOpsContact = this.contacts.find(c => c.id == contactId);
    }

    onVehicleTypeSelect(vehicleTypeId: number) {
        this.load.vehicleType = this.vehicleTypes.find(v => v.id == vehicleTypeId);
    }

    // select vehicle type
    //onVehicleSelected(vehicle: Vehicle) {
    //    this.vehicle = vehicle;

    //    if (!isUndefined(this.vehicle)) {
    //        this.vehicleControl.setValue(this.vehicle.registrationNumber);
    //    }

    //    this.modalService.dismissAll()
    //}

    onSetCustomerName(customerName: string) {
        this.customerName = customerName;
    }

    onOpenModal(content, modal: string) {

        if (modal == "SearchCustomer") {
            this.modalHeader = "Customer Search";
            this.clearModal();
            this.isSearchCustomer = true;
        }
        else if (modal == "CreateCustomer") {
            this.modalHeader = "Create Customer";
            this.clearModal();
            this.isAddCustomer = true;
        }
        else if (modal == "SearchSupplier") {
            this.modalHeader = "Supplier Search";
            this.clearModal();
            this.isSearchSupplier = true;
        }
        else if (modal == "CreateSupplier") {
            this.modalHeader = "Create Supplier";
            this.clearModal();
            this.isAddSupplier = true;
        }
        else if (modal == "SearchEquipment") {
            this.modalHeader = "Equipment Search";
            this.clearModal();
            this.isSearchEquipment = true;
        }
        else if (modal == "CreateEquipment") {
            this.modalHeader = "Add Equipment";
            this.clearModal();
            this.isAddEquipment = true;
        }
        else if (modal == "EmailPurchaseOrder") {
            this.modalHeader = "Email Purchase Order";
            this.clearModal();
            this.emailId = this.load.id;
            if (this.supplierContact != null)
                this.recipient = this.supplierContact.email;
            this.isEmailDocument = true;
        }
        else if (modal == "EmailSalesOrder") {
            this.modalHeader = "Email Sales Order";
            this.clearModal();
            this.isEmailDocument = true;
        }
        else if (modal == "EmailProofOfDelivery") {
            this.modalHeader = "Email Proof Of Delivery";
            this.clearModal();
            if (this.supplierContact != null)
                this.recipient = this.supplierContact.email;
            this.isEmailDocument = true;
        }

        this.open(content);
    }

    onSetEmailId(id: number) {
        this.emailId = id;
    }

    onSetRecipient(recipient: string) {
        this.recipient = recipient;
    }

    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    onLoadCustomerAdded(loadCustomer: LoadCustomer) {
        this.loadCustomers.push(loadCustomer);
    }

    onLoadCustomerDeleted(loadCustomer: LoadCustomer) {
        if (this.loadCustomers.find(l => l == loadCustomer)) {
            this.loadCustomers = this.loadCustomers.filter(l => l != loadCustomer);
        }
    }

    onLoadCustomerEdited(loadCustomer: LoadCustomer) {
        if (this.loadCustomers.find(l => l == loadCustomer)) {
            this.loadCustomers = this.loadCustomers.filter(l => l != loadCustomer);
            this.loadCustomers.push(loadCustomer);
        }
    }


    onSupplierChange() {
        // when a customer has been selected from the autocomplete list
        this.getSupplier(this.supplierControl.value);
    }

    onValidateSupplier(data) {
        // set customer based on selection
        this.getSupplier(this.supplierControl.value);
    }

    getSupplier(supplierName) {

        this.supplierName = supplierName;
        this.supplier = this.suppliers.find(c => c.tradingName == this.supplierName);

        if (!isUndefined(this.supplier)) {
            this.supplierService.loadContacts(this.supplier)
                .subscribe(s => {
                    if (s) {
                        this.supplierContacts = this.supplierService.contacts;
                    }
                });
        }
    }

    updateSupplier() {

        this.loadSupplier.load = this.load.id == 0 ? null : this.load;
        this.loadSupplier.supplier = (isUndefined(this.supplier) || this.supplier == null || this.supplier.id == 0) ? null : this.supplier;
        this.loadSupplier.cmsContact = (isUndefined(this.supplierCMSOpsContact) || this.supplierCMSOpsContact == null || this.supplierCMSOpsContact.id == 0) ? null : this.supplierCMSOpsContact;
        this.loadSupplier.supplierContact = (isUndefined(this.supplierContact) || this.supplierContact == null || this.supplierContact.id == 0) ? null : this.supplierContact;

        var d = new Date('2018-01-01');
        var o = - d.getTimezoneOffset();

        //if (this.loadSupplier.dateTimeSupplierInvoice == null) { this.loadSupplier.dateTimeSupplierInvoice = new Date(1900, 0, 1, 0, o, 0); } else { this.loadSupplier.dateTimeSupplierInvoice = new Date(this.loadSupplier.dateTimeSupplierInvoice.getTime() + o * 60000); }

        // add supplier to load
        if (this.supplier != null) {
            this.loadService.addSupplierToLoad(this.loadSupplier)
                .subscribe(x => {
                    if (x) {
                        this.loadSupplier = this.loadService.loadSupplier;

                        if (!this.customerError)  
                            this.saveLoadEmitter.emit(true);
                    }
                    else {
                        if (!this.customerError)  
                            this.saveLoadEmitter.emit(true);
                    }
                });
        }
        else {
            if (!this.customerError)  
                this.saveLoadEmitter.emit(true);
        }

    }


    onPrintPurchaseOrder() {
        this.loadService.printPurchaseOrder(this.load.id)
            .subscribe(
                (res) => {

                    var file = new File([res], "Purchase Order - " + this.load.id + ".pdf", { type: "application/pdf" });
                    saveAs(file);

                });
    }

    onApproveLoad() {

        if (confirm('Are you sure that you want to approve this load?')) {
            this.loadService.approveLoad(this.load.id)
                .subscribe(s => {
                        if (s) {
                            this.load = this.loadService.load;
                            this.loadLoadCustomers();
                        }
                    }
                );
        }
    }

    onLoadCustomerCancel(cancel: boolean) {
        //this.pullLoadCustomer = false;

    }

    selected = new FormControl(0);

    addLoadCustomer() {

        var loadCustomer = {
            id: 0,
            purchaseOrderNumber: null,
            customerRate: 0,
            commission: 0,
            customerInvoiceNumber: null,
            orderReference: null,
            dateTimeCustomerInvoice: null,
            customer: {
                id: 0,
                customerAccountNo: null,
                registeredName: null,
                tradingName: 'Customer',
                companyRegisteredNo: null,
                vatRegisteredNo: null,
                typeOfBusiness: null,
                dateTimeCommencedBusiness: null,
                companyType: null,
                addressLine1: null,
                addressLine2: null,
                addressLine3: null,
                addressLine4: null,
                addressLine5: null,
                city: null,
                postalAddressLine1: null,
                postalAddressLine2: null,
                postalAddressLine3: null,
                postalAddressLine4: null,
                postalAddressLine5: null,
                phone1: null,
                phone2: null,
                fax: null,
                email: null,
                bank: null,
                branchCode: null,
                accountNo: null,
                auditors: null,
                dateTimeSubmissionClosing: null,
                creditRequired: 0,
                associatedCompanies: null,
                customerStatus: null,
                dateTimeCreated: new Date(),
                isCreditApplicationForms: false,
                isDirectorIDsReceived: false
            },
            load: null,
            customerContact: null,
            cmsContact: null,
            cargos: null
        }

        this.loadCustomers.push(loadCustomer);
        this.selected.setValue(this.loadCustomers.length - 1);
    }

    removeLoadCustomer(index: number) {

        if (this.loadCustomers[index].id > 0) {

            this.loadService.removeCustomerFromLoad(this.loadCustomers[index])
                .subscribe(s => {
                    if (s)
                        console.log("Customer removed");
                })

        }

        this.loadCustomers.splice(index, 1);
    }

    //editLoad() {

    //    this.customerError = "";

    //    if (this.loadCustomers.length != 0) {

    //        // Add load
    //        this.loadService.updateLoad(this.load)
    //            .subscribe(s => {
    //                if (s) {
    //                    this.load = this.loadService.load;

    //                    this.addSupplier();
    //                }
    //            });

    //    }
    //    else {
    //        this.customerError = "A customer is required. Please add a new customer.";
    //    }
    //}

    onSubmitLoad() {

        this.customerError = "";

        if (this.loadCustomers.length != 0) {

            if (this.loadCustomers[0].customer.id != 0) {

                this.updateLoad();

            }
            else {
                this.customerError = "A customer is required. Please add a new customer.";
            }

        }
        else {
            this.customerError = "A customer is required. Please add a new customer.";
        }
    }

    updateLoad() {

        // This is to be run when the load is ready to be created or saved
        // Add load
        this.loadService.updateLoad(this.load)
            .subscribe(s => {
                if (s) {
                    this.load = this.loadService.load;
                    this.updateLoadCustomers().then(res => this.updateSupplier());
                }
                else {
                    this.customerError = "Error updating load!";
                }
            });

    }

    updateLoadCustomers() {

        var counter = 0;
        var length = this.loadCustomers.length;

        return new Promise((resolve, reject) => {
            this.loadCustomers.forEach(loadCustomer => {

                counter++;

                this.loadService.editLoadCustomer(loadCustomer, loadCustomer.load.id)
                .subscribe(s => {
                    if (s) {

                        var loadCustomerId = this.loadService.loadCustomer.id;

                        this.updateCargo(loadCustomerId, loadCustomer);

                        if (counter == length) resolve();
                    }
                    else {
                        this.customerError = "Error updating load customers!";

                        if (counter == length) resolve();
                    }
                });

            });

        });
    }

    updateCargo(loadCustomerId: number, loadCustomer: LoadCustomer) {

        loadCustomer.cargos.forEach(cargo => {

            var key: number = cargo.key;

            this.cargoService.updateCargo(cargo, loadCustomer.id)
                .subscribe(s => {

                    if (s) {

                        var eqms = cargo.equipments;
                        cargo = this.cargoService.cargo;

                        this.cargoLocations.filter(cl => cl.cargo.key == key).forEach(cl => {

                            if (cl.type == 'Origin') {

                                this.updateCargoOrigin(cargo, cl.location);

                            }

                            if (cl.type == 'Destination') {

                                this.updateCargoDestination(cargo, cl.location);

                            }

                        });

                        if (eqms.filter(e => e.isChecked == true).length > 0) {

                            this.updateCargoEquipment(cargo, eqms.filter(e => e.isChecked == true));

                        }

                    }
                    else {
                        this.customerError = "Cargo information missing!";
                    }

                });

        });

    }

    updateCargoEquipment(cargo: Cargo, equipments: Equipment[]) {
        // add each equipment
        equipments.forEach(e => {

            if (e.isChecked) {

                this.cargoService.addCargoEquipment(cargo.id, e.id)
                    .subscribe(x => {
                        console.log("Equipment added");
                    });

            }

        });
    }

    updateCargoOrigin(cargo: Cargo, location: Location) {

        this.locationService.addCargoOrigin(cargo, location)
            .subscribe(x => {

                console.log("Origin added");

            });

    }

    updateCargoDestination(cargo: Cargo, location: Location) {

        this.locationService.addCargoDestination(cargo, location)
            .subscribe(x => {

                console.log("Destination added");

            });

    }

    onCancelLoad() {

        if (confirm("Are you sure you want to cancel this load? This is not reversible.")) {
            this.loadService.deleteLoad(this.load.id)
                .subscribe(s => {
                    // load deleted
                    this.cancelLoadEmitter.emit(true)
            });
        }

    }

    onBackToLoads() {
        this.cancelLoadEmitter.emit(true);
    }
}