﻿import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { CustomerService } from '../../shared/customerService';
import { Customer } from '../../shared/customer';
import { CustomerContact } from '../../shared/customerContact';

@Component({
    selector: "customer-contact-list",
    templateUrl: "customerContactList.component.html",
    styleUrls: ["customerContactList.component.css"]
})
export class CustomerContactListComponent implements OnInit {

    @Input() customer: Customer;

    public customerContacts: CustomerContact[] = [];

    @Output() showEditCustomerContactEmitter = new EventEmitter<CustomerContact>();
    @Output() showDeleteCustomerContactEmitter = new EventEmitter<CustomerContact>();

    constructor(private customerService: CustomerService) { }


    ngOnInit(): void {
        this.loadCustomerContacts();
    }

    loadCustomerContacts() {

        this.customerService.loadCustomerContacts(this.customer)
            .subscribe(success => {
                if (success) {

                    this.customerContacts = this.customerService.customerContacts;
                }
            });

    }

    onEditCustomerContact(customerContact: CustomerContact) {
        this.showEditCustomerContactEmitter.emit(customerContact);
    }

    onDeleteCustomerContact(customerContact: CustomerContact) {
        this.showDeleteCustomerContactEmitter.emit(customerContact);
    }

}