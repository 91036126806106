﻿import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Cargo } from '../shared/cargo';
import { Location } from '../shared/location';
import { CargoLocation } from '../shared/cargoLocation';
import { CargoEquipment } from '../shared/cargoEquipment';
import { LoadCustomer } from '../shared/loadCustomer';
import { CargoService } from '../shared/cargoService';

@Component({
    selector: "cargos",
    templateUrl: "cargos.component.html",
    styleUrls: ["cargos.component.css"]
})
export class CargosComponent {

    @Input() loadCustomer: LoadCustomer;

    @Output() addCargoEmitter = new EventEmitter<Cargo>();
    @Output() editCargoEmitter = new EventEmitter<Cargo>();
    @Output() addCargoLocationEmitter = new EventEmitter<CargoLocation>();
    @Output() cancelCargoEmitter = new EventEmitter<boolean>();

    showCargoList: boolean;
    showCargo: boolean;
    showDeleteCargo: boolean;
    showEditCargo: boolean;

    cargo: Cargo;
    @Input() cargos: Cargo[];
    @Input() cargoLocations: CargoLocation[];
    origin: Location;
    destination: Location;

    constructor(private cargoService: CargoService) { }

    ngOnInit(): void {

        if (this.cargos == null) {
            this.cargos = [];
        }

        if (this.cargoLocations == null) {
            this.cargoLocations = [];
        }

        this.clearCargoView();

        if (this.loadCustomer.id != 0) {
            this.showCargoList = true;
        } else {
            this.showCargo = true;
        }

    }

    clearCargoView() {
        this.showDeleteCargo = false;
        this.showCargo = false;
        this.showCargoList = false;
        this.showEditCargo = false;
    }

    openCargoAdd() {
        this.clearCargoView();
        this.showCargo = true;
    }

    onCargoCancel() {
        this.clearCargoView();
        this.showCargoList = true;
    }

    onCargoLocationAdded(cargoLocation: CargoLocation) {
        this.cargoLocations.push(cargoLocation);
        this.addCargoLocationEmitter.emit(cargoLocation);
    }

    onCargoAdded(cargo: Cargo) {
        this.cargo = cargo;

        this.addCargoEmitter.emit(this.cargo);
        this.clearCargoView();
        this.showCargoList = true;
    }

    onCargoEdited(cargo: Cargo) {
        this.cargo = cargo;
        this.editCargoEmitter.emit(this.cargo);
        this.clearCargoView();
        this.showCargoList = true;
    }

    onSetOrigin(location: Location) {
        this.origin = location;
    }

    onSetDestination(location: Location) {
        this.destination = location;
    }

    onCargoEdit(cargo: Cargo) {
        this.cargo = cargo;
        //this.getOrigin_CargoEdit(cargo);
        this.clearCargoView();
        this.showEditCargo = true;
    }

    getOrigin_CargoEdit(c: Cargo) {
        this.cargoService.getOrigin(c.id)
            .subscribe(s => {
                if (s) {
                    this.origin = this.cargoService.origin;
                //    this.getDestination_CargoEdit(c);
                //}
                //else {
                //    this.getDestination_CargoEdit(c);
                }
            });
    }

    getDestination_CargoEdit(c: Cargo) {
        this.cargoService.getDestination(c.id)
            .subscribe(s => {
                if (s) {
                    this.destination = this.cargoService.destination;
                //    this.clearCargoView();
                //    this.showEditCargo = true;
                //}
                //else {
                //    this.clearCargoView();
                //    this.showEditCargo = true;
                }
            });
    }

    onCloseCargo() {
        this.cancelCargoEmitter.emit(true);
    }

    onCargoDelete(cargo: Cargo) {
        this.cargo = cargo;
        this.clearCargoView();
        this.showDeleteCargo = true;
    }


}