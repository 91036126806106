﻿import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CompanyDetail } from './companyDetail';

@Injectable()
export class CompanyDetailService {

    public companyDetail: CompanyDetail;

    constructor(private http: HttpClient) { }

    public loadCompanyDetail(): Observable<boolean> {

        return this.http.get("/api/CompanyDetails")
            .pipe(map((data: any) => {
                this.companyDetail = data;
                return true;
            }));
    }

    public editCompanyDetail(companyDetail: CompanyDetail): Observable<boolean> {

        return this.http.put("/api/CompanyDetails/", companyDetail)
            .pipe(map((data: any) => {
                this.companyDetail = data;
                return (this.companyDetail != null);
            }));

    }
}