﻿import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { CustomerService } from '../../shared/customerService';
import { Customer } from '../../shared/customer';
import { Contact } from '../../shared/contact';
import { CustomerContact } from '../../shared/customerContact';
import { ContactType } from '../../shared/contactType';
import { ContactService } from '../../shared/contactService';

@Component({
    selector: "customer-contact-add",
    templateUrl: "customerContactAdd.component.html",
    styleUrls: ["customerContactAdd.component.css"]
})
export class CustomerContactAddComponent implements OnInit {

    @Input() customer: Customer;
    public errorMessage: string;

    dropdownSettings = {};

    public contact = {
        id: 0,
        contactName: null,
        email: null,
        cell: null,
        isInternal: false,
        isActive: true
    }

    contactTypes: ContactType[] = [];
    contactType: ContactType;

    @Output() addCustomerContactEmitter = new EventEmitter<CustomerContact>();

    constructor(private customerService: CustomerService, private contactService: ContactService) { }

    ngOnInit(): void {
        this.loadContactTypes();
    }

    loadContactTypes() {
        this.contactService.loadContactTypes()
            .subscribe(s => {

                this.contactTypes = this.contactService.contactTypes;

            });
    }

    onContactTypeSelect(id) {
        this.contactType = this.contactTypes.find(t => t.id == id);
    }

    onSubmit() {
        this.customerService.addCustomerContact(this.customer.id, this.contact, this.contactType.id)
            .subscribe(_ => {

                this.addCustomerContactEmitter.emit(this.customerService.customerContact);

            }, err => {
                this.errorMessage = 'Failed to add contact.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error;
                }
            });
    }
}