﻿import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TradeReference } from '../../../shared/tradeReference';
import { CustomerService } from '../../../shared/customerService';
import { TradeReferenceQuestionnaire } from '../../../shared/tradeReferenceQuestionnaire';
import { isUndefined } from 'util';

@Component({
    selector: "trade-reference-questionnaire",
    templateUrl: "tradeReferenceQuestionnaire.component.html",
    styleUrls: ["tradeReferenceQuestionnaire.component.css"]
})
export class TradeReferenceQuestionnaireComponent implements OnInit {

    @Input() tradeReference: TradeReference;
    tradeReferenceQuestionnaire: TradeReferenceQuestionnaire;
    @Output() editTradeReferenceQuestionnaireEmitter = new EventEmitter<boolean>();

    errorMessage: string;

    constructor(private customerService: CustomerService) { }

    ngOnInit(): void {
        this.loadQuestionnaire();
    }

    loadQuestionnaire() {

        this.tradeReferenceQuestionnaire = { accountAge: null, businessType: null, creditLimit: null, goodPayers: null, id: 0, payDate: null };

        this.customerService.loadQuestionnaire(this.tradeReference.id)
            .subscribe(s => {
                
                if (s) {

                    if (isUndefined(this.customerService.tradeReferenceQuestionnaire)) {
                        this.tradeReferenceQuestionnaire = { accountAge: null, businessType: null, creditLimit: null, goodPayers: null, id: 0, payDate: null };
                    }
                    else {
                        this.tradeReferenceQuestionnaire = this.customerService.tradeReferenceQuestionnaire;
                    }

                }
                else {
                    this.tradeReferenceQuestionnaire = { accountAge: null, businessType: null, creditLimit: null, goodPayers: null, id: 0, payDate: null };
                }

            });

    }

    onSubmit() {
        this.customerService.editTradeReferenceQuestionnaire(this.tradeReference.id, this.tradeReferenceQuestionnaire)
            .subscribe(_ => {

                this.tradeReferenceQuestionnaire = this.customerService.tradeReferenceQuestionnaire;
                this.editTradeReferenceQuestionnaireEmitter.emit(true);

            });
    }

}
