﻿import { Component } from '@angular/core';
import { User } from '../shared/user';

@Component({
    selector: "users",
    templateUrl: "users.component.html",
    styleUrls: ["users.component.css"]
})
export class UsersComponent {

    public showUserList: boolean;
    public showDisableUser: boolean;
    public showEnableUser: boolean;

    user: User;

    ngOnInit(): void {
        this.clearUserView();
        this.showUserList = true;
    }

    clearUserView() {
        this.showUserList = false;
        this.showDisableUser = false;
        this.showEnableUser = false;
    }

    onShowUserList() {
        this.clearUserView();
        this.showUserList = true;
    }

    onUserSaved(isSaved: boolean) {
        this.clearUserView();
        this.showUserList = true;
    }

    onEnableUser(user: User) {
        this.user = user;
        this.clearUserView();
        this.showEnableUser = true;
    }

    onDisableUser(user: User) {
        this.user = user;
        this.clearUserView();
        this.showDisableUser = true;
    }
}