import { Component } from '@angular/core';

@Component({
    selector: 'crm-app',
    templateUrl: "app.component.html",
    styles: ["app.component.css"]
})
export class AppComponent {
  title = 'CRM App';
}
