﻿import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Load } from '../../shared/load';
import { LoadCustomer } from 'ClientApp/app/shared/loadCustomer';
import { Contact } from '../../shared/contact';
import { Customer } from '../../shared/customer';
import { concat } from 'rxjs';
import { isUndefined } from 'util';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { isEmpty } from 'rxjs/operators';

@Component({
    selector: "load-customers",
    templateUrl: "loadCustomers.component.html",
    styleUrls: ["loadCustomers.component.css"]
})
export class LoadCustomersComponent implements OnInit {

    @Input() load: Load;

    @Input() contacts: Contact[];

    private _pullLoadCustomer: boolean;

    @Input() set pullLoadCustomer(value: boolean) {
        this._pullLoadCustomer = value;
        
        if (this._pullLoadCustomer)
            this.addLoadCustomerEmitter.emit(this.loadCustomer);
    }
    get pullLoadCustomer(): boolean {
        return this._pullLoadCustomer;
    }

    customerName: string;
    @Input() customer: Customer;

    private _loadCustomers: LoadCustomer[];
    @Input() set loadCustomers(value: LoadCustomer[]) {
        this._loadCustomers = value;
        this.loadView();
    }

    get loadCustomers(): LoadCustomer[] {
        return this._loadCustomers;
    }

    @Output() customerNameEmitter = new EventEmitter<string>();
    @Output() openModalEmitter = new EventEmitter<string>();
    @Output() addLoadCustomerEmitter = new EventEmitter<LoadCustomer>();
    @Output() editLoadCustomerEmitter = new EventEmitter<LoadCustomer>();
    @Output() deleteLoadCustomerEmitter = new EventEmitter<LoadCustomer>();

    loadCustomer: LoadCustomer;

    showCustomers: boolean;
    showCustomer: boolean;
    showCustomerList: boolean;
    showDeleteCustomer: boolean;
    showEditCustomer: boolean;
    showCargo: boolean;
    showCustomerAdd: boolean;

    ngOnInit(): void {
        this.clearCargoView();

        this.loadView();
    }

    loadView() {

        //if (this.loadCustomers[0]) {
        //    if (this.loadCustomers.length > 1) {
                //this.showCustomerList = true;
                this.showCustomer = true;
                //this.showCustomerAdd = true;
        //    }
        //    else if (this.loadCustomers.length = 1) {
        //        this.loadCustomer = this.loadCustomers[0];
        //        this.customer = this.loadCustomer.customer;
        //        this.showCustomer = true;
        //        this.showCustomerAdd = true;
        //    } else {
        //        this.showCustomer = true;
        //    }
        //} else {
        //    this.showCustomer = true;
        //}

    }

    clearCargoView() {
        this.showCustomers = false;
        this.showCustomer = false;
        this.showCustomerList = false;
        this.showDeleteCustomer = false;
        this.showEditCustomer = false;
        this.showCargo = false;
        this.showCustomerAdd = false;
    }

    openCustomerAdd() {
        this.clearCargoView();
        this.showCustomer = true;
    }

    onOpenModal(customerName:string) {
        this.customerNameEmitter.emit(customerName);
        this.openModalEmitter.emit('CreateCustomer');
    }

    onLoadCustomerAdded(loadCustomer: LoadCustomer) {
        // add load customer
        this.addLoadCustomerEmitter.emit(loadCustomer);
        //this.clearCargoView();
        //this.showCustomerList = true;
    }

    onLoadCustomerEdited(loadCustomer: LoadCustomer) {
        // edit load customer
        this.editLoadCustomerEmitter.emit(loadCustomer);
        this.clearCargoView();
        this.showCustomerList = true;
    }

    onLoadCustomerCancel() {
        this.clearCargoView();
        this.showCustomerList = true;
    }

    onCargoOpen(loadCustomer: LoadCustomer) {
        this.loadCustomer = loadCustomer;
        this.clearCargoView();
        this.showCargo = true;
    }

    onEditLoadCustomer(loadCustomer: LoadCustomer) {
        this.loadCustomer = loadCustomer;
        this.customer = loadCustomer.customer;
        this.clearCargoView();
        this.showEditCustomer = true;
    }

    onDeleteLoadCustomer(loadCustomer: LoadCustomer) {
        this.loadCustomer = loadCustomer;
        this.clearCargoView();
        this.showDeleteCustomer = true;
    }

    onCancelCargo(close: boolean) {
        this.clearCargoView();
        this.showCustomerList = true;
    }

    onLoadCustomerDeleted(loadCustomer: LoadCustomer) {
        this.deleteLoadCustomerEmitter.emit(loadCustomer);
        this.clearCargoView();
        this.showCustomerList = true;
    }


}