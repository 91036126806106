﻿import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Cargo } from '../shared/cargo';
import { LoadCustomer } from '../shared/loadCustomer';
import { CargoService } from '../shared/cargoService';

@Component({
    selector: "cargo-delete",
    templateUrl: "cargoDelete.component.html",
    styleUrls: ["cargoDelete.component.css"]
})
export class CargoDeleteComponent {

    @Input() cargo: Cargo;
    @Output() cancelDeleteCargoEmitter = new EventEmitter<boolean>();
    @Output() deleteCargoEmitter = new EventEmitter<Cargo>();

    errorMessage: string = "";

    constructor(private cargoService: CargoService) { }

    onDeleteCargo() {
        this.cargoService.deleteCargo(this.cargo)
            .subscribe(s => {
                if (s) {
                    this.deleteCargoEmitter.emit(this.cargo);
                }
            });
    }

    onCancelDelete() {
        this.cancelDeleteCargoEmitter.emit(true);
    }
}