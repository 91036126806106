﻿import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: "navbar",
    templateUrl: "navbar.component.html",
    styleUrls: ["navbar.component.css"]
})
export class NavbarComponent {
    @Input() isCustomerSupplier: boolean;
    @Output() homeEmitter = new EventEmitter<boolean>();

    onShowHome() {
        this.homeEmitter.emit(true);
    }

    onToggleMenu() {

    }
}