﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TradeReference } from '../../shared/tradeReference';
import { CustomerService } from '../../shared/customerService';

@Component({
    selector: "trade-reference-delete",
    templateUrl: "tradeReferenceDelete.component.html",
    styleUrls: ["tradeReferenceDelete.component.css"]
})
export class TradeReferenceDeleteComponent {
    public errorMessage: string;
    @Input() tradeReference: TradeReference;

    @Output() deleteTradeReferenceEmitter = new EventEmitter<TradeReference>();

    constructor(private customerService: CustomerService) { }

    onDelete() {
        this.customerService.deleteTradeReference(this.tradeReference.id)
            .subscribe(_ => {
                this.deleteTradeReferenceEmitter.emit(this.customerService.tradeReference);
            });
    }
}