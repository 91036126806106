﻿import { Component, OnInit, Input } from '@angular/core';
import { Contact } from '../shared/contact';

@Component({
    selector: "contacts",
    templateUrl: "contacts.component.html",
    styleUrls: ["contacts.component.css"]
})
export class ContactsComponent implements OnInit {

    showContactList: boolean;
    showContact: boolean;
    showDeleteContact: boolean;
    showEditContact: boolean;

    contact: Contact;

    ngOnInit(): void {
        this.clearContactView();
        this.showContactList = true;
    }

    clearContactView() {
        this.showDeleteContact = false;
        this.showContact = false;
        this.showContactList = false;
        this.showEditContact = false;
    }

    showNewContact() {
        this.clearContactView();
        this.showContact = true;
    }

    onShowContactList() {
        this.clearContactView();
        this.showContactList = true;
    }

    onContactAdded(hideContact: boolean) {
        this.clearContactView();
        this.showContactList = hideContact;
    }

    onContactEdit(contact: Contact) {
        this.contact = contact;
        this.clearContactView();
        this.showEditContact = true;
    }

    onContactDelete(contact: Contact) {
        this.contact = contact;
        this.clearContactView();
        this.showDeleteContact = true;
    }
}