﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { LoadCustomer } from '../../shared/loadCustomer';
import { LoadService } from '../../shared/loadService';

@Component({
    selector: "load-customer-delete",
    templateUrl: "loadCustomerDelete.component.html",
    styleUrls: ["loadCustomerDelete.component.css"]
})
export class LoadCustomerDeleteComponent {

    @Input() loadCustomer: LoadCustomer;
    @Output() cancelDeleteCustomerEmitter = new EventEmitter<boolean>();
    @Output() deleteCustomerEmitter = new EventEmitter<LoadCustomer>();

    errorMessage: string = "";

    constructor(private loadService: LoadService) { }

    onDeleteLoadCustomer() {
        this.loadService.removeCustomerFromLoad(this.loadCustomer)
            .subscribe(s => {
                if (s) {
                    this.deleteCustomerEmitter.emit(this.loadCustomer);
                }
            });
    }

    onCancelDelete() {
        this.cancelDeleteCustomerEmitter.emit(true);
    }

}