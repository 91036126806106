﻿import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { isUndefined } from 'util';
import { Contact } from '../shared/contact';
import { ContactService } from '../shared/contactService';
import { Customer } from '../shared/customer';
import { CustomerService } from '../shared/customerService';
import { LoadCustomer } from '../shared/loadCustomer';
import { LoadService } from '../shared/loadService';
import { Supplier } from '../shared/supplier';
import { SupplierService } from '../shared/supplierService';
import { VehicleService } from '../shared/vehicleService';
import { AuthService } from '../auth/authService';
import { VehicleType } from '../shared/vehicleType';
import { forEach } from '@angular/router/src/utils/collection';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { CargoLocation } from '../shared/cargoLocation';
import { CargoEquipment } from '../shared/cargoEquipment';
import { CargoService } from '../shared/cargoService';
import { Cargo } from '../shared/cargo';
import { Location } from '../shared/location';
import { LocationService } from '../shared/locationService';
import { Equipment } from '../shared/equipment';
import { resolve } from 'url';

@Component({
    selector: "load-add",
    templateUrl: "loadAdd.component.html",
    styleUrls: ["loadAdd.component.css", "../app.component.css"]
})
export class LoadAddComponent implements OnInit {
    
    @Output() saveLoadEmitter = new EventEmitter<boolean>();
    @Output() cancelLoadEmitter = new EventEmitter<boolean>();
    isAdmin: boolean;

    pullLoadCustomer: boolean = false;

    supplierControl = new FormControl();
    suppliers: Supplier[] = [];
    filteredSuppliers: Observable<Supplier[]>;

    supplierName: string;
    supplier: Supplier;
    supplierContacts: Contact[] = [];
    supplierContact: Contact;
    supplierCMSOpsContact: Contact;

    vehicleTypes: VehicleType[] = [];

    customerError: string;

    customerName: string;
    customer: Customer;

    loadCustomer: LoadCustomer;
    loadCustomers: LoadCustomer[] = [];

    cargoLocations: CargoLocation[] = [];

    closeResult: string;
    modalHeader: string;

    isAddCustomer: boolean;
    isSearchCustomer: boolean;
    isAddSupplier: boolean;
    isSearchSupplier: boolean;
    isAddCargo: boolean;
    isAddEquipment: boolean;
    isSearchEquipment: boolean;

    public loadSupplier = {
        id: 0,
        supplierRate: 0,
        supplierInvoiceNumber: null,
        dateTimeSupplierInvoice: null,
        supplier: null,
        load: null,
        supplierContact: null,
        cmsContact: null
    }

    public load = {
        id: 0,
        purchaseOrderNumber: 0,
        salesOrderNumber: 0,
        commission: 0,
        git: 1000000,
        chepPallets: null,
        comments: null,
        loadStatus: null,
        isDetailVisible: false,
        driverName: null,
        driverCell: null,
        vehicleRegistration: null,
        vehicleType: null,
        isApproved: false
    }

    contacts: Contact[] = [];

    private _filterSuppliers(value: string): Supplier[] {
        const filterValue = this._normalizeValue(value);
        return this.suppliers.filter(s => this._normalizeValue(s.tradingName).includes(filterValue));
    }

    private _normalizeValue(value: string): string {
        return value.toLowerCase().replace(/\s/g, '');
    }

    constructor(private loadService: LoadService, private customerService: CustomerService, private contactService: ContactService, private modalService: NgbModal, private supplierService: SupplierService, private vehicleService: VehicleService, private cargoService: CargoService, private locationService: LocationService, private auth: AuthService) { }

    ngOnInit(): void {

        this.addLoadCustomer();
        this.loadSuppliers();
        this.loadVehicleTypes();
        // Add load
        //this.loadService.addLoad(this.load)
        //    .subscribe(s => {
        //        if (s) {
        //            this.load = this.loadService.load;
        this.loadOpsContacts();
        this.loadLoadCustomers();
            //    }
            //});

        this.checkAdminStatus();
    }

    onCargoLocationAdded(cargoLocation: CargoLocation) {
        this.cargoLocations.push(cargoLocation);
    }

    //onCargoEquipmentAdded(cargoEquipments: CargoEquipment[]) {
    //    this.cargoEquipments.push(cargoEquipments);
    //}

    checkAdminStatus() {

        this.auth.isAdminLoggedIn()
            .subscribe(data => {
                if (data)
                    this.isAdmin = this.auth.isAdmin;
            });

    }

    loadSuppliers() {

        this.supplierService.loadSuppliers()
            .subscribe(s => {
                if (s) {
                    this.suppliers = this.supplierService.suppliers;

                    this.filteredSuppliers = this.supplierControl.valueChanges
                        .pipe(
                            startWith(''),
                            map(value => typeof value === 'string' ? value : value),
                            map(name => name ? this._filterSuppliers(name) : [])
                        );

                }
            });

    }

    loadVehicleTypes() {

        this.vehicleService.loadVehicleTypes()
            .subscribe(s => {
                if (s) {
                    this.vehicleTypes = this.vehicleService.vehicleTypes;
                }
            });

    }

    // load vehicle types????
    //loadVehicles() {

    //    this.vehicleService.loadVehicles()
    //        .subscribe(s => {
    //            if (s) {
    //                this.vehicles = this.vehicleService.vehicles;

    //                this.filteredVehicles = this.vehicleControl.valueChanges
    //                    .pipe(
    //                        startWith(''),
    //                        map(value => typeof value === 'string' ? value : value),
    //                        map(name => name ? this._filterVehicles(name) : [])
    //                    );
    //            }
    //        });

    //}

    loadOpsContacts() {
        this.contactService.loadOpsContacts()
            .subscribe(s => {
                if (s) {
                    this.contacts = this.contactService.contacts;
                }
            });
    }

    loadLoadCustomers() {
    }

    clearModal() {
        this.isSearchCustomer = false;
        this.isAddCustomer = false;
        this.isSearchSupplier = false;
        this.isAddSupplier = false;
        this.isAddCargo = false;
        this.isAddEquipment = false;
        this.isSearchEquipment = false;
    }

    onCustomerSelected(customer: Customer) {
        this.customer = customer;
        this.modalService.dismissAll()
    }

    onSupplierSelected(supplier: Supplier) {
        this.supplier = supplier;

        if (!isUndefined(this.supplier)) {
            this.supplierControl.setValue(this.supplier.tradingName);

            if (this.suppliers.find(s => s == this.supplier) == null)
                this.suppliers.push(this.supplier);

            this.getSupplier(this.supplier.tradingName);
        }

        this.loadSupplierContacts();
        this.modalService.dismissAll()
    }

    loadSupplierContacts(){
        this.supplierService.loadContacts(this.supplier)
            .subscribe(s => {
                if (s) {
                    this.supplierContacts = this.supplierService.contacts;
                }
            });
    }
    

    onSupplierContactSelect(contactId: number) {
        this.supplierContact = this.supplierContacts.find(c => c.id == contactId);
    }

    onSupplierCMSContactSelect(contactId: number) {
        this.supplierCMSOpsContact = this.contacts.find(c => c.id == contactId);
    }

    onVehicleTypeSelect(vehicleTypeId: number) {
        this.load.vehicleType = this.vehicleTypes.find(v => v.id == vehicleTypeId);
    }


    onSetCustomerName(customerName: string) {
        this.customerName = customerName;
    }

    onOpenModal(content, modal: string) {

        if (modal == "SearchCustomer") {
            this.modalHeader = "Customer Search";
            this.clearModal();
            this.isSearchCustomer = true;
        }
        else if (modal == "CreateCustomer") {
            this.modalHeader = "Create Customer";
            this.clearModal();
            this.isAddCustomer = true;
        }
        else if (modal == "SearchSupplier") {
            this.modalHeader = "Supplier Search";
            this.clearModal();
            this.isSearchSupplier = true;
        }
        else if (modal == "CreateSupplier") {
            this.modalHeader = "Create Supplier";
            this.clearModal();
            this.isAddSupplier = true;
        }
        else if (modal == "SearchEquipment") {
            this.modalHeader = "Equipment Search";
            this.clearModal();
            this.isSearchEquipment = true;
        }
        else if (modal == "CreateEquipment") {
            this.modalHeader = "Add Equipment";
            this.clearModal();
            this.isAddEquipment = true;
        }

        this.open(content);
    }

    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    onLoadCustomerAdded(loadCustomer: LoadCustomer) {
        if (this.loadCustomers.find(l => l == loadCustomer) == null) {
            this.loadCustomers.push(loadCustomer);
            this.addLoad();
        }
    }

    onLoadCustomerDeleted(loadCustomer: LoadCustomer) {
        if (this.loadCustomers.find(l => l == loadCustomer)) {
            this.loadCustomers = this.loadCustomers.filter(l => l != loadCustomer);
        }
    }

    onLoadCustomerEdited(loadCustomer: LoadCustomer) {
        if (this.loadCustomers.find(l => l == loadCustomer)) {
            this.loadCustomers = this.loadCustomers.filter(l => l != loadCustomer);
            this.loadCustomers.push(loadCustomer);
        }
    }

    
    onSupplierChange() {
        // when a customer has been selected from the autocomplete list
        this.getSupplier(this.supplierControl.value);
    }

    onValidateSupplier(data) {
        // set customer based on selection
        this.getSupplier(this.supplierControl.value);
    }


    getSupplier(supplierName) {

        this.supplierName = supplierName;
        this.supplier = this.suppliers.find(c => c.tradingName == this.supplierName);

        if (!isUndefined(this.supplier)) {
            this.supplierService.loadContacts(this.supplier)
                .subscribe(s => {
                    if (s) {
                        this.supplierContacts = this.supplierService.contacts;
                    }
                });
        }
    }

    addSupplier() {

        this.loadSupplier.load = this.load;
        this.loadSupplier.supplier = this.supplier;
        this.loadSupplier.cmsContact = this.supplierCMSOpsContact;
        this.loadSupplier.supplierContact = this.supplierContact;

        var d = new Date('2018-01-01');
        var o = - d.getTimezoneOffset();

        if (this.loadSupplier.dateTimeSupplierInvoice == null) { this.loadSupplier.dateTimeSupplierInvoice = new Date(1900, 0, 1, 0, o, 0); } else { this.loadSupplier.dateTimeSupplierInvoice = new Date(this.loadSupplier.dateTimeSupplierInvoice.getTime() + o * 60000); }

        // add supplier to load
        if (this.supplier != null) {
            this.loadService.addSupplierToLoad(this.loadSupplier)
                .subscribe(x => {
                    if (x) {
                        this.loadSupplier = this.loadService.loadSupplier;

                        if (!this.customerError)
                            this.saveLoadEmitter.emit(true);
                    }
                    else {
                        if (!this.customerError)
                            this.saveLoadEmitter.emit(true);
                    }
                });
        }
        else {
            if (!this.customerError)
                this.saveLoadEmitter.emit(true);
        }

    }

    cargoEquipments: CargoEquipment[] = [];

    addLoadCustomers() {

        var counter = 0;
        var length = this.loadCustomers.length;

        return new Promise((resolve, reject) => {

            this.loadCustomers.forEach(loadCustomer => {

                counter++;

                this.loadService.addLoadCustomer(loadCustomer, this.load.id)
                    .subscribe(s => {
                        if (s) {
                            var loadCustomerId = this.loadService.loadCustomer.id;
                            loadCustomer.id = loadCustomerId;

                            this.addCargo(loadCustomerId, loadCustomer).then(x => { if (counter == length) resolve(); });

                        }
                        else {
                            this.customerError = "Error adding customer to the load.";
                            if (counter == length) resolve();
                        }
                    });

            });
            
        });

    }

    addCargo(loadCustomerId: number, loadCustomer: LoadCustomer) {

        var counter = 0;
        var length = loadCustomer.cargos.length;

        return new Promise((resolve, reject) => {

            loadCustomer.cargos.forEach(cargo => {

                var d = new Date('2018-01-01');
                var o = - d.getTimezoneOffset();

                if (cargo.dateTimeCollectionCustomer == null) { cargo.dateTimeCollectionCustomer = new Date(1900, 0, 1, 0, o, 0); } else { cargo.dateTimeCollectionCustomer = new Date(cargo.dateTimeCollectionCustomer.getTime() + o * 60000); }
                if (cargo.dateTimeCollectionSupplier == null) { cargo.dateTimeCollectionSupplier = new Date(1900, 0, 1, 0, o, 0); } else { cargo.dateTimeCollectionSupplier = new Date(cargo.dateTimeCollectionSupplier.getTime() + o * 60000); }
                if (cargo.dateTimeDeliveryCustomer == null) { cargo.dateTimeDeliveryCustomer = new Date(1900, 0, 1, 0, o, 0); } else { cargo.dateTimeDeliveryCustomer = new Date(cargo.dateTimeDeliveryCustomer.getTime() + o * 60000); }
                if (cargo.dateTimeDeliverySupplier == null) { cargo.dateTimeDeliverySupplier = new Date(1900, 0, 1, 0, o, 0); } else { cargo.dateTimeDeliverySupplier = new Date(cargo.dateTimeDeliverySupplier.getTime() + o * 60000); }
                if (cargo.dateTimeBooking == null) { cargo.dateTimeBooking = new Date(1900, 0, 1, 0, o, 0); } else { cargo.dateTimeBooking = new Date(cargo.dateTimeBooking.getTime() + o * 60000); }

                counter++;
                var key: number = cargo.key;

                this.cargoService.addCargo(cargo, loadCustomerId)
                    .subscribe(s => {

                        if (s) {

                            var eqms = cargo.equipments;
                            cargo = this.cargoService.cargo;

                            this.cargoLocations.filter(cl => cl.cargo.key == key).forEach(cl => {

                                if (cl.type == 'Origin') {

                                    this.addCargoOrigin(cargo, cl.location);

                                }

                                if (cl.type == 'Destination') {

                                    this.addCargoDestination(cargo, cl.location);

                                }

                            });

                            if (eqms.filter(e => e.isChecked == true).length > 0) {

                                this.addCargoEquipment(cargo, eqms.filter(e => e.isChecked == true));

                            }

                            if (counter == length) resolve();
                        }
                        else {
                            this.customerError = "Cargo information missing!";
                            if (counter == length) resolve();
                        }

                    });

            });
        });

    }

    addCargoEquipment(cargo: Cargo, equipments: Equipment[]) {
        // add each equipment
        equipments.forEach(e => {

            if (e.isChecked) {

                this.cargoService.addCargoEquipment(cargo.id, e.id)
                .subscribe(x => {
                    console.log("Equipment added");
                });

            }

        });
    }

    addCargoOrigin(cargo: Cargo, location: Location) {

        this.locationService.addCargoOrigin(cargo, location)
            .subscribe(x => {

                console.log("Origin added");

            });

    }

    addCargoDestination(cargo: Cargo, location: Location) {

        this.locationService.addCargoDestination(cargo, location)
            .subscribe(x => {

                console.log("Destination added");

            });

    }

    onSubmitLoad() {
        
        this.customerError = "";

        if (this.loadCustomers.length != 0) {

            if (this.loadCustomers[0].customer.id != 0) {

                this.addLoad();

            }
            else {
                this.customerError = "A customer is required. Please add a new customer.";
            }

        }
        else {
            this.customerError = "A customer is required. Please add a new customer.";
        }
    }

    addLoad() {

        // This is to be run when the load is ready to be created or saved
        // Add load
        if (this.load.id == 0) {
            this.loadService.addLoad(this.load)
                .subscribe(async s => {
                    if (s) {
                        this.load = this.loadService.load;
                        this.addLoadCustomers().then(res => this.addSupplier());
                    }
                });
        }
        else {
            this.loadService.updateLoad(this.load)
                .subscribe(async s => {
                    if (s) {
                        this.load = this.loadService.load;
                        this.addLoadCustomers().then(res => this.addSupplier());
                    }
                });
        }

    }

    onCancelLoad() {

        //this.loadService.deleteLoad(this.load.id)
        //    .subscribe(s => {
        //        // load deleted
        this.cancelLoadEmitter.emit(true);
            //});

    }

    onApproveLoad() {

        this.loadService.approveLoad(this.load.id)
            .subscribe(s => {
                // load approved
                this.load = this.loadService.load;
                this.saveLoadEmitter.emit(s);
            });

    }

    onLoadCustomerCancel(cancel: boolean) {
        this.pullLoadCustomer = false;

    }

    selected = new FormControl(0);

    addLoadCustomer() {

        var loadCustomer = {
            id: 0,
            purchaseOrderNumber: null,
            customerRate: 0,
            commission: 0,
            customerInvoiceNumber: null,
            orderReference: null,
            dateTimeCustomerInvoice: null,
            customer: {
                id: 0,
                customerAccountNo: null,
                registeredName: null,
                tradingName: 'Customer',
                companyRegisteredNo: null,
                vatRegisteredNo: null,
                typeOfBusiness: null,
                dateTimeCommencedBusiness: null,
                companyType: null,
                addressLine1: null,
                addressLine2: null,
                addressLine3: null,
                addressLine4: null,
                addressLine5: null,
                city: null,
                postalAddressLine1: null,
                postalAddressLine2: null,
                postalAddressLine3: null,
                postalAddressLine4: null,
                postalAddressLine5: null,
                phone1: null,
                phone2: null,
                fax: null,
                email: null,
                bank: null,
                branchCode: null,
                accountNo: null,
                auditors: null,
                dateTimeSubmissionClosing: null,
                creditRequired: 0,
                associatedCompanies: null,
                customerStatus: null,
                dateTimeCreated: new Date(),
                isCreditApplicationForms: false,
                isDirectorIDsReceived: false
            },
            load: null,
            customerContact: null,
            cmsContact: null,
            cargos: null
        }

        this.loadCustomers.push(loadCustomer);
        this.selected.setValue(this.loadCustomers.length - 1);
    }

    removeLoadCustomer(index: number) {
        this.loadCustomers.splice(index, 1);
    }

}