﻿import { Component, Input } from '@angular/core';
import { Customer } from '../../shared/customer';
import { CustomerContact } from '../../shared/customerContact';

@Component({
    selector: "customer-contacts",
    templateUrl: "customerContacts.component.html",
    styleUrls: ["customerContacts.component.css"]
})
export class CustomerContactsComponent {
    @Input() customer: Customer;

    public showCustomerContactList: boolean;
    public showCustomerContact: boolean;
    public showDeleteCustomerContact: boolean;
    public showEditCustomerContact: boolean;
    public showEditCustomerContactQuestionnaire: boolean;

    public customerContact: CustomerContact;

    ngOnInit(): void {
        this.clearCustomerContactView();
        this.showCustomerContactList = true;
    }

    clearCustomerContactView() {
        this.showCustomerContactList = false;
        this.showCustomerContact = false;
        this.showDeleteCustomerContact = false;
        this.showEditCustomerContact = false;
        this.showEditCustomerContactQuestionnaire = false;
    }

    showNewCustomerContact() {
        this.clearCustomerContactView();
        this.showCustomerContact = true;
    }

    onShowCustomerContactList() {
        this.clearCustomerContactView();
        this.showCustomerContactList = true;
    }


    onCustomerContactAdded(customerContact: CustomerContact) {
        this.clearCustomerContactView();
        this.showCustomerContactList = true;
    }

    onCustomerContactEdit(customerContact: CustomerContact) {
        this.customerContact = customerContact;
        this.clearCustomerContactView();
        this.showEditCustomerContact = true;
    }

    onCustomerContactQuestionnaireEdit(customerContact: CustomerContact) {
        this.customerContact = customerContact;
        this.clearCustomerContactView();
        this.showEditCustomerContactQuestionnaire = true;
    }

    onCustomerContactDelete(customerContact: CustomerContact) {
        this.customerContact = customerContact;
        this.clearCustomerContactView();
        this.showDeleteCustomerContact = true;
    }

    onRefreshCustomerContactList(hideCustomerContact: boolean) {
        this.clearCustomerContactView();
        this.showCustomerContactList = hideCustomerContact;
    }
}