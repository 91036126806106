﻿import { Component, Output, EventEmitter } from '@angular/core';
import { Supplier } from '../shared/supplier';
import { SupplierService } from '../shared/supplierService';

@Component({
    selector: "supplier-search",
    templateUrl: "supplierSearch.component.html",
    styleUrls: ["supplierSearch.component.css", "../app.component.css"]
})
export class SupplierSearchComponent {

    @Output() supplierEmitter = new EventEmitter<Supplier>();

    searchTerm: string;
    suppliers: Supplier[] = [];
    errorMessage: string;

    constructor(private supplierService: SupplierService) { }

    onSearch() {
        this.errorMessage = "";

        this.supplierService.searchSuppliers(this.searchTerm)
            .subscribe(s => {
                if (s) {
                    this.suppliers = this.supplierService.suppliers;

                    if (this.suppliers.length == 0)
                        this.errorMessage = "No suppliers found";
                }
                else {
                    this.errorMessage = "No suppliers found";
                }
            });
    }

    onSelectSupplier(supplier: Supplier) {
        this.supplierEmitter.emit(supplier);
    }

}