﻿import { Component, Input, EventEmitter, Output } from '@angular/core';
import { Supplier } from '../shared/supplier';
import { CompanyType } from '../shared/companyType';
import { SupplierService } from '../shared/supplierService';
import { HttpClient } from '@angular/common/http';
import { isUndefined } from 'util';
import { saveAs } from "file-saver";

@Component({
    selector: "supplier-edit",
    templateUrl: "supplierEdit.component.html",
    styleUrls: ["supplierEdit.component.css", "../app.component.css"]
})
export class SupplierEditComponent {
    isLoading: boolean;
    public uploadStatus: string;
    public message: string;

    @Input() supplier: Supplier;
    companyTypes: CompanyType[] = [];
    companyTypeId: number;

    dateTimeCommencedBusiness: string;

    errorMessage: string;

    @Output() editSupplierEmitter = new EventEmitter<boolean>();

    constructor(private supplierService: SupplierService, private http: HttpClient) { }

    ngOnInit(): void {
        this.loadDates();
        this.loadCompanyTypes();
        this.getSupplierAccountNo();
    }

    loadDates() {
        if (this.supplier.dateTimeCommencedBusiness != null)
            this.dateTimeCommencedBusiness = this.supplier.dateTimeCommencedBusiness.toString().substring(0, 10);

    }

    loadCompanyType() {
        this.supplierService.loadCompanyType(this.supplier)
            .subscribe(c => {
                if (c) {
                    if (!isUndefined(this.supplierService.companyType) && this.supplierService.companyType != null)
                        this.companyTypeId = this.supplierService.companyType.id;
                }
            });
    }

    getSupplierAccountNo() {

        if (this.supplier.supplierAccountNo == null) {
            this.supplier.supplierAccountNo = this.supplier.tradingName.substring(0, 3);
            if (this.supplier.id < 10) {
                this.supplier.supplierAccountNo += "00" + this.supplier.id;
            }
            else if (this.supplier.id < 100) {
                this.supplier.supplierAccountNo += "0" + this.supplier.id;
            }
            else {
                this.supplier.supplierAccountNo += this.supplier.id;
            }
        }

    }

    loadCompanyTypes() {
        this.supplierService.loadCompanyTypes()
            .subscribe(c => {
                if (c) {
                    this.companyTypes = this.supplierService.companyTypes;
                    this.loadCompanyType();

                }
            });
    }

    onCompanyTypeSelect(id) {
        this.supplier.companyType = this.companyTypes.find(t => t.id == id);
    }

    onSubmit() {
        this.supplierService.editSupplier(this.supplier, this.dateTimeCommencedBusiness)
            .subscribe(s => {
                this.supplier = this.supplierService.supplier;
                this.editSupplierEmitter.emit(true);
            });
    }

    onPrintTransportAgreement() {
        this.supplierService.printTransportAgreement()
            .subscribe(
                (res) => {

                    var file = new File([res], "TransportAgreement.pdf", { type: "application/pdf" });
                    saveAs(file);

                },
                err => {
                    alert("Unable to find TransportAgreement.pdf. Please ensure that one is uploaded.");
                });
    }
}