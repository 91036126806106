﻿import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { TradeReference } from '../../shared/tradeReference';
import { CustomerService } from '../../shared/customerService';
import { Customer } from '../../shared/customer';

@Component({
    selector: "trade-reference-add",
    templateUrl: "tradeReferenceAdd.component.html",
    styleUrls: ["tradeReferenceAdd.component.css"]
})
export class TradeReferenceAddComponent implements OnInit {

    @Input() customer: Customer;
    public errorMessage: string;

    dropdownSettings = {};

    public tradeReference = {
        id: 0,
        companyName: null,
        address: null,
        telephoneNo: null,
        contactPerson: null,
        isActive: true
    }

    @Output() addTradeReferenceEmitter = new EventEmitter<TradeReference>();

    constructor(private customerService: CustomerService) { }

    ngOnInit(): void {

    }

    onSubmit() {
        this.customerService.addTradeReference(this.customer.id, this.tradeReference)
            .subscribe(_ => {

                this.tradeReference = this.customerService.tradeReference;
                this.addTradeReferenceEmitter.emit(this.customerService.tradeReference);

            });
    }
}