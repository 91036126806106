﻿import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { Contact } from '../shared/contact';
import { ContactService } from '../shared/contactService';

@Component({
    selector: "edit-contact",
    templateUrl: "contactEdit.component.html",
    styleUrls: ["contactEdit.component.css"]
})
export class ContactEditComponent {

    errorMessage: string;
    @Input() contact: Contact;
    @Output() toggle = new EventEmitter<boolean>();

    constructor(private contactService: ContactService) { }

    onSubmit() {
       
    }
}