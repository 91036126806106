﻿import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { Supplier } from '../shared/supplier';
import { SupplierService } from '../shared/supplierService';

@Component({
    selector: "supplier-add",
    templateUrl: "supplierAdd.component.html",
    styleUrls: ["supplierAdd.component.css", "../app.component.css"]
})
export class SupplierAddComponent implements OnInit {

    @Input() supplierName: string;

    public errorMessage: string;

    public supplier = {
        id: 0,
        supplierAccountNo: null,
        registeredName: null,
        tradingName: null,
        companyRegisteredNo: null,
        vatRegisteredNo: null,
        typeOfBusiness: null,
        dateTimeCommencedBusiness: null,
        companyType: null,
        addressLine1: null,
        addressLine2: null,
        addressLine3: null,
        addressLine4: null,
        addressLine5: null,
        city: null,
        postalAddressLine1: null,
        postalAddressLine2: null,
        postalAddressLine3: null,
        postalAddressLine4: null,
        postalAddressLine5: null,
        phone1: null,
        phone2: null,
        fax: null,
        email: null,
        bank: null,
        branchCode: null,
        accountNo: null,
        supplierStatus: null,
        dateTimeCreated: new Date(),
        isTransportAgreement: false,
        isCompanyRegistrationDocuments: false,
        isVATCertificate: false,
        isCompanyLetterhead: false,
        isProofOfBankAccount: false,
        isGoodsInTransitPolicy: false,
        isCompanyCreditApplicationForms: false,
        isBBBEECertificate: false,
        isCompanyStandardTradingTermsAndConditions: false
    }

    public contact = {
        id: 0,
        contactName: null,
        email: null,
        cell: null,
        isActive: true,
        isInternal: false
    }

    @Output() addSupplierEmitter = new EventEmitter<Supplier>();

    constructor(private supplierService: SupplierService) { }

    ngOnInit(): void {
        this.supplier.tradingName = this.supplierName;
    }

    onSubmit() {
        this.supplierService.addSupplier(this.supplier, this.contact)
            .subscribe(_ => {
                this.addSupplierEmitter.emit(this.supplierService.supplier);
            }, err => {
                this.errorMessage = 'Failed to add supplier.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error;
                }
            });
    }
}