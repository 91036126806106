﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Contact } from '../shared/contact';
import { ContactService } from '../shared/contactService';

@Component({
    selector: "opsContact-edit",
    templateUrl: "opsContactEdit.component.html",
    styleUrls: ["opsContactEdit.component.css"]
})
export class OpsContactEditComponent {

    @Input() opsContact: Contact;

    errorMessage: string;

    @Output() saveOpsContactEmitter = new EventEmitter<boolean>();

    constructor(private opsContactService: ContactService) { }     

    onSubmit() {

        this.opsContactService.editOpsContact(this.opsContact)
            .subscribe(_ => {
                this.saveOpsContactEmitter.emit(true);
            }, err => {
                this.errorMessage = 'Failed to add opsContact.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error + '. Please make sure the contact details are unique.';
                }
            });

    }

}