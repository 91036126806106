﻿import { Component } from '@angular/core';
import { UserService } from '../shared/userService';
import { Router } from '@angular/router';

@Component({
    selector: "forgot-password",
    templateUrl: "forgotPassword.component.html",
    styleUrls: ["forgotPassword.component.css"]
})
export class ForgotPasswordComponent {

    errorMessage: string;

    public user = {
        id: null,
        firstName: "",
        surname: "",
        email: "",
        username: "",
        password: "",
        loginKey: "",
        isEnabled: false
    };

    constructor(private userService: UserService, private router: Router) {
    }

    onSubmitPasswordResetRequest() {
        this.userService.requestPasswordReset(this.user)
            .subscribe(data => {
                alert("Email has been sent. Click the link in the email to reset your password.");
            }, err => {
                if (err.error.length <= 500) {
                    this.errorMessage = "Failed to request password change. " + err.error;
                }
                else {
                    this.errorMessage = "Failed to request password change. Please try again.";
                }
            });
    }

    onLogin() {
        this.router.navigate(['Login']);
    }

    onRegister() {
        this.router.navigate(['Register']);
    }
} 