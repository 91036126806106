﻿import { Component } from '@angular/core';
import { Contact } from '../shared/contact';

@Component({
    selector: "ops-contacts",
    templateUrl: "opsContacts.component.html",
    styleUrls: ["opsContacts.component.css"]
})
export class OpsContactsComponent {

    public showOpsContactList: boolean;
    public showAddOpsContact: boolean;
    public showOpsContact: boolean;
    public showDeleteOpsContact: boolean;
    public showEditOpsContact: boolean;

    opsContact: Contact;

    ngOnInit(): void {
        this.clearOpsContactView();
        this.showOpsContactList = true;
    }

    clearOpsContactView() {
        this.showOpsContactList = false;
        this.showOpsContact = false;
        this.showDeleteOpsContact = false;
        this.showEditOpsContact = false;
        this.showAddOpsContact = false;
    }

    onShowNewOpsContact() {
        this.clearOpsContactView();
        this.showAddOpsContact = true;
    }

    onShowOpsContactList() {
        this.clearOpsContactView();
        this.showOpsContactList = true;
    }

    onOpsContactSaved(isSaved: boolean) {
        this.clearOpsContactView();
        this.showOpsContactList = true;
    }

    onEditOpsContact(opsContact: Contact) {
        this.opsContact = opsContact;
        this.clearOpsContactView();
        this.showEditOpsContact = true;
    }

    onDeleteOpsContact(opsContact: Contact) {
        this.opsContact = opsContact;
        this.clearOpsContactView();
        this.showDeleteOpsContact = true;
    }
}