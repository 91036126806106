﻿import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { VehicleType } from '../shared/vehicleType';
import { VehicleService } from '../shared/vehicleService';

@Component({
    selector: "vehicleType-edit",
    templateUrl: "vehicleTypeEdit.component.html",
    styleUrls: ["vehicleTypeEdit.component.css"]
})
export class VehicleTypeEditComponent implements OnInit {

    @Input() vehicleType: VehicleType;

    errorMessage: string;

    dropdownSettings = {};


    @Output() editVehicleTypeEmitter = new EventEmitter<boolean>();

    constructor(private vehicleService: VehicleService) { }

    ngOnInit(): void {
        this.configureDropDownSettings();
    }

    configureDropDownSettings() {

        this.dropdownSettings = {
            singleSelection: true,
            idField: 'id',
            textField: 'type',
            itemsShowLimit: 4,
            allowSearchFilter: false
        };


    }

    onSubmit() {
        this.vehicleService.updateVehicleType(this.vehicleType)
            .subscribe(_ => {
                this.vehicleType = this.vehicleService.vehicleType;
                this.editVehicleTypeEmitter.emit(true);
            }, err => {
                this.errorMessage = 'Failed to edit vehicleType.';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error + '. Please make sure the vehicle types are unique.';
                }
            });
    }

}