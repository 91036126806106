﻿import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CustomerService } from '../../shared/customerService';
import { CompanyDirector } from '../../shared/companyDirector';

@Component({
    selector: "company-director-delete",
    templateUrl: "companyDirectorDelete.component.html",
    styleUrls: ["companyDirectorDelete.component.css"]
})
export class CompanyDirectorDeleteComponent {
    public errorMessage: string;
    @Input() companyDirector: CompanyDirector;

    @Output() deleteCompanyDirectorEmitter = new EventEmitter<CompanyDirector>();

    constructor(private customerService: CustomerService) { }

    onDelete() {
        this.customerService.deleteCompanyDirector(this.companyDirector.id)
            .subscribe(_ => {
                this.deleteCompanyDirectorEmitter.emit(this.customerService.companyDirector);
            });
    }
}