﻿import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { Customer } from '../shared/customer';
import { CustomerService } from '../shared/customerService';

@Component({
    selector: "customer-list",
    templateUrl: "customerList.component.html",
    styleUrls: ["customerList.component.css"]
})
export class CustomerListComponent implements OnInit, OnDestroy {

    dtOptions: DataTables.Settings = {};
    dtTrigger: Subject<any> = new Subject();

    public customers: Customer[] = [];

    @Output() showEditCustomerEmitter = new EventEmitter<Customer>();
    @Output() showDeleteCustomerEmitter = new EventEmitter<Customer>();

    constructor(private customerService: CustomerService) { }

    ngOnDestroy(): void {
        this.dtTrigger.unsubscribe();
    }

    ngOnInit(): void {
        this.loadCustomers();
    }

    loadCustomers() {

        this.customerService.loadCustomers()
            .subscribe(success => {
                if (success) {

                    this.dtOptions = {
                        pagingType: 'full_numbers',
                        pageLength: 10
                    };

                    this.customers = this.customerService.customers;
                    this.dtTrigger.next();
                }
            });

    }

    onEditCustomer(customer: Customer) {
        this.showEditCustomerEmitter.emit(customer);
    }

}