﻿import { Component, Output, EventEmitter } from '@angular/core';
import { Contact } from '../shared/contact';
import { ContactService } from '../shared/contactService';

@Component({
    selector: "opsContact-add",
    templateUrl: "opsContactAdd.component.html",
    styleUrls: ["opsContactAdd.component.css"]
})
export class OpsContactAddComponent {

    public errorMessage: string;

    public opsContact = {
        id: 0,
        contactName: null,
        email: null,
        cell: null,
        isActive: true,
        isInternal: true
    };

    @Output() saveOpsContactEmitter = new EventEmitter<Contact>();

    constructor(private opsContactService: ContactService) { }

    onSubmit() {
        this.opsContactService.addOpsContact(this.opsContact)
            .subscribe(_ => {
                this.saveOpsContactEmitter.emit(this.opsContactService.contact);
            }, err => {
                this.errorMessage = 'Failed to save new contact';
                if (typeof err.error !== 'string') {
                    Object.values(err.error).forEach(val => {
                        this.errorMessage = this.errorMessage + ' ' + val[0];
                    });
                }
                else {
                    this.errorMessage = err.error + '. Please make sure the contact details are unique.';
                }
            });
    }
}