﻿import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Cargo } from './cargo';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Equipment } from './equipment';
import { CargoEquipment } from './cargoEquipment';
import { ContainerType } from './containerType';
import { LoadCustomer } from './loadCustomer';
import { Location } from './location';

@Injectable()
export class CargoService {

    public cargo: Cargo;
    public cargoEquipment: Equipment;
    public containerType: ContainerType;
    public origin: Location;
    public destination: Location;

    constructor(private http: HttpClient) { }

    public addCargo(cargo: Cargo, loadCustomerId: number): Observable<boolean> {

        return this.http.post("/api/cargo/" + loadCustomerId, cargo)
            .pipe(map((data: any) => {
                this.cargo = data;
                return (this.cargo != null);
            }), catchError(err => { return of(false); }));

    }

    public updateCargo(cargo: Cargo, loadCustomerId: number): Observable<boolean> {

        return this.http.put("/api/cargo/" + loadCustomerId,  cargo)
            .pipe(map((data: any) => {
                this.cargo = data;
                return (this.cargo != null);
            }), catchError(err => { return of(false); }));

    }

    public clearCargoEquipment(cargoId: number): Observable<boolean> {

        return this.http.delete("/api/cargo/" + cargoId + "/ClearEquipment/")
            .pipe(map((data: any) => {
                return data.status;
            }));


    }

    public addCargoEquipment(cargoId: number, equipmentId: number): Observable<boolean> {

        return this.http.post("/api/cargo/" + cargoId + "/equipment/" + equipmentId, null)
            .pipe(map((data: any) => {
                this.cargoEquipment = data;
                return (this.cargoEquipment != null);
            }));

    }

    public getContainerType(cargoId: number): Observable<boolean> {

        return this.http.get("/api/cargo/GetContainerType/" + cargoId)
            .pipe(map((data: any) => {
                this.containerType = data;
                return (this.containerType != null);
            }));

    }

    public deleteCargo(cargo: Cargo): Observable<boolean> {
        return this.http.delete("/api/cargo/" + cargo.id)
            .pipe(map((data: any) => {
                return data.status;
            }));
    }

    public getOrigin(cargoId: number): Observable<boolean> {

        return this.http.get("/api/cargo/GetOrigin/" + cargoId)
            .pipe(map((data: any) => {
                this.origin = data;
                return (this.origin != null);
            }));

    }

    public getDestination(cargoId: number): Observable<boolean> {

        return this.http.get("/api/cargo/GetDestination/" + cargoId)
            .pipe(map((data: any) => {
                this.destination = data;
                return (this.destination != null);
            }));

    }

}