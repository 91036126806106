﻿import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from './user';
import { Role } from './role';

@Injectable()
export class UserService {
    
    public user: User;
    public users: User[] = [];
    public roles: Role[] = [];

    constructor(private http: HttpClient) { }

    public register(user: User, role: string): Observable<boolean> {
        const url = '/api/users/' + role;

        return this.http
            .post(url, user)
            .pipe(map((data: any) => {
                return true;
            }));
    }

    public loadRoles(): Observable<boolean> {

        return this.http.get("/api/users/GetRoles")
            .pipe(map((data: any[]) => {
                this.roles = data;
                return true;
            }));
    }

    public loadUsers(): Observable<boolean> {

        return this.http.get("/api/users")
            .pipe(map((data: any[]) => {
                this.users = data;
                return true;
            }));
    }

   public loadRolesByUser(user: User): Observable<boolean> {

        return this.http.get("/api/users/GetRoles/" + user.id)
            .pipe(map((data: any[]) => {
                this.roles = data;
                return true;
            }));
    }

    public requestPasswordReset(user: User): any {
        return this.http.post("/Account/CreateResetPasswordToken", user)
            .pipe(map((data: any) => {
                return true;
            }));
    }

    public resetPassword(newPassword): any {

        var options = { headers: new HttpHeaders().set('Content-Type', 'application/json') };

        return this.http.put("/api/users/resetpassword", '"' + newPassword + '"', options)
            .pipe(map((data: any) => {
                this.user = data;
                return true;
            }));

    }

    public deleteUser(user: User): Observable<boolean> {

        const url = '/api/users/' + user.id;

        return this.http
            .delete<User>(url)
            .pipe(
                map(_ => {
                    return true;
                }));

    }

    public enableUser(user: User): Observable<boolean> {

        const url = '/api/users/enableUser/' + user.id;

        return this.http
            .put(url, null)
            .pipe(
                map(_ => {
                    return true;
                }));

    }

    public disableUser(user: User): Observable<boolean> {

        const url = '/api/users/disableUser/' + user.id;

        return this.http
            .put(url, null)
            .pipe(
                map(_ => {
                    return true;
                }));

    }

    public addRolesToUser(user: User, roles: string[]): Observable<boolean> {

        return this.http.put("/api/users/addroles/" + roles.toString(), user)
            .pipe(map((data: any[]) => {
                return true;
            }));
    }

    public removeRolesFromUser(user: User, roles: string[]): Observable<boolean> {

        return this.http.put("/api/users/removeroles/" + roles.toString(), user)
            .pipe(map((data: any[]) => {
                return true;
            }));

    }

    public updateUser(user: User): Observable<boolean> {

        return this.http.put("/api/users", user)
            .pipe(map((data: any[]) => {
                return true;
            }));
    }
}
