﻿import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/authService';
import { Customer } from '../shared/customer';
import { CustomerService } from '../shared/customerService';

@Component({
    selector: "crm-app-home",
    templateUrl: "home.component.html",
    styleUrls: ["home.component.css"]
})
export class HomeComponent implements OnInit {

    isCustomerSupplier: boolean = false;
    showHome: boolean;
    showCustomers: boolean;
    showSuppliers: boolean;
    showUsers: boolean;
    showOpsContacts: boolean;
    showEquipment: boolean;
    showLoads: boolean;
    showDocuments: boolean;
    showVehicleTypes: boolean;
    showCompanyDetail: boolean;
    showOrderNumberSeed: boolean;

    customer: Customer;

    constructor(private authService: AuthService, private customerService: CustomerService) { }

    ngOnInit(): void {
        this.checkCustomerSupplierStatus();
        this.clearWindow();
        this.showHome = true;
    }

    onLogout() {
        this.authService.logout();
    }

    onShowHome(show:boolean) {
        this.clearWindow();
        this.showHome = show;
    }

    onShowCustomers(show: boolean) {
        this.clearWindow();
        this.showCustomers = show;
    }

    onShowSuppliers(show: boolean) {
        this.clearWindow();
        this.showSuppliers = show;
    }

    onShowUsers(show: boolean) {
        this.clearWindow();
        this.showUsers = show;
    }

    onShowOpsContacts(show: boolean) {
        this.clearWindow();
        this.showOpsContacts = show;
    }

    onShowEquipment(show: boolean) {
        this.clearWindow();
        this.showEquipment = show;
    }

    onShowLoads(show: boolean) {
        this.clearWindow();
        this.showLoads = show;
    }

    onShowVehicleType(show: boolean) {
        this.clearWindow();
        this.showVehicleTypes = show;
    }

    onShowDocuments(show: boolean) {
        this.clearWindow();
        this.showDocuments = show;
    }

    onShowCompanyDetail(show: boolean) {
        this.clearWindow();
        this.showCompanyDetail = show;
    }

    onShowOrderNumberSeed(show: boolean) {
        this.clearWindow();
        this.showOrderNumberSeed = show;
    }

    clearWindow() {
        this.showHome = false;
        this.showCustomers = false;
        this.showSuppliers = false;
        this.showUsers = false;
        this.showOpsContacts = false;
        this.showEquipment = false;
        this.showLoads = false;
        this.showVehicleTypes = false;
        this.showDocuments = false;
        this.showCompanyDetail = false;
        this.showOrderNumberSeed = false;
    }

    checkCustomerSupplierStatus() {

        this.authService.isCustomerSupplierLoggedIn()
            .subscribe(data => {
                if (data) {
                    this.isCustomerSupplier = this.authService.isCustomerSupplier;

                    this.customerService.getCustomer()
                        .subscribe(data => {
                            if (data) {
                                this.customer = this.customerService.customer;
                            }
                        });
                }
            });

    }
}