﻿import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Cargo } from '../shared/cargo';
import { LoadService } from '../shared/loadService';
import { Location } from '../shared/location';
import { SupplierService } from '../shared/supplierService';
import { EquipmentService } from '../shared/equipmentService';
import { CargoService } from '../shared/cargoService';
import { LocationService } from '../shared/locationService';
import { ContainerType } from '../shared/containerType';
import { Equipment } from '../shared/equipment';
import { CargoLocation } from '../shared/cargoLocation';
import { LoadCustomer } from '../shared/loadCustomer';
import { isUndefined } from 'util';

@Component({
    selector: "cargo-edit",
    templateUrl: "cargoEdit.component.html",
    styleUrls: ["cargoEdit.component.css"]
})
export class CargoEditComponent implements OnInit {

    @Input() loadCustomer: LoadCustomer;
    @Output() addCargoEmitter = new EventEmitter<Cargo>();
    @Output() addCargoLocationEmitter = new EventEmitter<CargoLocation>();
    @Output() cancelCargoEmitter = new EventEmitter<boolean>();

    @Input() cargo: Cargo;
    @Input() origin: Location;
    @Input() destination: Location;

    equipmentDropdownSettings = {};
    containerTypeDropdownSettings = {};
    containerTypes: ContainerType[] = [];
    equipments: Equipment[] = [];

    selectedEquipment: Equipment[];
    selectedItemsContainerTypes: ContainerType[];
    selectedItemsContainerType: ContainerType;

    public errorMessage: string;

    constructor(private supplierService: SupplierService, private equipmentService: EquipmentService, private cargoService: CargoService, private locationService: LocationService) { }

    ngOnInit(): void {

        this.loadSelectedContainerType();
        this.loadSelectedEquipment();
        this.loadContainerTypes();
        this.loadEquipment();
        this.configureDropDownSettings();

    }

    configureDropDownSettings() {

        this.equipmentDropdownSettings = {
            singleSelection: false,
            idField: 'id',
            textField: 'description',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: false
        };

        this.containerTypeDropdownSettings = {
            singleSelection: true,
            idField: 'id',
            textField: 'type',
            itemsShowLimit: 3,
            allowSearchFilter: false
        };


    }

    loadEquipment() {

        this.equipmentService.loadEquipment()
            .subscribe(s => {
                if (s) {
                    this.equipments = this.equipmentService.equipments;

                    if (this.cargo.id > 0) {

                        this.equipmentService.loadSelectedEquipment(this.cargo.id)
                            .subscribe(s => {
                                if (s) {
                                    this.selectedEquipment = this.equipmentService.equipments;

                                    this.equipments.forEach(e => {
                                        if (this.selectedEquipment.find(s => s.description == e.description)) {
                                            e.isChecked = true;
                                        } else {
                                            e.isChecked = false;
                                        }
                                    });
                                }
                                else {
                                    this.equipments.forEach(e => {
                                        e.isChecked = false;
                                    });
                                }
                            });

                    }
                    else {
                        this.equipments.forEach(e => {
                            e.isChecked = false;
                        });
                    }


                }
            });
    }

    loadContainerTypes() {

        this.supplierService.loadContainerTypes()
            .subscribe(s => {
                if (s) {
                    this.containerTypes = this.supplierService.containerTypes;
                }
            });

    }

    loadSelectedContainerType() {

        this.cargoService.getContainerType(this.cargo.id)
            .subscribe(x => {
                
                this.selectedItemsContainerTypes = [];
                this.selectedItemsContainerTypes.push(this.cargoService.containerType);
                this.selectedItemsContainerType = this.cargoService.containerType;

            });

    }

    loadSelectedEquipment() {

        this.equipmentService.loadSelectedEquipment(this.cargo.id)
            .subscribe(x => {
                
                this.selectedEquipment = [];
                this.selectedEquipment = this.equipmentService.equipments;

            });

    }

    onSubmit() {

        if (!isUndefined(this.selectedItemsContainerType)) {
            this.cargo.containerType = this.selectedItemsContainerType;

            var d = new Date('2018-01-01');
            var o = - d.getTimezoneOffset();

            if (this.cargo.dateTimeCollectionCustomer == null) { this.cargo.dateTimeCollectionCustomer = new Date(1900, 0, 1, 0, o, 0); } else
            {
                if (typeof this.cargo.dateTimeCollectionCustomer != 'string' && this.cargo.dateTimeCollectionCustomer instanceof String)
                    this.cargo.dateTimeCollectionCustomer = new Date(this.cargo.dateTimeCollectionCustomer.getTime() + o * 60000);
            }

            if (this.cargo.dateTimeCollectionSupplier == null) { this.cargo.dateTimeCollectionSupplier = new Date(1900, 0, 1, 0, o, 0); } else
            {
                if (typeof this.cargo.dateTimeCollectionSupplier != 'string' && this.cargo.dateTimeCollectionSupplier instanceof String)
                    this.cargo.dateTimeCollectionSupplier = new Date(this.cargo.dateTimeCollectionSupplier.getTime() + o * 60000);
            }

            if (this.cargo.dateTimeDeliveryCustomer == null) { this.cargo.dateTimeDeliveryCustomer = new Date(1900, 0, 1, 0, o, 0); } else
            {
                if (typeof this.cargo.dateTimeDeliveryCustomer != 'string' && this.cargo.dateTimeDeliveryCustomer instanceof String)
                    this.cargo.dateTimeDeliveryCustomer = new Date(this.cargo.dateTimeDeliveryCustomer.getTime() + o * 60000);
            }

            if (this.cargo.dateTimeDeliverySupplier == null) { this.cargo.dateTimeDeliverySupplier = new Date(1900, 0, 1, 0, o, 0); } else
            {
                if (typeof this.cargo.dateTimeDeliverySupplier != 'string' && this.cargo.dateTimeDeliverySupplier instanceof String)
                    this.cargo.dateTimeDeliverySupplier = new Date(this.cargo.dateTimeDeliverySupplier.getTime() + o * 60000);
            }

            if (this.cargo.dateTimeBooking == null) { this.cargo.dateTimeBooking = new Date(1900, 0, 1, 0, o, 0); } else
            {
                if (typeof this.cargo.dateTimeBooking != 'string' && this.cargo.dateTimeBooking instanceof String)
                    this.cargo.dateTimeBooking = new Date(this.cargo.dateTimeBooking.getTime() + o * 60000);
            }


            this.cargoService.updateCargo(this.cargo, this.loadCustomer.id)
                .subscribe(s => {

                    if (s) {

                        this.cargo = this.cargoService.cargo;

                        if (this.origin != null) {

                            this.addCargoOrigin();

                        } else {

                            if (this.destination != null) {

                                this.addCargoDestination();

                            } else {

                                if (this.equipments.filter(e => e.isChecked == true).length > 0) {

                                    this.addCargoEquipment();

                                }

                                this.addCargoEmitter.emit(this.cargo);

                            }

                        }

                    }
                    else {
                        this.errorMessage = "Information missing!";
                    }

                });
        }
        else {
            this.errorMessage = "Information missing!";
        }
    }

    addCargoEquipment() {
        // clear equipment
        this.cargoService.clearCargoEquipment(this.cargo.id)
            .subscribe(s => {

                this.selectedEquipment = [];

                if (s) {
                    console.log(this.equipments);
                    console.log(this.equipments.filter(e => e.isChecked == true));
                    this.equipments.filter(e => e.isChecked == true).forEach(e => {
                        this.selectedEquipment.push(e);
                    });

                    console.log(this.selectedEquipment);
                    // add each equipment
                    this.selectedEquipment.forEach(e => {

                        this.cargoService.addCargoEquipment(this.cargo.id, e.id)
                            .subscribe(x => {
                                console.log("Equipment added");
                            });

                    });
                }
            });
    }

    addCargoOrigin() {
        this.locationService.addCargoOrigin(this.cargo, this.origin)
            .subscribe(x => {
                this.addCargoLocationEmitter.emit(this.locationService.cargoOrigin);

                if (this.destination != null) {

                    this.addCargoDestination();

                } else {

                    this.addCargoEmitter.emit(this.cargo);

                    if (this.equipments.filter(e => e.isChecked == true).length > 0) {

                        this.addCargoEquipment();

                    }

                }
            });
    }

    addCargoDestination() {
        this.locationService.addCargoDestination(this.cargo, this.destination)
            .subscribe(x => {
                this.addCargoLocationEmitter.emit(this.locationService.cargoDestination);

                this.addCargoEmitter.emit(this.cargo);

                if (this.equipments.filter(e => e.isChecked == true).length > 0) {

                    this.addCargoEquipment();

                }
            });
    }

    onContainerTypeSelect(item: any) {
        this.cargo.containerType = item.type;
        this.selectedItemsContainerTypes.push(item);
    }
    onContainerTypeDeselect(item: any) {
        this.cargo.containerType = null;
    }

    onChangeDateTimeCollectionCustomer(data: any) {
        //var date = new Date(data);
        this.cargo.dateTimeCollectionSupplier = data;
    }

    onChangeDateTimeDeliveryCustomer(data: any) {
        this.cargo.dateTimeDeliverySupplier = data;
        this.cargo.dateTimeBooking = data;
    }

    onEquipmentSelect(item: any) {
        if (this.selectedEquipment.filter(e => e.id == item.id) == null)
            this.selectedEquipment.push(item);
    }
    onEquipmentDeselect(item: any) {
        this.selectedEquipment = this.selectedEquipment.filter(e => e != item);
    }
    onSelectAllEquipment(items: any) {
        this.selectedEquipment = this.equipments;
    }
    onDeselectAllEquipment(items: any) {
        this.selectedEquipment = [];
    }

    onCancelCargo() {
        this.cancelCargoEmitter.emit(true);
    }

    onSetOrigin(origin: Location) {
        this.origin = origin;
    }

    onSetDestination(destination: Location) {
        this.destination = destination;
    }
}