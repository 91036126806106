﻿import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Load } from '../../shared/load';
import { LoadDocument } from '../../shared/loadDocument';
import { LoadService } from '../../shared/loadService';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { HttpRequest, HttpClient, HttpEventType } from '@angular/common/http';
import { saveAs } from "file-saver";

@Component({
    selector: "load-document-list",
    templateUrl: "loadDocumentList.component.html",
    styleUrls: ["loadDocumentList.component.css"]
})
export class LoadDocumentsListComponent implements OnInit {
    
    @Input() load: Load;
    @Output() showDeleteLoadDocumentEmitter = new EventEmitter<LoadDocument>();

    isLoading: boolean;
    public uploadStatus: string;
    public files: UploadFile[] = [];

    public loadDocuments: LoadDocument[] = [];

    constructor(private loadService: LoadService, private http: HttpClient) { }

    ngOnInit(): void {
        this.loadLoadDocuments();
        this.uploadStatus = "Drop documents to upload";
    }

    loadLoadDocuments() {

        this.loadService.loadLoadDocuments(this.load.id)
            .subscribe(success => {
                if (success) {

                    this.loadDocuments = this.loadService.loadDocuments;
                }
            });

    }

    onDownloadLoadDocument(loadDocument: LoadDocument) {

        this.loadService.loadLoadDocument(loadDocument.id).subscribe(
            (res) => {

                var file = new File([res], loadDocument.fileName, { type: "application/pdf" });
                saveAs(file);

            });

    }

    public dropped(event: UploadEvent) {
        this.isLoading = true;
        this.files = event.files;

        const fileCount = event.files.length;
        var fileCounter = 0;

        for (const droppedFile of event.files) {

            fileCounter++;

            // Is it a file?
            if (droppedFile.fileEntry.isFile) {

                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {

                    // You could upload it like this:
                    const formData = new FormData()
                    formData.append(droppedFile.relativePath, file)

                    const uploadReq = new HttpRequest('POST', 'api/upload/UploadLoadDocument/' + this.load.id, formData, {
                        reportProgress: true,
                    });

                    this.http.request(uploadReq).subscribe(event => {

                        if (event.type == HttpEventType.UploadProgress) {
                            this.uploadStatus = "Loading: " + (event.loaded / (event.total || event.loaded) * 100).toLocaleString('en-us', { maximumFractionDigits: 0 }) + '%';
                        }

                        if (event.type === HttpEventType.Response) {
                            if (event.body.toString() == 'Load document upload successful.') {
                                this.isLoading = false;
                                alert('Document (' + droppedFile.relativePath + ') upload successful.');
                                if (fileCount == fileCounter) {
                                    this.loadLoadDocuments();
                                }
                                this.uploadStatus = "Drop documents to add";
                            }
                        }

                        if (event.type === HttpEventType.ResponseHeader) {
                            if (event.status == 404) {
                                alert('Document upload failed. File is too large to upload.');
                                this.uploadStatus = "Drop documents to add";
                                this.isLoading = false;
                            } else if (event.status == 200) {
                                this.isLoading = false;
                                alert('Document (' + droppedFile.relativePath + ') upload successful.');
                                if (fileCount == fileCounter) {
                                    this.loadLoadDocuments();
                                }
                                this.uploadStatus = "Drop documents to add";
                            }
                        }
                    });
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                this.isLoading = false;
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                alert("Error dropping directory!") 
                this.uploadStatus = "Drop documents to add";
            }
        }

    }

    onDeleteLoadDocument(loadDocument: LoadDocument) {
        this.showDeleteLoadDocumentEmitter.emit(loadDocument);
    }
}
